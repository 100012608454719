

// ORIGINAL COLOR SCHEME
export const PAGE_CONSTANT_STYLES1 = {

    page_background_color: '#ccdde8',
    page_button_color_selected: '#0d6cd1', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    
    page_panel_color: '#ccdde8', // dark blue: 011552,    lighter: #034488
    result_item_color: '#ccdde8',
    result_item_background_color: '#fffcf6', // original light color: '#fffcf6' ,   
    result_item_text_color: 'black',

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};



// NEW DARKER COLOR SCHEME   
export const PAGE_CONSTANT_STYLES2 = {

    page_background_color: '#113065', // '#034488', '#333399' 262673 113065   
    // from-couch-1: 343d4e     272f42      1a2332      0c1930     10264b
    //
    page_button_color_selected: '#151269', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#113065', // dark blue: 011552,    lighter: #034488
    result_item_color: '#113065', //'#262673',  //'#011552'
    result_item_background_color: '#034488', // original light color: '#fffcf6' ,   
    result_item_text_color: 'white',

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',

};



// NEW DARKER COLOR SCHEME 2
export const PAGE_CONSTANT_STYLES3 = {

    page_background_color: '#0c254e', // '#034488', '#333399' 262673 113065   
    // from-couch-1: 343d4e     272f42      1a2332      0c1930     10264b
    //   10264b   14284b   16366b   0c254e
    page_button_color_selected: '#151269', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#0c254e', // dark blue: 011552,    lighter: #034488
    result_item_color: '#0c254e', //'#262673',  //'#011552'
    result_item_background_color: '#74acdf', // '#0d5eaf', // original light color: '#fffcf6' ,     113065
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};


export const PAGE_CONSTANT_STYLES4 = {

    page_background_color: '#05449c', //    #405782
    page_button_color_selected: '#74acdf', //
    page_button_color_default: '#011552', // 
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#05449c', // 
    result_item_color: '#05449c', //
    result_item_background_color: '#74acdf', //
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};

export const PAGE_CONSTANT_STYLES5 = {
    page_background_color: '#05449c', //    #405782
    page_button_color_selected: '#58a6d6', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', // 
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#05449c', // 
    result_item_color: '#05449c', //
    result_item_background_color: '#74acdf', // '#74acdf', //   006bbd
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};

export const PAGE_CONSTANT_STYLES6 = {
    page_background_color: '#74acdf',// '#05449c', //    #405782
    page_button_color_selected: '#011552', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#006bbd', //'#05449c', // border outside panel 006bbd 011552
    result_item_color: '#05449c', // items inside panel
    result_item_background_color: '#74acdf', // '#74acdf', //   006bbd
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#011552',
};

export const PAGE_CONSTANT_STYLES7 = {
    page_background_color: '#74acdf',// '#05449c', //    #405782
    page_button_color_selected: '#011552', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#74acdf', //'#05449c', // border outside panel 006bbd 011552
    result_item_color: '#05449c', // items inside panel
    result_item_background_color: '#006bbd', // '#74acdf', //   006bbd
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#011552',
};


export const PAGE_CONSTANT_STYLES8 = {
    page_background_color: '#74acdf',// '#05449c', //    #405782
    page_button_color_selected: '#011552', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#74acdf', //'#05449c', // border outside panel 006bbd 011552
    result_item_background_color: '#006bbd', // '#74acdf', //   006bbd 405782
    result_item_color: '#1e3f6c', // items inside panel 405782 05449c
    result_item_text_color: '#edece8', // 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#011552',
};



export const PAGE_CONSTANT_STYLES_0825 = {
    page_background_color: '#74acdf',// '#05449c', //    #405782
    page_button_color_selected: '#011552', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1


    // 006bbd 2c5c8d
    page_panel_color: '#587ebb', //'#05449c', // border outside panel 006bbd 011552 74acdf
    result_item_background_color: '#1e3f6c', 
    result_item_background_color_favorite: '#011552' ,// '#011552', 
    
    result_item_background_color_2: '#74acdf', 
    result_item_background_color_favorite_2: '#74acdf' ,// '#011552', 
    // '#74acdf', //   006bbd 405782 2c5c8d 587ebb
    // dark - 1e3f6c
    // mid 405782
    result_item_color: '#405782   ', 
    //light - 587ebb
    // items inside panel 405782 05449c 006bbd 1e3f6c 2c5c8d 74acdf
    result_item_text_color: '#ccdde8 ', // edece8
    result_item_text_color_2: '#edece8', // 

    details_background_color: '#405782 ', // ' #8dbbe6', 2c5c8d 2b78c8 8dbbe6 7891B8 587ebb 587ebb
    // 74acdf 8dbbe6 8dbbe6 1e3f6c 587ebb 1e3f6c
    details_text_color: '#ccdde8',
    // edece8 2c5c8d ccdde8


    modal_background_color: '#587ebb', // 74acdf 1e3f6c 006bbd
    // 2c5c8d


    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#1e3f6c', // 1e3f6c   011552


    page_typography_color: '#2c5c8d' ,

    search_item_text_color: '#ccdde8',
    search_item_background_color: '#587ebb  ' , // 2c5c8d 1e3f6c
 
};










export const PAGE_CONSTANT_STYLES_0904 = {

    //THIS IS THE MAIN BACKGROUND COLOR FOR THE WINEMENU PAGE
    page_background_color: '#05081c', // '#05081c',// '#05449c', //    #405782   black   050514


    
    page_button_color_selected: '#080c45', //  011552 74acdf
// red eb4034   961239
    // COLOR FOR TYPE, VARIETAL, REGION, SELECT/UNSELECT BUTTONS -- SortByPanel.jsx -- also GLASSES AND BOTTLES, JUST NEEDED TO REFRESH TO SEE IT
    page_button_color_default: '#20242b', //       BSF: 022947                             //'#022947', //'#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1






    // 006bbd 2c5c8d
    page_panel_color: '#0a0d36', //'#05449c', // border outside panel 006bbd 011552 74acdf
    result_item_background_color:  '#1e3f6c', 
    result_item_background_color_favorite: '#011552' ,// '#011552', 
    
    result_item_background_color_2: 'white',//'#74acdf', 
    result_item_background_color_favorite_2: '#74acdf' ,// '#011552', 
    // '#74acdf', //   006bbd 405782 2c5c8d 587ebb
    // dark - 1e3f6c
    // mid 405782
    result_item_color: '#405782   ', 
    //light - 587ebb
    // items inside panel 405782 05449c 006bbd 1e3f6c 2c5c8d 74acdf
    result_item_text_color: '#ccdde8 ', // edece8
    result_item_text_color_2: '#edece8', // 

    details_background_color: '#405782 ', // ' #8dbbe6', 2c5c8d 2b78c8 8dbbe6 7891B8 587ebb 587ebb
    // 74acdf 8dbbe6 8dbbe6 1e3f6c 587ebb 1e3f6c
    details_text_color: '#ccdde8',
    // edece8 2c5c8d ccdde8


    modal_background_color: '#587ebb', // 74acdf 1e3f6c 006bbd
    // 2c5c8d


    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#1e3f6c', // 1e3f6c   011552


    page_typography_color: '#2c5c8d' ,

    search_item_text_color: 'white',// '#ccdde8',
    search_item_background_color: '#405782' , // 2c5c8d 1e3f6c 587ebb
 
    // for the text 'welcome yayas guests' and text  below
    welcome_message_color_default: '#dee9f0', //'white'    dee9f0                PREV COLOR VERY LIGHT BLUE: ccdde8


    // DARK BLUE CONSIDER USING -- 022947




    
};








/*

7891B8
405782




dark blue - 1e3f6c




*/
/*
color palette

dark blue #034488
mid blue #178fd6
light blue #ccdde8
gray brown #edece8
off white #fffcf6



*/






















export const PAGE_CONSTANT_STYLES = {

    //THIS IS THE MAIN BACKGROUND COLOR FOR THE WINEMENU PAGE
    page_background_color: '#05081c', // '#05081c',// '#05449c', //    #405782   black   050514     /// NORMAL -- 05081c

   /// red = #720e0e; 
    
    page_button_color_selected: '#080c45', //  011552 74acdf
// red eb4034   961239


    // COLOR FOR TYPE, VARIETAL, REGION, SELECT/UNSELECT BUTTONS -- SortByPanel.jsx -- also GLASSES AND BOTTLES, JUST NEEDED TO REFRESH TO SEE IT
    page_button_color_default: '#20242b', //       BSF: 022947                             //'#022947', //'#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1






    // 006bbd 2c5c8d
    page_panel_color: '#0a0d36', //'#05449c', // border outside panel 006bbd 011552 74acdf -- NORMAL #0a0d36
    result_item_background_color: '#1e3f6c', 
    result_item_background_color_favorite: '#011552' ,// '#011552', 
    
    result_item_background_color_2: 'white',//'#74acdf', 
    result_item_background_color_favorite_2: '#74acdf' ,// '#011552', 
    // '#74acdf', //   006bbd 405782 2c5c8d 587ebb
    // dark - 1e3f6c
    // mid 405782
    result_item_color: '#405782   ', 
    //light - 587ebb
    // items inside panel 405782 05449c 006bbd 1e3f6c 2c5c8d 74acdf
    result_item_text_color: '#ccdde8 ', // edece8
    result_item_text_color_2: '#edece8', // 

    details_background_color: '#405782 ', // ' #8dbbe6', 2c5c8d 2b78c8 8dbbe6 7891B8 587ebb 587ebb
    // 74acdf 8dbbe6 8dbbe6 1e3f6c 587ebb 1e3f6c
    details_text_color: '#ccdde8',
    // edece8 2c5c8d ccdde8


    modal_background_color: '#587ebb', // 74acdf 1e3f6c 006bbd
    // 2c5c8d


    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#1e3f6c', // 1e3f6c   011552


    page_typography_color: '#2c5c8d' ,

    search_item_text_color: 'white',// '#ccdde8',
    search_item_background_color: '#405782' , // 2c5c8d 1e3f6c 587ebb
 
    // for the text 'welcome yayas guests' and text  below
    welcome_message_color_default: '#dee9f0', //'white'    dee9f0                PREV COLOR VERY LIGHT BLUE: ccdde8


    // DARK BLUE CONSIDER USING -- 022947


    // THIS IS USED FOR THE TOGGLE FEATURE WHEN ITEM IS OFF
     /// red = #720e0e; 
    toggled_off_panel_color: '#9e2323',    //'#720e0e', 


    
};


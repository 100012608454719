
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { useState } from "react";

import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";


import SpiritsResultItem from "./SpiritsResultItem"





import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color




const SpiritsCategoryHeading = (props) => {

    const [open2, setOpen2] = useState(false);
    const { item } = props;
    const { index } = props;

    return (

        <div>

            <style>

                {
                    `.fade {
                            opacity: 1;
                            transition: opacity 0.3s ease;
                        }
                        .visible {
                            opacity: 1;
                        }
                        .hidden {
                            opacity: 0;
                        }`
                }

            </style>

            <Card sx={{
                // minWidth: 300,
                // border: "2px solid white",
                backgroundColor: RESULT_ITEM_BACKGROUND_COLOR,//'gray',
                borderRadius: '0px',
                opacity: '100%',
                margin: '0.5rem',
                borderRadius: '8px'
            }}>
                <CardHeader
                    onClick={() => setOpen2(!open2)}
                    aria-label="expand"
                    size="small"
                    title={item.category}
                    action={
                        <IconButton
                            onClick={() => setOpen2(!open2)}
                            aria-label="expand"
                            size="small"
                        >
                            {open2 ? <Remove />
                                : <Add />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                }}>

                    <Collapse in={open2} timeout={500}
                        unmountOnExit>
                        <CardContent>
                            <Container sx={{
                                height: 'auto',
                                lineHeight: 2,
                                width: '100%',
                                margin: '0rem',
                                padding: '0rem',
                            }}>

                                {item.data.map((single_item, index) => (
                                    <div className={`fade ${open2 ? 'visible' : 'hidden'}`} key={index}>
                                        
                                        
                                        {/* <Typography>item {index}</Typography>
                                        <Typography>{single_item.name}</Typography> */}
                                        <SpiritsResultItem
                                        item_data={single_item}
                                        index={index}
                                        item_count={item.count}
                                        ></SpiritsResultItem>
                                        {/* <FoodItem foodItem={foodItem} open={open2} /> */}

                                    </div>
                                ))}

                            </Container>
                        </CardContent>
                    </Collapse>

                </div>
            </Card>

        </div >
    );
};

export default SpiritsCategoryHeading;

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';


import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_PANEL_COLOR = PAGE_CONSTANT_STYLES.page_panel_color
const PAGE_TYPOGRAPHY_COLOR = PAGE_CONSTANT_STYLES.page_button_color_default // page_typography_color
const PAGE_BUTTON_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color

// FONT
import '../../../fonts/font.css'



const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',

            backgroundColor: PAGE_PANEL_COLOR, // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',

            // backgroundColor: '#011552',
            // opacity: '1.00',
            // paddingLeft: '0.5rem',
            // marginLeft: '1rem',
            marginRight: '1rem',


        }}
    >
        {props.children}
    </Box>
);


const InstructionTypography = (props) => (
    <Typography
        sx={{
            borderRadius: '5px',
            align: "center",
            fontSize: '1.2rem',
            fontWeight: 'bold',
            backgroundColor: PAGE_TYPOGRAPHY_COLOR, //'#edece8',
            color: 'black',
            margin: '0.5rem',
            opacity: '0.8',
            // marginTop:'0rem',
            // marginBottom:'1rem',
            padding: '5px',
            color: PAGE_BUTTON_TEXT_COLOR,
            // fontFamily: 'imported-font-result-text',
        }}
    >
        {props.children}
    </Typography>
);













const Instructions = (props) => {

    // Extract data from props
    const { renderDisplayItems } = props;

    if (renderDisplayItems.length == -0) {
        return (
            <div style={{ width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>

                <div style={{
                    width: '100%', height: 'auto'
                }}
                >


                    <Typography
                        //borderRadius='5px'
                        align="center"
                        fontSize='1.5rem'
                        fontWeight='bold'
                        backgroundColor='#edece8'
                        color='black'
                        margin='0rem'
                        marginTop='0rem'
                        padding='5px'
                        borderRadius='0px'
                        fontFamily='imported-font-result-text-name'

                    >
                        INSTRUCTIONS
                    </Typography>

                    {/* <InstructionTypography>
                                    This web app was developed to enhance the experience of selecting the right wine.
                                </InstructionTypography>

                                <InstructionTypography>
                                    This web app is designed to enhance the experience of selecting the right wine.
                                </InstructionTypography> */}

                    <InstructionTypography>
                        This web app is a searchable interactive menu to help in selecting the right wine.
                    </InstructionTypography>

                    <InstructionTypography>
                        Scroll to the top of the page and select either the 'Glasses' or 'Bottles' button
                       
                    </InstructionTypography>

                    {/* (currently only the 'Glasses' section is complete). */}

                    <InstructionTypography>
                        Start typing in the search bar to search wines by name
                    </InstructionTypography>

                    <InstructionTypography>
                        Or browse by wine type (white, red, etc.), varietal (grape), 
                        or country and individually toggle checkboxes on or off to narrow the search
                    </InstructionTypography>


                    {/* Then select to browse by wine type (white, red, etc.), varietal (grape), or country. */}


                    {/* <InstructionTypography>
                        Individually toggle checkboxes on or off.
                    </InstructionTypography> */}

                    <InstructionTypography>
                        To select/unselect all checkboxes, click the corresponding 'select/unselect all' buttons.
                    </InstructionTypography>

                    <InstructionTypography>
                        Then scroll down to see your results!
                    </InstructionTypography>

                    <InstructionTypography>
                        If you want to remember a wine for later, click the star icon in the result and it will be saved to your Favorites
                    </InstructionTypography>




                </div>



                {/* // <div ref={triggerPointRef} style={{ visibility: 'hidden', height: 1 }}>
                            //    Load More
                           // </div> */}



            </div>

        );
    } else {
        return (
            <div>
                <Typography
                    //borderRadius='5px'
                    align="center"
                    fontSize='2rem'
                    fontWeight='bold'
                    // backgroundColor='#edece8'
                    color='white'
                    margin='0rem'
                    marginTop='0rem'
                    padding='5px'
                    borderRadius='0px'
                    fontFamily='imported-font-result-text-name'

                >
                    Search Results
                </Typography>

            </div>
        )
    }
};

export default Instructions;


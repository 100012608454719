import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import { useState, useEffect, useRef } from "react";

import SpiritsCategories from './SpiritsCategories'



// REACT COMPONENTS
import MenuBar from "../../components/MenuBar"
import Footer from "../../components/Footer"


// REDUX
import { connect } from 'react-redux';
// REDUX ACTIONS - MOVED TO MODULES
import { FULL_DRINK_DATA } from '../../redux/selectors';

// REDUX OPERATION MODULES
import * as initializeDrinkOperations from "../../redux/pagemodules/drinkmenu/initializeDrinkOperations.js"
// import * as searchObjectOperations from "../../redux/pagemodules/winemenu/searchObjectOperations.js"
// import * as sortOperations from "../../redux/pagemodules/winemenu/sortOperations.js"


// ==========================================
// ==========================================
//               CONFIGS
// ==========================================
// ========================================== 

import CONFIG from '../../config/config.js'
const SPIRITS_DATA_SERVER_IP = CONFIG.SPIRITS_DATA_SERVER_IP
const SPIRITS_DATA_SERVER_URL = CONFIG.SPIRITS_DATA_SERVER_URL
const devModeBool = true




import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
// console.log('PAGE_CONSTANT_STYLES', PAGE_CONSTANT_STYLES.page_button_color)
const PAGE_BUTTON_COLOR_SELECTED = PAGE_CONSTANT_STYLES.page_button_color_selected  // '#151269'
const PAGE_BUTTON_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.page_button_color_default



// ==========================================
// ==========================================
//               STYLES
// ==========================================
// ========================================== 


import '../../styles/page-styles.css'

import { ContentDivWrapper } from './spirit_menu_styles.jsx'
import { BannerBox } from './spirit_menu_styles.jsx'
// import { ButtonBox } from './wine_menu_styles.jsx'

import { BannerCardBlue } from './spirit_menu_styles.jsx'

// //import { BannerImageTop } from './wine_menu_styles.jsx'


import { BlueTypographyBar } from './spirit_menu_styles.jsx'
import { WhiteBox } from './spirit_menu_styles.jsx'
import { BottomBannerBox } from './spirit_menu_styles.jsx'
import { BannerCardWhite } from './spirit_menu_styles.jsx'
import { BannerImageBottom } from './spirit_menu_styles.jsx'

// import { StyledBox } from './wine_menu_styles.jsx'
// import { CategoryButtonBox } from './wine_menu_styles.jsx'

// import wineBottleBanner from '../../images/wine-bottle-banner.jpg'
// import wineGlassBanner from '../../images/wine-glass-banner.jpg'


import spiritsBanner from './spirits-banner.jpg'












const Spirits = (props) => {

    // Extract data from props
    const { data } = props;

    const [loading, setLoading] = useState(true);
    
















    const fetchEndpointURL = SPIRITS_DATA_SERVER_IP + SPIRITS_DATA_SERVER_URL
    useEffect(() => {
        const initializeFetch = async () => {
            if (loading === true) {
                await initializeDrinkOperations.fetchDrinkDataAfterPageLoad(fetchEndpointURL)
                // await initializeOperations.selectSearchCategory();

                setLoading(false);
            }
        };
        initializeFetch();
    }, []);



























































    console.log('data', props.FULL_DRINK_DATA_FROM_SELECTOR.spiritsData)


    return (
        <div className='parentDiv'>

            <div id='fadingBanner'>
            </div>

            <ContentDivWrapper>

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MenuBar></MenuBar>
                        </Grid>








                        <BannerBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <BannerCardBlue >
                                    <Paper
                                        sx={{
                                            margin: '1rem',
                                            marginLeft: '1.5rem',
                                            padding: '0rem',
                                            border: '0rem',
                                            backgroundColor: 'black',
                                            repeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: '5px',
                                            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                            textAlign: 'center',
                                            position: 'relative',

                                        }}
                                    >
                                        <Paper
                                            sx={{
                                                margin: '0rem',
                                                padding: '0rem',
                                                border: '0rem',
                                                backgroundImage: `url(${spiritsBanner})`,
                                                repeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                width: "100%",
                                                height: "100px",
                                                borderRadius: '5px',
                                                boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                                textAlign: 'center',
                                                position: 'absolute',

                                                // opacity: searchCategoryMapObject[searchCategory]['opacityG'],
                                                transition: 'opacity 0.5s ease-in-out',
                                                // zIndex: searchCategoryMapObject[searchCategory]['z_indexG'],

                                            }}>
                                            <Typography sx={{
                                                backgroundColor: '#ccdde8',
                                                opacity: "0.85",
                                                margin: '0px',
                                                paddingTop: '0rem',
                                                paddingBottom: '0rem',
                                                paddingLeft: '1rem',
                                                paddingRight: '1rem',
                                                borderRadius: '5px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '2rem',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                width: '14rem',
                                            }}
                                            >
                                                Spirits
                                            </Typography>
                                        </Paper>

                                    </Paper>
                                </BannerCardBlue>
                            </Grid>
                        </BannerBox>









                    </Grid>
                </Box>





















                {/* MAIN CONTENT OF PAGE 

                ROUGE FLOW:
                Foodmenu -> FoodCategory -> CategoryHeading -> FoodItem -> Detailsmodal 

                */}




                <div>
                    <SpiritsCategories

                        data={props.FULL_DRINK_DATA_FROM_SELECTOR.spiritsData}
                        stillLoading={loading}

                    ></SpiritsCategories>
                </div>










































                <BlueTypographyBar></BlueTypographyBar>
                <WhiteBox >
                    <Grid container spacing={2}>
                        <BottomBannerBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <BannerCardWhite>
                                    <BannerImageBottom />
                                </BannerCardWhite>
                            </Grid>
                        </BottomBannerBox>
                    </Grid>
                </WhiteBox>

                <Footer></Footer>

            </ContentDivWrapper>
        </div >
    );
};

const mapStateToProps = (state) => ({
    FULL_DRINK_DATA_FROM_SELECTOR: FULL_DRINK_DATA(state),
});

export default connect(mapStateToProps)(Spirits);
















import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

const devModeBool = false

import CocktailsResultItem from './CocktailsResultItem'




const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',

            // backgroundColor: 'gray', // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',

            // backgroundColor: '#011552',
            // opacity: '1.00',
            // paddingLeft: '0.5rem',
            // marginLeft: '1rem',
            marginRight: '1rem',

        }}
    >
        {props.children}
    </Box>
);








const CocktailsResultsContainer = (props) => {

    const { stillLoading } = props;
    const { data } = props;


    console.log('stillLoading', stillLoading)
    console.log('data', data)




    if (stillLoading == true) {
        return (
            <div></div>
        )
    } else {
        return (



            <div id="inspect_this_div" style={{ width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>
                <Grid container spacing={2}>
                    <ButtonBox>
                        <Grid item xs={12} sm={8} md={6} >
                            <StyledBox>
                                <div id="inspect_this_div_2" style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    flexWrap: "wrap",
                                    borderWidth: '2px',
                                    borderColor: '#edece8',
                                    borderStyle: 'solid',
                                    paddingBottom: '1rem',
                                    marginBottom: '1rem',
                                    color:'white',
                                }}>
                                    <div style={{
                                        width: '100%', height: 'auto'
                                    }}
                                    >









                                        {data.map((item, index) => (

                                            <div key={index}>

                                                
                                                {/* <Typography >{item.name}</Typography> */}




                                                <CocktailsResultItem
                                                    item_data={item}
                                                    index={index}
                                                    item_count={data.length}
                                                ></CocktailsResultItem>



                                            </div>

                                        ))}













                                    </div>


                                </div>
                            </StyledBox>
                        </Grid >
                    </ButtonBox>
                </Grid >
            </div >

        );
    }
};

export default CocktailsResultsContainer;













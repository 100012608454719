

export default function exportedFunction(state = {}, action) {

    if (action.type === 'CREATE_DATA_SEARCH_OBJECTS') {

        // console.log('CREATE_DATA_SEARCH_OBJECTS', action.payload)
        return {
            glassDataSearchObject: action.payload.glassDataSearchObject,
            bottleDataSearchObject: action.payload.bottleDataSearchObject,
        };
    }


    // this is done in action 2 of the previous version
    // if (action.type === 'UPDATE_DATA_SEARCH_OBJECTS') {

    //     console.log('UPDATE_DATA_SEARCH_OBJECTS', action.payload)
    //     return {
    //         glassDataSearchObject: state.glassDataSearchObject,
    //         bottleDataSearchObject: state.bottleDataSearchObject,
    //     };
    // }














    if (action.type === 'UPDATE_CHECKBOX') {

        //console.log('UPDATE_CHECKBOX', action.payload)

        const selectedElement = action.payload.selectedElement
        const selectedObject = action.payload.selectedObject
        const selectedSortBy = action.payload.selectedSortBy
        // console.log('UPDATE_CHECKBOX')
        // console.log('selectedElement', selectedElement)
        // console.log('selectedObject', selectedObject)
        // console.log('selectedSortBy', selectedSortBy)

        selectedElement[1] = !selectedElement[1]
        state[selectedObject][selectedSortBy][selectedElement[2][0][1]] = selectedElement[1]

        // console.log('state[selectedObject][selectedSortBy] ',state[selectedObject][selectedSortBy])
        // console.log('state[selectedObject][selectedSortBy] ',state[selectedObject][selectedSortBy][selectedElement[2][0]])

        //console.log(action.payload)
        return {
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
        };
    }













    if (action.type === 'UPDATE_FLAVOR_NOTE') {

        console.log('UPDATE_FLAVOR_NOTE', action.payload)
        return {
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
        };
    }















    if (action.type === 'UPDATE_SLIDER') {

        console.log('UPDATE_SLIDER', action.payload)
        return {
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
        };
    }














    if (action.type === 'TOGGLE_CHECKBOX') {

        console.log('TOGGLE_CHECKBOX', action.payload)
        const selectedObject = action.payload.selectedObject
        const selectedSortBy = action.payload.selectedSortBy
        console.log('selectedObject selectedSortBy', selectedObject, selectedSortBy)
        console.log('state[selectedObject][selectedSortBy]', state[selectedObject][selectedSortBy])

        const searchObjectToModify = state[selectedObject][selectedSortBy]
        console.log('searchObjectToModify', searchObjectToModify)

        // searchObjectToModify.map((item) => (
        //     console.log('item', item)
        // ))

        searchObjectToModify.forEach((item, index) => {

            console.log('item', item, index)
            searchObjectToModify[index]['property'][1] = action.payload.value
            console.log('item', searchObjectToModify[index])

        })

        let returnObject = {
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
        }
        returnObject[selectedObject][selectedSortBy] = searchObjectToModify

        return {
            glassDataSearchObject: returnObject.glassDataSearchObject,
            bottleDataSearchObject: returnObject.bottleDataSearchObject,
        };
    }











    if (action.type === 'SEARCH_BY_TERM') {

        console.log('SEARCH_BY_TERM', action.payload)


        let returnObject = {
            glassDataSearchObject: state.glassDataSearchObject,
            bottleDataSearchObject: state.bottleDataSearchObject,
        }

        let searchByData = {
            searchTerm: action.payload.searchTerm,
            searchFields: action.payload.searchFields,
        }
        returnObject['glassDataSearchObject']['searchByData'] = searchByData
        returnObject['bottleDataSearchObject']['searchByData'] = searchByData

        return {
            glassDataSearchObject: returnObject.glassDataSearchObject,
            bottleDataSearchObject: returnObject.bottleDataSearchObject,
        };
    }





    




    return state;

}




// store.js

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer'; // import the root reducer
import thunkMiddleware from 'redux-thunk';

const initialState = {
  full_wine_data: [],
  data_search_objects: [],
  selected_search_category: [],
  wine_search_results: {
    wineSearchResults: [],
    wineSearchResultsLength: 0,
  },
  full_drink_data: [],
  favorite_items: {
    itemData: []
  },
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState, // Pass the initial state here
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: thunkMiddleware
      }
    })
})

export default store;
/**
 * initializeOperations.js
 *
 * This file contains operations related to initializing or setting up the state
 * or any other necessary resources when the page is loaded or mounted.
 *
 * - Functions for initializing default state values.
 * - Operations to fetch initial data from an API.
 * - Any other setup operations needed for the page.
 */

const devModeBool = false

// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 

// REDUX
import store from '../../store.js';

// import React from 'react';
// import { useDispatch } from 'react-redux';

// REDUX ACTIONS
import a01_FetchWineDataSuccess from "../../actions/a01_FetchWineDataSuccess"
import a02_CreateDataSearchObjects from "../../actions/a02_CreateDataSearchObjects"
import a03_SelectSearchCategory from "../../actions/a03_SelectSearchCategory"
import a04_GenerateWineSearchResults from "../../actions/a04_GenerateWineSearchResults"


// ==========================================
// ==========================================
//               EXPORTS
// ==========================================
// ========================================== 


export const fetchDataAfterPageLoad = async (fetchEndpointURL) => {

    if (devModeBool == true) {
        console.log('Component mounted, if this happens twice check strict mode');
    }

    //console.log('fetchDataAfterPageLoad')
    try {
        const response = await fetch(fetchEndpointURL);
        const data = await response.json();
        if (devModeBool == true) {
             console.log('FETCH_DATA SUCCESS ----- ', data)
        }

        await store.dispatch(a01_FetchWineDataSuccess(data));

        /////await store.dispatch(a02_CreateDataSearchObjects(data));
        // setLoading(false);

    } catch (error) {
        if (devModeBool == true) {
            console.error('Error:', error);
            console.log('FETCH_DATA ----- ERROR')
        }
    }

}





export const createDataSearchObjects = async (data) => {

    try {
        await store.dispatch(a02_CreateDataSearchObjects(data));
    } catch (error) {

    }

}





export const selectSearchCategory = () => {

   ///  console.log('selectSearchCategory INITIALIZE-----------------------------')
    const initialSearchCategoryObject = {
        searchCategory: 'glasses',
        sortBy: 'type',
    }
    try {
        store.dispatch(a03_SelectSearchCategory(initialSearchCategoryObject));
    } catch (error) {

    }

}









export const generateWineSearchResults = (payload) => {

    //console.log('generateWineSearchResults', payload )
    try {
        store.dispatch(a04_GenerateWineSearchResults(payload));
    } catch (error) {

    }

}










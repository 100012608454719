

export default function exportedFunction(state = {}, action) {

    console.time('sorting-time-r01'); 
    console.log('starting', action.payload)
    const devModeBool = true
    // action number 1

    if (action.type === 'FETCH_WINE_DATA_SUCCESS') {

        // toggleBoolValue is used to turn the default to on for chackboxes - 
        const toggleBoolValue = true //false
        const toggleBoolValue2 = true //false
        const wineDataArray = action.payload
        let glassDataArray = []
        let bottleDataArray = []

        wineDataArray.forEach((element, index) => {
            let modifiedElement = {
                acid_low_high: element.acid_low_high,
                alcohol_by_volume_low_high: element.alcohol_by_volume_low_high,
                body_light_full: element.body_light_full,
                country_or_domestic_state: element.country_or_domestic_state,
                dry_sweet: element.dry_sweet,
                enabled: element.enabled,
                flight_category: element.flight_category,
                flight_name: element.flight_name,
                grape: element.grape,
                id: element.id,
                image: element.image,
                name: element.name,
                pairs_with_item_id: element.pairs_with_item_id,
                price_bottle: element.price_bottle,
                price_flight: element.price_flight,
                price_glass_5_oz: element.price_glass_5_oz,
                price_glass_8_oz: element.price_glass_8_oz,
                region: element.region,
                tannin_low_high: element.tannin_low_high,
                tasting_notes: element.tasting_notes,
                type: element.type,
                varietal_descriptors: element.varietal_descriptors,
                description: element.description,
            }

            if (modifiedElement['enabled'] == null) {
                modifiedElement['enabled'] = 1
            }
            if (modifiedElement['image'] == null) {
                modifiedElement['image'] = 'default_image.jpg'
            }

            let removeNullStringValuesArray = [
                'country_or_domestic_state',
                'grape',
                'region',
                'tasting_notes',
                'type',
                'varietal_descriptors',
            ]

            removeNullStringValuesArray.forEach((keyElement) => {
                if (modifiedElement[keyElement] == null) {
                    modifiedElement[keyElement] = 'empty'
                }

            })


            let removeNullIntegerValuesArray = [
                'acid_low_high',
                'alcohol_by_volume_low_high',
                'body_light_full',
                'dry_sweet',
                'tannin_low_high',
            ]

            removeNullIntegerValuesArray.forEach((keyElement) => {
                if (modifiedElement[keyElement] == null) {
                    modifiedElement[keyElement] = -1
                }

            })


            if (modifiedElement['enabled'] == 1) {

                if (element.price_glass_8_oz != null) {
                    //console.log("this item is a BOTTLE")
                    glassDataArray.push(modifiedElement)
                } 
                if (element.price_bottle != null) {
                    //console.log("this item is a BOTTLE")
                    bottleDataArray.push(modifiedElement)
                } 
                
            }

            
        
        });


        if (devModeBool == true) {
            console.log('glassDataArray ', glassDataArray)
            console.log('bottleDataArray ', bottleDataArray)
        }





        let glassDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
            searchByData: {searchTerm: '', searchFields: ['name', 'description'] }
        }
        let bottleDataSearchObject = {
            type: [],
            grape: [],
            region: [],
            country_or_domestic_state: [],
            sliderValues: {},
            searchByData: {searchTerm: '', searchFields: ['name', 'description'] }
        }

        // - iterate over this set and generate checkbox search categories based off of selection (type, grape, or location)



        let wineFlightResults = []










        console.log('point 1')
        
        
        /*
        
        
        glassDataArray.forEach((element, index) => {
            //console.log(element.type);
            //console.log(element.region);
            //console.log(element.grape);

            if (!glassDataSearchObject.type.includes(element.type)) {
                glassDataSearchObject.type.push(element.type);
            }

            /////console.log("glassDataArray element", element.flight_category);



            if (element.flight_name != null) {

                // for each wine check if that category exists yet
                // if category exists add to array
                // if category does not exist, create category, then add to array in appropriate location
                let currentFlightCategory = element.flight_category
                let categoryExists = false
                wineFlightResults.forEach((flightElement) => {
                    if (flightElement.category == currentFlightCategory) {
                        /////console.log("exists")
                        categoryExists = true
                    }
                })

                if (categoryExists == false) {
                    /////console.log("DOES NOT EXIST")
                    wineFlightResults.push(
                        {
                            category: currentFlightCategory,
                            flightList: [],
                        }
                    )
                }

                let flightCategoryHashMap = {}
                wineFlightResults.forEach((flightElement, flightElementIndex) => {
                    flightCategoryHashMap[flightElement.category] = flightElementIndex
                })
                /////console.log("flightCategoryHashMap", flightCategoryHashMap)



                // now category definitely exists, but need to check if flight exists, so will make variable that is 
                // the flightList array, which will copy by reference (not make a new copy of the array in memory)

                let currentFlightCategoryIndex = flightCategoryHashMap[currentFlightCategory]
                /////console.log("currentFlightCategoryIndex", currentFlightCategoryIndex)
                let currentCategoryFlightList = wineFlightResults[currentFlightCategoryIndex].flightList

                let currentFlightExists = false
                /////console.log("currentCategoryFlightList", currentCategoryFlightList)
                currentCategoryFlightList.forEach((currentCategoryFlightListElement) => {
                    if (currentCategoryFlightListElement.flight_name == element.flight_name) {
                        /////console.log("currentFlightExists")
                        currentFlightExists = true
                    }
                })
                /////console.log("currentFlightExists ", currentFlightExists)

                if (currentFlightExists == false) {
                    /////console.log("currentFlightExists DOES NOT EXIST")
                    let currentCategoryFlightListArrayLength = currentCategoryFlightList.length
                    currentCategoryFlightList.push(
                        {
                            flight_name: element.flight_name,
                            flight_index: currentCategoryFlightListArrayLength,
                            wine_glasses: [],
                        }
                    )
                }

                let flightNameHashMap = {}
                currentCategoryFlightList.forEach((flightListElement, flightListElementIndex) => {
                    flightNameHashMap[flightListElement.flight_name] = flightListElementIndex
                })
                /////console.log("flightNameHashMap", flightNameHashMap)

                let currentFlightName = element.flight_name
                let currentFlightListIndex = flightNameHashMap[currentFlightName]
                /////console.log("currentFlightListIndex", currentFlightListIndex)
                let currentFlightNameArray = currentCategoryFlightList[currentFlightListIndex].wine_glasses
                currentFlightNameArray.push(element)

            }

        });



*/














        if (devModeBool == true) {
            console.log("wineFlightResults ", wineFlightResults)

            console.log('glassDataSearchObject ', glassDataSearchObject)
            console.log('bottleDataSearchObject ', bottleDataSearchObject)
        }


        // pass all of the glasses data or bottles data along with the key to be populated: type, grape, or region
        const populateSearchObject = (dataArray, searchKey) => {

            let returnArray = []
            let returnArray2 = []
            const dataMap = {};


            // here the first level of the dataMap is populated with each unique ky in the dataarray, e.g. bubbles white rose red
            dataArray.forEach((element) => {

                if (element.enabled == 1) {


                    if (!returnArray.includes(element[searchKey])) {
                        returnArray.push(element[searchKey]);
                        dataMap[element[searchKey]] = []
                    }
    
                    dataMap[element[searchKey]].push(element)
    

                }

            });




            // ALL TASTING NOTE STUFF
            Object.keys(dataMap).forEach((key, keyIndex) => {

                let dataMapElementArray = dataMap[key]
                let tastingNotesArray = []
                let tastingNotesArray2 = []
                let tastingNoteIndex = 0
                let imageCodes = {
                    color: null,
                    grape: null,
                    region: null,
                }
                //console.log("key, obj[key] ", key, dataMap[key]);

                dataMapElementArray.forEach((element) => {
                    //const parsedArray = JSON.parse(element.tasting_notes); now varietal_descriptors
                    let commaSeparatedString = element.varietal_descriptors

                    //console.log("element.varietal_descriptors" , element.varietal_descriptors)
                    //console.log("element" , element.type.toLowerCase() )

                    imageCodes.color = element.type.toLowerCase()
                    
                    let grapeWithSpaces = element.grape.toLowerCase()
                    imageCodes.grape = grapeWithSpaces.replace(/\s/g, '');

                    let regionWithSpaces = element.region.toLowerCase()
                    imageCodes.region = regionWithSpaces.replace(/\s/g, '');

                    // if (commaSeparatedString == null){
                    //     commaSeparatedString = '1,2'
                    // }

                    const elements = commaSeparatedString.split(',').map(item => item.trim());
                    const jsonString = JSON.stringify(elements);
                    //console.log(jsonString);
                    //console.log(typeof jsonString);
                    const parsedArray = JSON.parse(jsonString);

                    // [stringTastingNote, true, tastingNoteIndex]


                    parsedArray.forEach((stringTastingNote) => {
                        if (!tastingNotesArray.includes(stringTastingNote)) {
                            tastingNotesArray.push(stringTastingNote);
                            tastingNotesArray2.push([stringTastingNote, toggleBoolValue2, [keyIndex, tastingNoteIndex]]);
                            tastingNoteIndex += 1
                        }
                    });


                    //console.log("element.tasting_notes", element.tasting_notes)

                });

                /////console.log("FOR KEY OF : ", key, " TASTING NOTES ARE: ", tastingNotesArray2)
                let arrayLength = dataMapElementArray.length
                let lengthString = arrayLength.toString()
                // populates the returnarray 
                returnArray2.push({
                    property: [key, toggleBoolValue, [keyIndex], imageCodes],
                    tastingNotes: tastingNotesArray2,
                    length: lengthString,
                })

            });



            return returnArray2

        };




        glassDataSearchObject.type = populateSearchObject(glassDataArray, 'type')
        glassDataSearchObject.grape = populateSearchObject(glassDataArray, 'grape')
        glassDataSearchObject.region = populateSearchObject(glassDataArray, 'region')
        glassDataSearchObject.country_or_domestic_state = populateSearchObject(glassDataArray, 'country_or_domestic_state')

        bottleDataSearchObject.type = populateSearchObject(bottleDataArray, 'type')
        bottleDataSearchObject.grape = populateSearchObject(bottleDataArray, 'grape')
        bottleDataSearchObject.region = populateSearchObject(bottleDataArray, 'region')
        bottleDataSearchObject.country_or_domestic_state = populateSearchObject(bottleDataArray, 'country_or_domestic_state')


        if (devModeBool == true) {
            console.log('glassDataSearchObject 2 ', glassDataSearchObject)
            console.log('bottleDataSearchObject 2 ', bottleDataSearchObject)
        }




        //// console.log('QUERY_WINE_DATA_SUCCESS bottleDataArray: ', bottleDataArray)







        const bottleDataArrayItems = bottleDataArray.map(function (currentItem, index, array) {
            ////  console.log(currentItem.price_bottle)
            return currentItem.price_bottle /* transformed value based on the currentItem */;
        });



        function quickSort(arr) {
            if (arr.length <= 1) {
                return arr; // Base case: an array with one or zero elements is already sorted
            }

            const pivotIndex = Math.floor(arr.length / 2);
            const pivot = parseInt(arr[pivotIndex]);
            const left = [];
            const right = [];

            // Partition the array into two subarrays: elements less than the pivot and elements greater than the pivot
            for (let i = 0; i < arr.length; i++) {
                if (i === pivotIndex) {
                    continue; // Skip the pivot element
                }
                if (parseInt(arr[i]) < pivot) {
                    left.push(arr[i]);
                } else {
                    right.push(arr[i]);
                }
            }

            // Recursively sort the subarrays and combine them with the pivot to form the final sorted array
            return [...quickSort(left), pivot.toString(), ...quickSort(right)];
        }


        const sortedArrayBottles = quickSort(bottleDataArrayItems);
        ////  console.log(sortedArrayBottles); // Output: [1, 1, 2, 3, 6, 8, 10]


        function divideIntoBuckets(arr) {
            // Calculate the size of each bucket (20% of the total items)
            const bucketSize = Math.ceil(arr.length / 5);

            // Create empty buckets to hold the items
            const buckets = [];

            // Sort the input array in ascending order
            /// arr.sort((a, b) => a - b);

            // Calculate bucket bounds based on the sorted array
            for (let i = 0; i < 5; i++) {
                const lowerBound = arr[i * bucketSize];
                const upperBound = arr[Math.min(((i + 1) * bucketSize), arr.length - 1)];
                buckets.push({ lower: lowerBound, upper: upperBound });
            }

            return buckets;
        }



        const sortedArrayBottlesBuckets = divideIntoBuckets(sortedArrayBottles);
        ////  console.log(sortedArrayBottlesBuckets); // Output: [1, 1, 2, 3, 6, 8, 10]

        bottleDataArray.map(function (currentItem, index, array) {

            let currentBucket = null
            let currentPrice = parseInt(currentItem.price_bottle)
            for (let i = 0; i < 5; i++) {
                let currentLimit = parseInt(sortedArrayBottlesBuckets[i].upper)
                //console.log(currentPrice, currentLimit)
                if (currentPrice < currentLimit) {
                    //// console.log(currentPrice, currentLimit, 'less')
                    currentBucket = i + 1
                    break
                }

            }
            //// console.log(currentBucket, '\n\n')
            bottleDataArray[index].price = currentBucket
        });

        if (devModeBool == true) {
            console.log('WINE_DATA: glassDataSearchObject', glassDataSearchObject)
            console.log('WINE_DATA: bottleDataSearchObject', bottleDataSearchObject)
            console.log('WINE_DATA: glassDataArray', glassDataArray)
            console.log('WINE_DATA: bottleDataArray', bottleDataArray)
        }

        console.timeEnd('sorting-time-r01'); 
        return {
            fullWineData: action.payload,
            glassDataArray: glassDataArray,
            bottleDataArray: bottleDataArray,
            glassDataSearchObject: glassDataSearchObject,
            bottleDataSearchObject: bottleDataSearchObject,
            wineSearchResults: state.wineSearchResults,
            wineSearchResultsLength: state.wineSearchResultsLength,
            wineFlightResults: wineFlightResults,
        };
    }



    return state;
}
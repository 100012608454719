import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import footerBannerImg from '../images/yayas-banner-footer.png'





const lineColor = '#20242b'    // '#034488'
const backgroundColor = "#05081c" //"#1c1f2d" //'#20242b' //'#0a0d36'
const textColor = '#ccdde8'


const StyledTypographyTopBar = (props) => (
    <Typography
        sx={{
            marginTop: '0rem',
            padding: '0.4rem',
            backgroundColor: lineColor, // '#034488',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);

const StyledTypographyBottomBar = (props) => (
    <Typography
        sx={{
            padding: '0.5rem',
            backgroundColor: lineColor, //'#034488',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);

const StyledTypographyBar = (props) => (
    <Typography
        sx={{
            padding: '0.4rem',
            backgroundColor: backgroundColor, //'#034488',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);

const StyledTypographyBarThin = (props) => (
    <Typography
        sx={{
            margin: '0rem',
            padding: '0.2rem',
            backgroundColor: lineColor, //'#034488',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);

const StyledTypography = (props) => (
    <Typography
        sx={{
            padding: '0.5rem',
            color: textColor, // '#034488',
            backgroundColor: backgroundColor, //'#fffcf6',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
        }}
    >
        {props.children}
    </Typography>
);

const StyledTypographyLarge = (props) => (
    <Typography
        sx={{
            padding: '0.5rem',
            color: textColor, //'#ccdde8',  //'#034488',
            backgroundColor: backgroundColor, //'#fffcf6',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontSize: '1.3rem',
        }}
    >
        {props.children}
    </Typography>
);




const StyledTypographySmall = (props) => (
    <Typography
        sx={{
            padding: '0rem',
            color: textColor, //'#034488',
            backgroundColor: backgroundColor, //'#fffcf6',
            display: 'flex',
            justifyContent: 'center',
            fontWeight: 'regular',
            fontSize: '0.9rem',

        }}
    >
        {props.children}
    </Typography>
);

const BannerBox = (props) => (
    <Box
        sx={{
            paddingTop: '1rem',
            width: '100%',

        }}
    >
        {props.children}
    </Box>
);

const ContentDivWrapper = (props) => (
    <Box

    >
        {props.children}
    </Box>
);




const Footer = (props) => {

    // Extract data from props
    const { data } = props;

    return (
        <div>






            <Grid item xs={12} sm={8} md={8} >
                <Box sx={{
                    backgroundColor: backgroundColor , //'#20242b', //'#0a0d36', //'#fffcf6',
                    opacity: '100%'
                }}>
                    <StyledTypographyBarThin></StyledTypographyBarThin>
                    <Box sx={{ paddingTop: '1rem' }}></Box>

                    <StyledTypographyLarge >
                        YA YA'S EURO BISTRO
                    </StyledTypographyLarge>



                    <StyledTypographySmall >
                        Modern Mediterranean Cuisine
                    </StyledTypographySmall>
                    <StyledTypographySmall >
                        in a sophisticated atmosphere
                    </StyledTypographySmall>

                    <Box sx={{ paddingTop: '1rem' }}></Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '50%', 
                            backgroundColor: lineColor, // '#034488',
                            height: '2px' }}></Box>
                    </Box>
                    <Box sx={{ paddingTop: '1rem' }}></Box>

                    <StyledTypography>
                        8310 East Belleview Ave.
                    </StyledTypography>
                    <StyledTypography>
                        Greenwood Village, CO 80111
                    </StyledTypography>

                    <Box sx={{ paddingTop: '1rem' }}></Box>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '50%', 
                            backgroundColor: lineColor, // '#034488', 
                            height: '2px' }}></Box>
                    </Box>
                    <Box sx={{ paddingTop: '1rem' }}></Box>

                    <StyledTypography >
                        Call for Reservations:  (303) 741 - 1110
                    </StyledTypography>

                    <Box sx={{ paddingTop: '1rem' }}></Box>
                    <StyledTypographyBarThin></StyledTypographyBarThin>
                    <Box sx={{ paddingTop: '2rem' }}></Box>
                    <StyledTypography >
                    &#169; 2024 jdtechdevs llc
                    </StyledTypography>
                    <StyledTypographySmall >
                        ALL RIGHTS RESERVED
                    </StyledTypographySmall>

                    {/* <Link to="https://www.jdtechdevs.com"
                        style={{ textDecoration: 'none' }}
                    >
                        <StyledTypography >
                            jdtechdevs.com
                        </StyledTypography>
                    </Link> */}

                    <Box sx={{ paddingTop: '1rem' }}></Box>
                    <StyledTypographyBottomBar></StyledTypographyBottomBar>
                </Box>
            </Grid>
        </div >
    );
};

export default Footer;



/*

Modern Euro Cuisine in a sophisticated atmosphere



                <Box>
                    <Grid item xs={12} sm={8} md={6} >

                        <Card style={{
                            margin: '0rem',
                            padding: '0rem',
                            border: '0rem',
                            display: 'flex',
                            justifyContent: "center",
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            borderRadius: '0px',
                            backgroundColor: '#fffcf6', // '#ccdde8',
                        }}>

                            <Paper
                                style={{
                                    margin: '1rem',
                                    marginLeft: '1rem',
                                    padding: '0rem',
                                    border: '0rem',
                                    // backgroundColor: 'black',
                                    backgroundImage: `url(${footerBannerImg})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: '5px',
                                    boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                }} square
                            />

                        </Card>
                    </Grid>
                </Box>
















                            <ContentDivWrapper>
                <Box>
                    <Grid container spacing={2}>
                        <BannerBox>
                            <Grid item xs={12} sm={8} md={6} >

                                <Card style={{
                                    margin: '0rem',
                                    padding: '0rem',
                                    border: '0rem',
                                    display: 'flex',
                                    justifyContent: "center",
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0)',
                                    borderRadius: '0px',
                                    backgroundColor: '#ccdde8',
                                }}>

                                    <Paper
                                        style={{
                                            margin: '1rem',
                                            marginLeft: '1.5rem',
                                            padding: '0rem',
                                            border: '0rem',
                                            // backgroundColor: 'black',
                                            backgroundImage: `url(${footerBannerImg})`,
                                            repeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: '5px',
                                            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                        }} square
                                    />

                                </Card>
                            </Grid>
                        </BannerBox>
                    </Grid>
                </Box>
            </ContentDivWrapper>










*/
/**
 * searchOperations.js
 *
 * This file contains operations related to handling search-related actions and state updates.
 *
 * - Functions for dispatching actions to update search parameters.
 * - Operations for making API calls to fetch search results.
 * - Any other logic associated with searching functionality.
 */


const devModeBool = false

// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 

// REDUX
import store from '../../store.js';

// REDUX ACTIONS
import a05_UpdateCheckbox from "../../actions/a05_UpdateCheckbox"
import a06_UpdateFlavorNote from "../../actions/a06_UpdateFlavorNote"
import a07_UpdateSlider from "../../actions/a07_UpdateSlider"

import a03_SelectSearchCategory from "../../actions/a03_SelectSearchCategory"


import a08_ToggleCheckbox from "../../actions/a08_ToggleCheckbox"
import a09_SearchByTerm from "../../actions/a09_SearchByTerm"
import a10_UpdateFavoriteItems from "../../actions/a10_UpdateFavoriteItems"


// ==========================================
// ==========================================
//               EXPORTS
// ==========================================
// ========================================== 


export const updateCheckbox = (data) => {

    try {
        console.log('updateCheckbox')
        store.dispatch(a05_UpdateCheckbox(data));
    } catch (error) {

    }

}


export const updateFlavorNote = (data) => {

    try {
        console.log('updateFlavorNote')
        store.dispatch(a06_UpdateFlavorNote(data));
    } catch (error) {

    }

}


export const updateSlider = (data) => {

    try {
        console.log('updateSlider')
        store.dispatch(a07_UpdateSlider(data));
    } catch (error) {

    }

}










export const selectSearchCategory = (data) => {

    // const initialSearchCategoryObject = {
    //     searchCategory: 'glasses',
    //     sortBy: 'type',
    // }
    // console.log('selectSearchCategory SEARCH-----------------------------')
    try {
        store.dispatch(a03_SelectSearchCategory(data));
    } catch (error) {

    }

}







export const toggleCheckbox = (data) => {

    try {
        console.log('toggleCheckbox')
        store.dispatch(a08_ToggleCheckbox(data));
    } catch (error) {

    }

}





export const searchByTerm = (data) => {

    try {
        console.log('searchByTerm')
        store.dispatch(a09_SearchByTerm(data));
    } catch (error) {

    }

}




export const updateFavoriteItems = (data) => {

    try {
        console.log('a10_UpdateFavoriteItems')
        store.dispatch(a10_UpdateFavoriteItems(data));
    } catch (error) {

    }

}

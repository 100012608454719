

export default function exportedFunction(state = {}, action) {

    if (action.type === 'SELECT_SEARCH_CATEGORY') {

       ////  console.log('CREATE_DATA_SEARCH_OBJECTS', action.payload)
        const stateDataObjectLocationMap = {
            glasses: {
                activeDataArray: 'glassDataArray',
                activeSearchObject: 'glassDataSearchObject',
            },
            bottles: {
                activeDataArray: 'bottleDataArray',
                activeSearchObject: 'bottleDataSearchObject',
            },
        }

        let activeDataObjects = null
        let newSearchCategory = null
        if (action.payload.searchCategory) {
            //console.log('action.payload.searchCategory', action.payload.searchCategory)
            activeDataObjects = stateDataObjectLocationMap[action.payload.searchCategory]
            newSearchCategory = action.payload.searchCategory
        } else {
            newSearchCategory = state.searchCategory
            activeDataObjects = stateDataObjectLocationMap[newSearchCategory]
        }

        //// console.log('activeDataObjects', activeDataObjects)

        return {
            searchCategory: newSearchCategory, //action.payload.searchCategory,
            sortBy: action.payload.sortBy,
            activeStateDataObjects: activeDataObjects
        };
    }

    return state;

}


import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color



import CONFIG from '../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL

import PriceItem from './PriceItem'
import DrinkDescriptionComponent from './DrinkDescriptionComponent'
import ModalComponent from './ModalComponent'

const BeerResultItem = (props) => {

    // Extract data from props
    const { item_data } = props;
    const { index } = props;
    const { item_count } = props;
    const correct_index = index + 1

    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + item_data.image_thumb
    const imgURL_2 = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + item_data.image_main






































    return (
        <div>


            <div
                // ref={itemRef} onClick={handleParentClick}
                style={{
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '0rem',
                    paddingBottom: '0.5rem',
                    // backgroundColor:'gray',
                    margin: '1rem',
                    marginBottom: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '8px',

                }} >


                <Box backgroundColor={RESULT_ITEM_BACKGROUND_COLOR} //'#fffcf6'
                    sx={{

                        //opacity: '100%',
                        width: "100%", //'23rem',
                        //minWidth: '40%',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0.5rem', //'5px',
                        margin: '0rem',
                        // border: '0px',
                        display: 'flex',
                        //marginLeft: '2rem',
                        flexDirection: 'column',
                        borderRadius: '8px',
                        border: "2px solid white",
                    }} >



                    <Typography
                        align="left"
                        fontSize='0.9rem'
                        // fontWeight='italic'
                        // backgroundColor='gray'
                        color={RESULT_ITEM_TEXT_COLOR} // 'white'
                    //margin='0.2rem'
                    //marginBottom= '0.5rem' // '5px',
                    >
                        - Item {correct_index} of {item_count} -
                    </Typography>















                    <Box
                        sx={{
                            //display: 'flex',
                            //flexDirection: 'column',
                            //flexWrap: "wrap",
                            //justifyContent: "space-between",
                            padding: '0.5rem',
                            //gap: '1rem',
                            //width: 'auto',
                            //backgroundColor: '#011552',//'#034488',
                            backgroundColor: RESULT_ITEM_COLOR, // '#011552' //'#edece8'
                            width: 'auto', // '85%',
                            //paddingTop: '0rem', // '5px',

                            //opacity: "85%",
                            height: '20%',



                        }}
                    >



                        {/* <Grid item xs={6} sm={6} md={6} > */}
                        <Typography
                            align="center"
                            fontSize='1.3rem'
                            fontWeight='bold'
                            // backgroundColor='gray'
                            color={RESULT_ITEM_TEXT_COLOR}// 'white'
                        //margin='0.2rem'
                        //marginBottom= '0.5rem' // '5px',
                        >
                            {item_data.name}
                        </Typography>
                        {/* </Grid> */}

                    </Box>














                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "no-wrap",
                            padding: '0rem',
                            paddingTop: '0.5rem',
                            backgroundColor: RESULT_ITEM_BACKGROUND_COLOR, // 'gray', // '#fffcf6',//'#034488',
                            width: '100%',
                            height: '80%',



                        }}
                    >


                        <Box sx={{
                            padding: '0rem',
                            backgroundColor: '#fffcf6',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "no-wrap",
                            justifyContent: 'center',
                            borderRadius: '0px',
                            backgroundColor: RESULT_ITEM_BACKGROUND_COLOR,
                        }}>
                            <Box sx={{
                                padding: '0rem',
                                backgroundColor: RESULT_ITEM_COLOR, //'#034488',
                                height: 'auto',
                                width: 'auto',
                                padding: '0.2rem',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                justifyContent: 'center',
                            }}>


                                <Paper
                                    sx={{
                                        backgroundImage: `url(${imgURL})`,
                                        opacity: '0.85',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: '15rem 15rem',
                                        backgroundColor: 'black',

                                        width: '15rem',
                                        height: '15rem',
                                        borderRadius: '0px',
                                    }}>





                                    <ModalComponent
                                    style={{
                                        backgroundColor: 'black',
                                        paddingLeft: '0rem',
                                        // color: 'blue',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                    render={true}
                                    data={item_data}
                                    buttonOpacity={'60%'}
                                    //ref={childRef}
                                    isActive={false}
                                //isActive={id === activeItem}
                                ></ModalComponent>



                                </Paper>

                            </Box>
                        </Box>



                        <Box

                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                opacity: '100%',
                                transition: 'opacity 1.8s',

                            }}>

                        </Box>

                    </Box>








                    {/* 
                  
                    
 */}

                    <PriceItem data={item_data} ></PriceItem>
                    <DrinkDescriptionComponent data={item_data}></DrinkDescriptionComponent>


                </Box>
            </div >
        </div >
    );









































};

export default BeerResultItem;


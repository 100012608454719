
export default function exportedFunction(state = {}, action) {

    console.time('sorting-time-r04');
    const devModeBool = true
    if (action.type === 'GENERATE_WINE_SEARCH_RESULTS') {

        // console.log('CREATE_DATA_SEARCH_OBJECTS', action.payload)
        // return {
        //     searchResults: 'results',
        // };

        if (devModeBool == true) {
            console.log('UPDATE_WINE_SEARCH_RESULTS ', action.payload)
            //console.log('STATE ', state)
        }





        let dataToSearchArray = null
        let selectedSearchObjectName = null

        // let selectedSearchArrayName = null
        // if (action.payload.activeMenu == 'glasses') {
        //     selectedSearchArrayName = "glassDataArray"
        //     selectedSearchObjectName = "glassDataSearchObject"

        // }
        // if (action.payload.activeMenu == 'bottles') {
        //     selectedSearchArrayName = "bottleDataArray"
        //     selectedSearchObjectName = "bottleDataSearchObject"

        // }


        dataToSearchArray = action.payload.dataArray //state[selectedSearchArrayName]

        let sortByProperty = action.payload.sortBy
        let currentDataSearchPropertyArray = action.payload.searchObject[sortByProperty]

        let currentDataSearchTermObject = action.payload.searchObject.searchByData


        //state[selectedSearchObjectName][sortByProperty]


        if (devModeBool == true) {
            console.log("SEARCH ARRAY: ", dataToSearchArray)
            console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)
        }




        // now iterate over search array to obtain subset that meets search criteria
        // already narrowed between bottles and glasses
        // array.map and check each entry against relevant category (property, i.e. type, grape, or region)
        // if item passes category check, then check against tasting notes
        // get a list of all tasting notes of item
        // compare list against full tasting notes true, false doesnt exclude but lack of any true does
        // as soon as a single true is found, wine will be included
        // or could be done by tasting note exclusion, need to consider this, maybe build EXCLUDE feature
        // e.g. press once to turn note off, twice to exclude things with that note, not sure if thats a good idea or not
        // could make subset for each category of tasting notes that are true, but probably faster to make first version 
        //      if just compare everything and then check if false after
















































///  THIS SORTLOOP FUNCTION IS THE MAIN RESULTS FINDING CODE

let wineSearchResultsArray2 = []


const sortLoop2 = (dataToSearchArray) => {

    let wineSearchResultsArray = []


    dataToSearchArray.forEach((dataElement) => {

        // console.log("SEARCHING ELEMENT : ", dataElement)
        // console.log(dataElement[sortByProperty])
        // console.log("currentDataSearchPropertyArray", currentDataSearchPropertyArray)

        let addedToResults = false
        let setAddToResults = false
        currentDataSearchPropertyArray.forEach((compareElement, compareElementIndex) => {
            addedToResults = false
            setAddToResults = false
            //console.log("compareElement ", compareElementIndex, compareElement['property'])
            let currentComparisonElement = compareElement['property']
            let currentComparisonTastingNotes = compareElement['tastingNotes']
            if (dataElement[sortByProperty] == currentComparisonElement[0]) {
                //console.log("TRUE FALSE CHECK")
                //console.log(compareElementIndex)
                /////console.log(compareElement[sortByProperty][compareElementIndex-1]['property'])
                if (currentComparisonElement[1] == true) {
                    //console.log("MATCH FOUND!")
                    let currentTastingNotes = dataElement['varietal_descriptors']
                    let currentTastingNotesLowerCase = currentTastingNotes.toLowerCase()
                    let currentTastingNotesLowerCaseStringWithoutSpaces = currentTastingNotesLowerCase.replace(/\s/g, "");
                    let tastingNotesArray = currentTastingNotesLowerCaseStringWithoutSpaces.split(",");
                    //console.log("currentComparisonTastingNotes ", currentComparisonTastingNotes)
                    //console.log("currentTastingNotes ", currentTastingNotes, tastingNotesArray)

                    // problem was comparing upper and lower case - this solved bottles, removing tasting notes with spaces mostly fixed glasses
                    // but there is still one glass that is having a problem, oh i missed one double word, maybe now it will work

                    tastingNotesArray.forEach((tastingNotesArrayElement) => {
                        //console.log('tastingNotesArrayElement ', tastingNotesArrayElement)
                        currentComparisonTastingNotes.forEach((comparisonTastingNotesArrayElement) => {

                            let comparisonTastingNotesArrayElementCopy = comparisonTastingNotesArrayElement[0]
                            let comparisonTastingNotesArrayElementLowerCase = comparisonTastingNotesArrayElementCopy.toLowerCase()
                            if (comparisonTastingNotesArrayElementLowerCase == tastingNotesArrayElement) {
                                if (comparisonTastingNotesArrayElement[1] == true) {
                                    setAddToResults = true
                                    //console.log("addedToResults 1", setAddToResults)
                                }
                            }
                            //console.log('comparisonTastingNotesArrayElement ', comparisonTastingNotesArrayElement)
                        })
                    })

                    if (devModeBool == true) {
                        console.log("addedToResults 2", setAddToResults)
                    }



                    if (setAddToResults == true) {
                        //console.log("addedToResults 3", setAddToResults)
                        if (addedToResults == false) {


                            //console.log('adding item : ', dataElement) 
                            let mainString = dataElement.name.toLowerCase();

                            let substring = currentDataSearchTermObject.searchTerm.toLowerCase()

                            // console.log('mainString ', mainString)
                            // console.log('substring ', substring)



                            //const substring = ;

                            if (mainString.includes(substring)) {
                                //console.log("Substring found!");

                                wineSearchResultsArray.push(dataElement)
                                //console.log("addedToResults 4", setAddToResults, addedToResults)
                                addedToResults = true
                                //console.log("addedToResults 5", setAddToResults, addedToResults)


                            }
                            // else {
                            //   console.log("Substring not found.");
                            // }




                        }
                    }
                    if (setAddToResults == false) {

                        if (devModeBool == true) {
                            console.log("FALSE UNADDED ELEMENT: ", dataElement)
                        }


                    }
                }
                if (currentComparisonElement[1] == false) {

                    if (devModeBool == true) {
                        console.log("MATCH EXCLUDED")
                    }


                }
            }

        })
    })


    console.timeEnd('sorting-time-r04-loop');


    console.log('sortLoop wineSearchResultsArray ', wineSearchResultsArray)
    return wineSearchResultsArray


}

// wineSearchResultsArray2 = sortLoop(dataToSearchArray)

















































































///  THIS SORTLOOP FUNCTION IS THE MAIN RESULTS FINDING CODE

        let wineSearchResultsArray = []
        const sortLoop = (dataToSearchArray) => {
            let wineSearchResultsArray = []



            // THIS ARRAY IS ALL THE WINES, SO 35 Glasses or hundreds of bottles
            console.log('dataToSearchArray ', dataToSearchArray)

            dataToSearchArray.forEach((dataElement) => {


                let addedToResults = false
                let setAddToResults = false





              /// this is from the 
                //console.log('currentDataSearchPropertyArray ', currentDataSearchPropertyArray)
                currentDataSearchPropertyArray.forEach((compareElement, compareElementIndex) => {
                   // console.log('currentDataSearchPropertyArray forEach ', compareElement, '\nproperty ',  compareElement['property'], '\ntastingNotes ', compareElement['tastingNotes'])

                    // console.log('\nproperty ',  compareElement['property'])

                    // console.log( '\ntastingNotes ', compareElement['tastingNotes'])


                    addedToResults = false
                    setAddToResults = false
                    //console.log("compareElement ", compareElementIndex, compareElement['property'])
                    let currentComparisonElement = compareElement['property']
                    let currentComparisonTastingNotes = compareElement['tastingNotes']





                    if (dataElement[sortByProperty] == currentComparisonElement[0]) {
                        //console.log("TRUE FALSE CHECK")
                        //console.log(compareElementIndex)
                        /////console.log(compareElement[sortByProperty][compareElementIndex-1]['property'])





                        if (currentComparisonElement[1] == true) {
                            //console.log("MATCH FOUND!")
                            let currentTastingNotes = dataElement['varietal_descriptors']
                            let currentTastingNotesLowerCase = currentTastingNotes.toLowerCase()
                            let currentTastingNotesLowerCaseStringWithoutSpaces = currentTastingNotesLowerCase.replace(/\s/g, "");
                            let tastingNotesArray = currentTastingNotesLowerCaseStringWithoutSpaces.split(",");
                            //console.log("currentComparisonTastingNotes ", currentComparisonTastingNotes)
                            //console.log("currentTastingNotes ", currentTastingNotes, tastingNotesArray)

                            // problem was comparing upper and lower case - this solved bottles, removing tasting notes with spaces mostly fixed glasses
                            // but there is still one glass that is having a problem, oh i missed one double word, maybe now it will work

                            tastingNotesArray.forEach((tastingNotesArrayElement) => {
                                //console.log('tastingNotesArrayElement ', tastingNotesArrayElement)
                                currentComparisonTastingNotes.forEach((comparisonTastingNotesArrayElement) => {

                                    let comparisonTastingNotesArrayElementCopy = comparisonTastingNotesArrayElement[0]
                                    let comparisonTastingNotesArrayElementLowerCase = comparisonTastingNotesArrayElementCopy.toLowerCase()
                                    if (comparisonTastingNotesArrayElementLowerCase == tastingNotesArrayElement) {
                                        if (comparisonTastingNotesArrayElement[1] == true) {
                                            setAddToResults = true
                                            //console.log("addedToResults 1", setAddToResults)
                                        }
                                    }
                                    //console.log('comparisonTastingNotesArrayElement ', comparisonTastingNotesArrayElement)
                                })
                            })

                            if (devModeBool == true) {
                               // console.log("addedToResults 2", setAddToResults)
                            }



                            if (setAddToResults == true) {
                                //console.log("addedToResults 3", setAddToResults)
                                if (addedToResults == false) {


                                    //console.log('adding item : ', dataElement) 
                                    let mainString = dataElement.name.toLowerCase();

                                    let substring = currentDataSearchTermObject.searchTerm.toLowerCase()

                                    // console.log('mainString ', mainString)
                                    // console.log('substring ', substring)



                                    //const substring = ;

                                    if (mainString.includes(substring)) {
                                        //console.log("Substring found!");

                                        wineSearchResultsArray.push(dataElement)
                                        //console.log("addedToResults 4", setAddToResults, addedToResults)
                                        addedToResults = true
                                        //console.log("addedToResults 5", setAddToResults, addedToResults)


                                    }
                                    // else {
                                    //   console.log("Substring not found.");
                                    // }




                                }
                            }
                            if (setAddToResults == false) {

                                if (devModeBool == true) {
                                    console.log("FALSE UNADDED ELEMENT: ", dataElement)
                                }


                            }
                        }
                        if (currentComparisonElement[1] == false) {

                            if (devModeBool == true) {
                                console.log("MATCH EXCLUDED")
                            }


                        }
                    }

                })







            })
            console.log('sortLoop wineSearchResultsArray ', wineSearchResultsArray)
            return wineSearchResultsArray
        }

        wineSearchResultsArray = sortLoop(dataToSearchArray)













































        if (devModeBool == true) {
            console.log("wineSearchResultsArray  ", wineSearchResultsArray)
        }


        let wineSearchResultsLength = wineSearchResultsArray.length

        if (devModeBool == true) {
            console.log("wineSearchResultsLength  ", wineSearchResultsLength)
        }




        /*************************************     
            
               3333             1
                  3            11
                  3           1 1
                  3          1  1
               3333             1
                  3             1
                  3             1    
                  3             1
                  3   000       1
               3333   000   111111111
            
    
        *************************************/



        // for (let i = 0; i <= wineParamArrayLength; i++) {

        //     if (queryResultArray[i] == true) {
        //         ////console.log(i, " true")
        //         let testItem = randomArray[i]

        //         for (let k = 2; k <= 7; k++) {
        //             let testItemParamValue = testItem[k]
        //             if (testItemParamValue < lowLimitMatrix[k - 2]) {
        //                 ////console.log(i, " low false")
        //                 queryResultArray[i] = false
        //                 break
        //             }
        //             if (testItemParamValue > highLimitMatrix[k - 2]) {
        //                 ////console.log(i, " high false")
        //                 queryResultArray[i] = false
        //                 break
        //             }
        //         }
        //     }

        // }


        if (devModeBool == true) {
            console.log('3.10')
        }



        let wineSliderSearchResultsArray = []
        let wineSliderSearchResultsArrayLength = null

        if (devModeBool == true) {
            console.log('3.11', selectedSearchObjectName)
        }




        const sliderSearchObject = action.payload.searchObject['sliderValues']
        //state[selectedSearchObjectName]['sliderValues']

        if (devModeBool == true) {
            console.log('3.12', sliderSearchObject)
            console.log('Object is empty', Object.keys(sliderSearchObject))
            console.log('3.13')
        }










        // THIS IS ALL THE SLIDER STUFF - NOT NEEDED NO SLIDERS
// BUT DO STILL NEED THESE TWO LINES, UNLESS WANT TO CHANGE LAST FEW LINES BEFORE RETURN

        wineSliderSearchResultsArray = wineSearchResultsArray
        wineSliderSearchResultsArrayLength = wineSearchResultsLength


/*
        if (Object.keys(sliderSearchObject).length === 0) {

            if (devModeBool == true) {
                console.log('Object is empty');
            }


            wineSliderSearchResultsArray = wineSearchResultsArray
            wineSliderSearchResultsArrayLength = wineSearchResultsLength

        } else {




            if (devModeBool == true) {
                console.log('Object is not empty');
            }



            wineSearchResultsArray.forEach((dataElement) => {

                if (devModeBool == true) {
                    console.log(dataElement)
                }


                ////const sliderSearchObject = state[selectedSearchObjectName]['sliderValues']
                //console.log(sliderSearchObject)
                let addToResults = true
                Object.keys(sliderSearchObject).forEach((key, keyIndex) => {

                    if (devModeBool == true) {
                        console.log(key, keyIndex, sliderSearchObject[key], dataElement[key])
                    }


                    let sliderRange = sliderSearchObject[key]

                    // if (dataElement[key] >= sliderRange[0]) {
                    //     if (dataElement[key] <= sliderRange[1]) {
                    //         console.log("true")
                    //         addToResults = true
                    //     }
                    // }

                    if (dataElement[key] < sliderRange[0]) {

                        if (devModeBool == true) {
                            console.log("false")
                        }



                        addToResults = false
                    }
                    if (dataElement[key] > sliderRange[1]) {

                        if (devModeBool == true) {
                            console.log("false")
                        }



                        addToResults = false
                    }

                })

                if (addToResults == true) {
                    wineSliderSearchResultsArray.push(dataElement)
                }

            })

            wineSliderSearchResultsArrayLength = wineSliderSearchResultsArray.length





        }

*/






        console.timeEnd('sorting-time-r04-loop');
        console.timeEnd('sorting-time-r04');

        console.log('wineSliderSearchResultsArray ', wineSliderSearchResultsArray);
        


        return {
            wineSearchResults: wineSliderSearchResultsArray,
            wineSearchResultsLength: wineSliderSearchResultsArrayLength,
        };




    }
















    // if (action.type === 'SEARCH_BY_TERM') {

    //     console.log('SEARCH_BY_TERM', action.payload)



    //     return {
    //         wineSearchResults: null,
    //         wineSearchResultsLength: null,
    //     };
    // }






















    return state;

}



const CONFIG = {


    // FOOD_DATA_SERVER_IP: 'https://rougewineselector.com', 
    // FOOD_DATA_SERVER_URL: '/api/dataserverfood',

    // FOOD_IMAGE_SERVER_IP: 'https://rougewineselector.com', 
    // FOOD_IMAGE_SERVER_URL: '/api/imageserver/foodimages/',



    //  'https://rougewineselector.com/api/dataserver'
    //  used in pages/winemenu/WineList.jsx
    // local: http://192.168.3.18:8880/api/dataserver

    // WINE_DATA_SERVER_IP: 'http://192.168.3.18:8880', 
    // WINE_DATA_SERVER_URL: '/api/dataserver',






    // WINE_DATA_SERVER_IP: 'https://rougewineselector.com', 
    // WINE_DATA_SERVER_URL: '/api/dataserver',
    // WINE_IMAGE_SERVER_IP: 'https://rougewineselector.com', 
    // WINE_IMAGE_SERVER_URL: '/api/imageserver/wineimages/',



    // WINE_DATA_SERVER_IP: 'http://localhost:7101', 
    // WINE_DATA_SERVER_URL: '/api/dataserver',

    // WINE_IMAGE_SERVER_IP: 'http://localhost:7102', 
    // WINE_IMAGE_SERVER_URL: '/api/imageserver/wineimages/',

    WINE_DATA_SERVER_IP: 'https://yayaswine.com', 
    WINE_DATA_SERVER_URL: '/api/dataserver',

    WINE_IMAGE_SERVER_IP: 'https://yayaswine.com', 
    WINE_IMAGE_SERVER_URL: '/api/imageserver/wineimages/',

    GLOBAL_OPACITY: '100%',


    // WINE_CLUB_SIGNUP_IP: 'https://rougewineselector.com', 
    // WINE_CLUB_SIGNUP_URL: '/api/wineclub/signup',

    
    // FEEDBACK_IP: 'https://rougewineselector.com', 
    // FEEDBACK_URL: '/api/submitfeedback',


    // SPIRITS_DATA_SERVER_IP: 'http://192.168.2.180:6969', 
    // SPIRITS_DATA_SERVER_URL: '/api/dataserverspirits',

    SPIRITS_DATA_SERVER_IP: 'https://yayaswine.com',
    SPIRITS_DATA_SERVER_URL: '/api/dataserverspirits',


}

export default CONFIG;


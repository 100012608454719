import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';



import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const MODAL_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.modal_background_color
const MODAL_INDICATOR_FULL_COLOR = PAGE_CONSTANT_STYLES.modal_indicator_full_color
const MODAL_INDICATOR_EMPTY_COLOR = PAGE_CONSTANT_STYLES.modal_indicator_empty_color
const MODAL_BUTTON_COLOR = PAGE_CONSTANT_STYLES.modal_button_color
const PAGE_BUTTON_TEXT_COLOR = PAGE_CONSTANT_STYLES.page_button_text_color

// FONT
import '../../../fonts/font.css'



const ScoreBarComponent = (props) => {

    // Extract data from props
    const { scoreArray } = props;
    let renderComponent = false

    if (scoreArray.length === 0) {
        console.log("The array is empty!");
        renderComponent = false
    } else {
        console.log("The array has elements.");
        renderComponent = true
    }

    if (renderComponent == true) {


        return (

            <div
            // style={{

            //     marginBottom: '2rem',
            // }}
            >







                <style>{`
        .graph-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .graph-row {
          display: flex;
          align-items: center;
          margin-bottom: 0px;
          margin-top: 0px;
          margin-right: 0px;
          margin-left: 0px;
        }

        .graph-bar {
          width: 8rem;
          height: 8px;
          background-color: ${MODAL_INDICATOR_EMPTY_COLOR};
          border-radius: 3px;
          margin-left: 0px;
        }

        .graph-bar::before {
          content: "";
          display: block;
          height: 100%;
          background-color: ${MODAL_INDICATOR_FULL_COLOR};
          border-radius: 3px;
          width: var(--progress);
          transition: width 0.3s ease-in-out;
        }
      `}</style>



                <Typography align='center' sx={{ 
                    fontFamily: 'imported-font-result-text',
                    fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '1rem' }}>TASTING SCORES</Typography>


                {scoreArray.map((item, index) => (



                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: "100%",
                            margin: '0rem',
                            marginBottom: '0.5rem',
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',

                            }}
                        >
                            <Typography sx={{ fontSize: '1rem', fontWeight: 'bold', }}>{item.label}</Typography>

                            <div className="graph-container">
                                <div className="graph-row">
                                    <div className="graph-bar" style={{ '--progress': item.value.toString() + '%', }}></div>
                                </div>
                            </div>

                            {/* <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: 'black', }}>{item.value.toString() + '%'}</Typography> */}


                        </div>


                    </div>


                    // <Typography

                    //     sx={{
                    //         fontSize: '1rem',
                    //         fontWeight: 'bold',
                    //         color: 'black',
                    //         padding: '0px',
                    //         margin: '0px',
                    //     }}

                    //     key={`tasting_note-${index}`}

                    // >
                    //     {item.label}
                    // </Typography>

                ))}


            </div >
        );

    }

    else {
        return (
            <div></div>
        )
    }


};

export default ScoreBarComponent;





import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import wineBannerImg from '../../images/wine-bottle-banner.jpg'
import footerBannerImg from '../../images/yayas-banner-footer.png'

// 011552 is background color of page
// also in : 
// SelectPanel.jsx , CheckboxPanel.jsx , 
// SearchResults.jsx , ResultItem.jsx , 
// ModalContents.jsx
// 073e5d -- too green
// 151269 -- dark
// 2c3d5b -- gray blue
// 034488



import {PAGE_CONSTANT_STYLES} from './page-constants.jsx'

///console.log('PAGE_CONSTANT_STYLES', PAGE_CONSTANT_STYLES.page_background_color)
const PAGE_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.page_background_color  // '#151269'





export const ContentDivWrapper = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            //gap: '1rem',
            width: '100%',
            height: 'auto',
            minHeight: '120vh',
            backgroundColor: PAGE_BACKGROUND_COLOR,
            // '#2c394c', 151922 222a4d
            // backgroundImage: `url(${background_image})`,
            backgroundAttachment: 'fixed',
            repeat: 'no-repeat',
            backdropFilter: 'blur(0)', // or backdropFilter: 'none'
            padding: '0px',
            margin: '0px',
            border: '0px',
        }}
    >
        {props.children}
    </Box>
);






export const BannerBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

export const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

export const BottomBannerBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

export const BannerCardBlue = (props) => (
    <Card
        sx={{
            margin: '0rem',
            padding: '0rem',
            border: '0rem',
            display: 'flex',
            justifyContent: "center",
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0)',
            borderRadius: '0px',
            backgroundColor: PAGE_BACKGROUND_COLOR,
            // position: 'relative',
        }}
    >
        {props.children}
    </Card>
);

export const BannerCardWhite = (props) => (
    <Card
        sx={{
            margin: '0rem',
            padding: '0rem',
            border: '0rem',
            display: 'flex',
            justifyContent: "center",
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0)',
            borderRadius: '0px',
            backgroundColor: '#fffcf6',
        }}
    >
        {props.children}
    </Card>
);

// export const BannerImageTop = (props) => (
//     <Paper
//         sx={{
//             margin: '1rem',
//             marginLeft: '1.5rem',
//             padding: '0rem',
//             border: '0rem',
//             backgroundImage: `url(${wineBannerImg})`,
//             repeat: 'no-repeat',
//             backgroundSize: 'cover',
//             width: "100%",
//             height: "100px",
//             borderRadius: '5px',
//             boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
//             textAlign: 'center',
//             position: 'relative',

//         }}
//     >
//         {props.children}
//     </Paper>
// );

export const BannerImageBottom = (props) => (
    <Paper
        sx={{
            margin: '1rem',
            marginLeft: '1.5rem',
            padding: '0rem',
            border: '0rem',
            backgroundImage: `url(${footerBannerImg})`,
            repeat: 'no-repeat',
            backgroundSize: 'cover',
            width: "100%",
            height: "100px",
            borderRadius: '5px',
            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
        }}
    >
        {props.children}
    </Paper>
);

export const WhiteBox = (props) => (
    <Box
        sx={{
            backgroundColor: '#fffcf6',
        }}
    >
        {props.children}
    </Box>
);

export const BlueTypographyBar = (props) => (
    <Typography
        sx={{
            padding: '0.4rem',
            backgroundColor: PAGE_BACKGROUND_COLOR,
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);



export const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            backgroundColor: PAGE_BACKGROUND_COLOR,
            opacity: '1.00',
            //paddingLeft: '1rem',
            marginLeft: '1rem',
            marginRight: '1rem',
        }}
    >
        {props.children}
    </Box>
);


export const CategoryButtonBox = (props) => (
    <Box
        sx={{
            //backgroundColor: '#034488',
            borderRadius: '5px',
            width: '8rem',
            marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);











const devModeBool = false

// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 

// REDUX
import store from '../../store.js';


// REDUX ACTIONS
import a20_FetchDrinkDataSuccess from "../../actions/a20_FetchDrinkDataSuccess"


// ==========================================
// ==========================================
//               EXPORTS
// ==========================================
// ========================================== 


export const fetchDrinkDataAfterPageLoad = async (fetchEndpointURL) => {

    if (devModeBool == true) {
        console.log('Component mounted, if this happens twice check strict mode');
    }

    //console.log('fetchDataAfterPageLoad')
    try {
        const response = await fetch(fetchEndpointURL);
        const data = await response.json();
        if (devModeBool == true) {
             console.log('FETCH_DATA SUCCESS ----- ', data)
        }

        console.log(data)

        await store.dispatch(a20_FetchDrinkDataSuccess(data));

        /////await store.dispatch(a02_CreateDataSearchObjects(data));
        // setLoading(false);

    } catch (error) {
        if (devModeBool == true) {
            console.error('Error:', error);
            console.log('FETCH_DATA ----- ERROR')
        }
    }

}









    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (devModeBool == true) {
    //             console.log("FETCH_FUNCTION")
    //         }
    //         let fetchEndpointURL = SPIRITS_DATA_SERVER_IP + SPIRITS_DATA_SERVER_URL
    //         try {
    //             const response = await fetch(fetchEndpointURL);
    //             const data = await response.json();
    //             if (devModeBool == true) {
    //                 console.log('FETCH_DATA SUCCESS ----- ', data)
    //             }
    //             // await dispatch(QueryFoodDataSuccess(data));
    //             console.log(data)
    //             setLoading(false);
    //         } catch (error) {
    //             if (devModeBool == true) {
    //                 console.error('Error:', error);
    //                 console.log('FETCH_DATA ----- ERROR')
    //             }
    //         }
    //     };
    //     fetchData();
    // }, []);














    
import React from "react";
import ReactDOM from "react-dom";
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import logoImg from '../images/yayas-logo.png'

// MATERIAL UI IMPORTS
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Hidden from '@mui/material/Hidden'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
// https://mui.com/material-ui/material-icons/
// https://stackoverflow.com/questions/69263383/what-is-the-alternative-of-makestyles-for-material-ui-v-5
// https://mui.com/system/styled/







// STYLE FOR MENUBAR BACKGROUND  #fffcf6
//THIS IS THE MAIN BAR ACROSS THE WHOLE SCREEN
const StyledPaper = (props) => (
    <Paper
        sx={{
            background: '#20242b',// #202A2B   #22202B #0D051C  #05141C    1C1905             BEST SO FAR: '#20242b',                // '#20242b', //"#232c31",   2c323a      --- hex picked -- 191d24
            borderRadius: '0px', opacity: '100%'
        }}
    >
        {props.children}
    </Paper>
);

// LOGO PROPERTIES
const StyledLogoPaper = (props) => (
    <Paper
        sx={{
            padding: '0rem',
            margin: '0.5rem',
            backgroundColor: '#0a0d36', //'#034488',     ----- 0a0d36 ---   080c45    080c45   05081c
            backgroundImage: `url(${logoImg})`,
            repeat: 'no-repeat',
            backgroundSize: 'cover',
            width: "10rem", // these were 15 and 6
            height: "6rem",
            borderRadius: '5px'
        }}
    >
        {props.children}
    </Paper>
);

// STYLE FOR HAMBURGER MENU ICON
const StyledHamburgerMenu = (props) => (
    <Menu
        sx={{
            mx: 2, width: '4rem', height: '4rem', 
            color: '#d3dfe8' // "#034488",   0a0d36
        }}
    >
        {props.children}
    </Menu>
);

// STYLE FOR DRAWER BACKGROUND - currently button gray -- 20242b  
const StyledBox = (props) => (
    <Box
        sx={{
            background: '#20242b', height: '100%' , // '#edece8',
        }}
    >
        {props.children}
    </Box>
);

// STYLE FOR CLOSE DRAWER X (CLOSE) ICON
const StyledCloseIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CloseIcon
            sx={{
                mx: 2, width: '4rem', height: '4rem', color: '#034488', 
            }}
        >
        </CloseIcon>
    </div>
);

// DRAWER PROPERTIES
const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        opacity: 0.85, // Adjust the opacity of the drawer
    },
}));

// STYLE FOR DRAWER BACKGROUND
const StyledListItem = (props) => (
    <ListItem
        sx={{
            backgroundColor: 'black', marginBottom: '0.5rem', 
        }}
    >
        {props.children}
    </ListItem>
);

// STYLE FOR DRAWER BUTTONS
const StyledButton= (props) => (
    <Button
        sx={{
            fontSize: '24px', width: "100%", margin: '0px', padding: '0.5rem', color: '#034488', 
        }}
    >
        {props.children}
    </Button>
);












const MenuBar = () => {

    const menuCategories = [
        { label: 'Home', url: '' },
        { label: 'Wine List', url: '' },
        // { label: 'About', url: 'feedback' },
        // { label: 'Feedback', url: 'feedback' },
        { label: 'Spirits', url: 'spirits' },
        { label: 'Cocktails', url: 'cocktails' },
        { label: 'Beer', url: 'beer' },
    ]

    const [mobileOpen, setMobileOpen] = React.useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    
    
    
    let navigate = useNavigate();

    function handleClick(textArg) {
        console.log('navigate ', textArg.url)
        navigate('/' + textArg.url)
    }





    return (

        <div>
            <AppBar position="relative"  elevation={3} sx={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <StyledPaper>
                    <Box
                        m={0}
                        display="flex"
                        justifyContent="space-between"
                        
                    >

                        <RouterLink to={{ pathname: "/" }} component="button" >
                            <StyledLogoPaper>
                            </StyledLogoPaper>
                        </RouterLink>

                        <IconButton
                            onClick={handleDrawerToggle}
                        >
                            
                            <StyledHamburgerMenu>
                            </StyledHamburgerMenu>

                        </IconButton>
                    </Box>
                </StyledPaper>
            </AppBar>






            <nav>
                <Hidden>
                    <StyledDrawer

                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <StyledBox id="STYLED-BOX-ELEMENT">
                            <IconButton
                                sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                onClick={handleDrawerToggle} >

                                <StyledCloseIcon>
                                </StyledCloseIcon>

                            </IconButton>




                            <div>
                                <List  >
                                    {menuCategories.map((text) => (
                                        <StyledListItem key={text.label}>

                                            <Button 
                                            style={{fontSize:'1.5rem',fontWeight:'bold'}}
                                            onClick={() => handleClick(text)}
                                            > {text.label}</Button>

                                        </StyledListItem>
                                    ))}
                                </List>
                            </div>








                        </StyledBox>
                    </StyledDrawer>
                </Hidden>
            </nav>

        </div>
    )

}



export default MenuBar;



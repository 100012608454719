import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import { useState, useEffect, useRef } from "react";

import CheckboxComponent from './searchpanel/CheckboxComponent'


import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_BUTTON_COLOR_SELECTED = PAGE_CONSTANT_STYLES.page_button_color_selected  // '#151269'
const PAGE_BUTTON_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.page_button_color_default
const PAGE_BUTTON_TEXT_COLOR = PAGE_CONSTANT_STYLES.page_button_text_color 



// FONT
import '../../../fonts/font.css'



const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            // backgroundColor: 'black',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem',
            marginRight: '1rem',
        }}
    >
        {props.children}
    </Box>
);

const CategoryButtonBox = (props) => (
    <Box
        sx={{
            backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // '#034488', // backgroundColor: '#034488',
            borderRadius: '5px',
            width: '7rem',
            padding: '0px',
            margin: '0px',
            //marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);


const CategoryButtonBox2 = (props) => (
    <Box
        sx={{
            backgroundColor: '#ccdde8',
            // backgroundColor: '#edece8',
            borderRadius: '5px',
            width: '6rem',
            padding: '0px',
            margin: '10px',
            //marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);











const SelectPanel = (props) => {

    // Extract data from props
    const { data } = props;
    const wineSearchResultsLength = data.wineSearchResultsLength



    const { sendActionToPage } = props

    const { searchObjectInfo } = props


    const { buttonsActiveState } = props




    // console.log('searchObjectInfo', searchObjectInfo)


    const [unselectButtonPressed, setUnselectButtonPressed] = useState(false);
    const handleUnselectTouchStart = (event) => {
        setUnselectButtonPressed(true);
        // const panelPayload = {
        //     selectedType: 'togglecheckbox',
        //     value: false,
        //     selectedObject: searchObjectInfo.activeSearchObject,
        //     selectedSortBy: searchObjectInfo.sortBy,
        // }
        // sendActionToPage(panelPayload)
    };


    const handleUnselectTouchEnd = (event) => {

        setTimeout(() => {

            if (buttonsActiveState == true) {
                const panelPayload = {
                    selectedType: 'togglecheckbox',
                    value: false,
                    selectedObject: searchObjectInfo.activeSearchObject,
                    selectedSortBy: searchObjectInfo.sortBy,
                }
                sendActionToPage(panelPayload)
            }

        }, 150);


        setTimeout(() => {
            setUnselectButtonPressed(false);
        }, 250);
    };






    const [selectButtonPressed, setSelectButtonPressed] = useState(false);
    const handleSelectTouchStart = (event) => {
        setSelectButtonPressed(true);

    };
    const handleSelectTouchEnd = (event) => {

        setTimeout(() => {

            if (buttonsActiveState == true) {
                const panelPayload = {
                    selectedType: 'togglecheckbox',
                    value: true,
                    selectedObject: searchObjectInfo.activeSearchObject,
                    selectedSortBy: searchObjectInfo.sortBy,
                }
                sendActionToPage(panelPayload)
            }

        }, 150);

        setTimeout(() => {
            setSelectButtonPressed(false);
        }, 250);
    };





    const selectedSearchData = ['1', '2', '3', '4']











    return (


        <div>

            <Box sx={{

                textAlign: 'center',
                position: 'relative',
            }}>
                <Typography align="center" fontSize='1rem' fontWeight='bold'
                    style={{

                        fontFamily: 'imported-font-labels',


                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-1%, 10%)',
                        paddingBottom: '1rem', paddingTop: '0.5rem', margin: '0rem',
                        color: PAGE_BUTTON_TEXT_COLOR,
                    }}
                >Available Matches
                </Typography>
            </Box>

            <Grid container spacing={2}>
                <ButtonBox>
                    <Grid item xs={12} sm={8} md={6} >
                        <StyledBox>

                            <CategoryButtonBox>
                                <Button
                                    sx={{

                                        fontFamily: 'imported-font-buttons',


                                        fontSize: '1.2rem',
                                        fontWeight: 'bold',
                                        width: '7rem',
                                        borderRadius: '5px',
                                        height: '100%',
                                        backgroundColor: PAGE_BUTTON_COLOR_DEFAULT , // '#034488',
                                        boxShadow: unselectButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                        color: unselectButtonPressed ? '#fffcf6' : '#ccdde8',
                                        '&:active': {
                                            boxShadow: 'none',
                                            backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // '#034488', // backgroundColor: '#034488',
                                            color: '#edece8',
                                        }
                                    }}
                                    onTouchStart={handleUnselectTouchStart}
                                    onTouchEnd={handleUnselectTouchEnd}
                                    onMouseDown={handleUnselectTouchStart}
                                    onMouseUp={handleUnselectTouchEnd}
                                >UNSELECT ALL</Button>
                            </CategoryButtonBox>

                            <CategoryButtonBox2>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                    <div style={{ height: 'auto' }}>
                                        <Typography // align="center" fontSize='2rem' backgroundColor='#edece8' color='#178fd6' fontWeight='bold'

                                            style={{

                                                fontFamily: 'imported-font-buttons',


                                                fontSize: '2rem',
                                                // backgroundColor: '#ccdde8', //'#edece8',
                                                fontWeight: 'bold',
                                                //transform: 'translate(50%, 50%)',
                                                textAlign: "center",
                                                verticalAlign: 'center',
                                                color: '#05449c', // '#0d6cd1',
                                                padding: '0px', margin: '0px', height: '100%', borderRadius: '0px',
                                                //opacity: '80%'
                                            }}
                                        >
                                            {wineSearchResultsLength}
                                        </Typography>
                                    </div>
                                </div>
                            </CategoryButtonBox2>


                            <CategoryButtonBox>
                                <Button
                                    sx={{

                                        fontFamily: 'imported-font-buttons',


                                        fontSize: '1.3rem',
                                        fontWeight: 'bold',
                                        width: '7rem',
                                        borderRadius: '5px',
                                        height: '100%',
                                        backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
                                        boxShadow: selectButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                        color: selectButtonPressed ? '#fffcf6' : '#ccdde8',
                                        '&:active': {
                                            boxShadow: 'none',
                                            backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // '#034488', // backgroundColor: '#034488',
                                            color: '#edece8',
                                        }
                                    }}
                                    onTouchStart={handleSelectTouchStart}
                                    onTouchEnd={handleSelectTouchEnd}
                                    onMouseDown={handleSelectTouchStart}
                                    onMouseUp={handleSelectTouchEnd}
                                >SELECT ALL</Button>
                            </CategoryButtonBox>


                        </StyledBox>
                    </Grid >
                </ButtonBox>
            </Grid >


            {/* <Typography>test</Typography>


            <Grid container spacing={2}>
                <ButtonBox>
                    <Grid item xs={12} sm={8} md={6} >

                        {selectedSearchData.map((item, index) => (
                            <CategoryButtonBox
                                key={`checkbox_${index}`}

                            >
                                <Box sx={{ backgroundColor: 'gray', height: '3rem', width: '3rem' }}></Box>
                                <CheckboxComponent></CheckboxComponent>
                            </CategoryButtonBox>
                        ))}


                    </Grid >
                </ButtonBox>
            </Grid > */}



            {/* <div id="findThisDiv" style={{ color: 'white', maxWidth: '90%' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: '0.25rem',
                            width: '100%',

                        }}
                    >
                        {selectedSearchData.map((item, index) => (
                            <Grid
                                key={`checkbox_${index}`}
                                style={{
                                    
                                    width: '24vw',
                                }}
                            >
                                <CheckboxComponent></CheckboxComponent>
                            </Grid>
                        ))}
                    </Box>
                </div> */}






        </div >
    );
};

export default SelectPanel;

/*


            <Typography align="center" fontSize='1rem'
                style={{ padding: '0px', margin: '0px', width: '100vw' }}
            >
                AVAILABLE MATCHES:
            </Typography>


*/




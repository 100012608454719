import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import { useState, useEffect, useRef } from "react";

import CheckboxComponent from './searchpanel/CheckboxComponent'

import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_PANEL_COLOR = PAGE_CONSTANT_STYLES.page_panel_color


const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            paddingBottom: '2rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            backgroundColor: PAGE_PANEL_COLOR, // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',
            marginRight: '1rem',

        }}
    >
        {props.children}
    </Box>
);

const CategoryButtonBox = (props) => (
    <Box
        sx={{
            backgroundColor: '#034488',
            borderRadius: '5px',
            width: '8rem',
            padding: '0px',
            margin: '0px',
            //marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);














const CheckboxPanel = (props) => {

    // Extract data from props
    //const { data } = props;

    const { searchObjectInfo } = props
    const { sendActionToPage } = props

    let selectedSearchData = [] // arrayToMap //['1', '2', '3', '4', '5', '6', '7', '8', '9']
    if (searchObjectInfo != null) {
        // console.log('activeSearchObject ', activeSearchObject)
        const arrayToMap = searchObjectInfo.searchObject[searchObjectInfo.sortBy]
        selectedSearchData = arrayToMap
        //console.log('searchObjectInfo', searchObjectInfo)
        //console.log('sendActionToPage',sendActionToPage)
    }








    const [typeButtonPressed, setTypeButtonPressed] = useState(false);
    const handleTypeTouchStart = (event) => {
        setTypeButtonPressed(true);
    };
    const handleTypeTouchEnd = (event) => {
        setTimeout(() => {
            setTypeButtonPressed(false);
        }, 250);
    };


    const [regionButtonPressed, setRegionButtonPressed] = useState(false);
    const handleRegionTouchStart = (event) => {
        setRegionButtonPressed(true);
    };
    const handleRegionTouchEnd = (event) => {
        setTimeout(() => {
            setRegionButtonPressed(false);
        }, 250);
    };








    function handleActionPanel(elementTypeAndData) {

        //console.log(elementData)

        const elementLocationAndData = {
            selectedObject: searchObjectInfo.activeSearchObject,
            selectedSortBy: searchObjectInfo.sortBy,
            selectedType: elementTypeAndData.type,
            selectedElement: elementTypeAndData.data,
        }
        console.log(elementLocationAndData)
        sendActionToPage(elementLocationAndData)

    }








    let widthVar = '8rem'
    let widthVar2 = '0%'
    let testColor = '#fffcf6'
    if (searchObjectInfo != null) {

        return (


            <div style={{ width: '100%', marginTop: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>

                <Grid container spacing={2}>
                    <ButtonBox>
                        <Grid item xs={12} sm={8} md={6} >
                            <StyledBox>
                                <div style={{
                                    width: '100%', 
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'space-evenly', 
                                    flexWrap: "wrap",
                                    borderWidth: '2px', 
                                    borderColor: '#edece8', 
                                    borderStyle: 'solid',
                                    paddingBottom: '1rem',
                                }}>

                                    {selectedSearchData.map((item, index) => (
                                        <div key={`checkbox_${index}`} style={{ width: '27%', margin: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
                                            <Box
                                                sx={{ height: '12re0', width: widthVar, margin: widthVar2 }}
                                            >
                                                <CheckboxComponent data={item} sortBy={searchObjectInfo.sortBy} handleAction={handleActionPanel} ></CheckboxComponent>
                                            </Box>
                                        </div>
                                    ))}

                                </div>
                            </StyledBox>
                        </Grid >
                    </ButtonBox>
                </Grid >


                {/* <Typography>test</Typography>


            <Grid container spacing={2}>
                <ButtonBox>
                    <Grid item xs={12} sm={8} md={6} >

                        {selectedSearchData.map((item, index) => (
                            <CategoryButtonBox
                                key={`checkbox_${index}`}

                            >
                                <Box sx={{ backgroundColor: 'gray', height: '3rem', width: '3rem' }}></Box>
                                <CheckboxComponent></CheckboxComponent>
                            </CategoryButtonBox>
                        ))}


                    </Grid >
                </ButtonBox>
            </Grid > */}



                {/* <div id="findThisDiv" style={{ color: 'white', maxWidth: '90%' }}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            gap: '0.25rem',
                            width: '100%',

                        }}
                    >
                        {selectedSearchData.map((item, index) => (
                            <Grid
                                key={`checkbox_${index}`}
                                style={{
                                    
                                    width: '24vw',
                                }}
                            >
                                <CheckboxComponent></CheckboxComponent>
                            </Grid>
                        ))}
                    </Box>
                </div> */}






            </div >
        );

    } else {
        return (

            <div style={{ width: '100%', marginTop: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>
                <Grid container spacing={2}>
                    <ButtonBox>
                        <Grid item xs={12} sm={8} md={6} >
                            <StyledBox>
                                <div style={{
                                    width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', flexWrap: "wrap",
                                    borderWidth: '2px', borderColor: '#edece8', borderStyle: 'solid',
                                }}>
                                    <div style={{
                                        width: '100%', height: '15rem'
                                    }}
                                    ></div>


                                </div>
                            </StyledBox>
                        </Grid >
                    </ButtonBox>
                </Grid >
            </div >

        )
    }
};

export default CheckboxPanel;

/*


            <Typography align="center" fontSize='1rem'
                style={{ padding: '0px', margin: '0px', width: '100vw' }}
            >
                AVAILABLE MATCHES:
            </Typography>


*/




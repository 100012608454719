


import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import Add from
    "@mui/icons-material/Add";
import Remove from
    "@mui/icons-material/Remove";

import IconButton from "@mui/material/IconButton";


import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color



const StyledAddIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '0rem', margin: '0rem', }}>
        <Add
            sx={{
                mx: 2, width: '2rem', height: '2rem', color: '#034488', padding: '0rem', margin: '0rem',
            }}
        >
        </Add>
    </div>
);
const StyledRemoveIcon = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Remove
            sx={{
                mx: 2, width: '2rem', height: '2rem', color: '#034488', padding: '0rem', margin: '0rem',
            }}
        >
        </Remove>
    </div>
);





































const DrinkDescriptionComponent = (props) => {

    const [open2, setOpen2] = useState(false);


    const item = "props";
    const index = 1;
    const { data } = props
    const data_description = data.description







    return (

        <div
            style={{

                margin: '0rem',

            }}
        >

            <style>

                {`




.fade {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
  }






                `}

            </style>


            <Card sx={{

                minWidth: 300,
                //border: "6px solid black", //"4px solid rgba(211,211,211,0.6)",

                backgroundColor: '#8dbbe6', // RESULT_ITEM_BACKGROUND_COLOR, //RESULT_ITEM_COLOR, 
                // '#011552' //'#edece8'
                // 74acdf   ccdde8  8dbbe6  609cd4


                color: RESULT_ITEM_COLOR, //RESULT_ITEM_TEXT_COLOR, 
                //'white'

                //backgroundColor: 'gray',
                borderRadius: '0px',
                opacity: '100%',
                margin: '0rem',
                marginTop: '0.5rem',
                padding: '0rem',
                // borderWidth: '1rem',
                borderColor: 'black',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                boxShadow: '2px 2px 5px rgba(03, 44, 88 0.5)'

            }}>

                <CardHeader

                    onClick={() => setOpen2(!open2)}
                    aria-label="expand"
                    size="medium"

                    titleTypographyProps={{ fontSize: "1.2rem", fontWeight: 'bold', textAlign: 'left', }}
                    title={"Details"}

                    action={

                        <IconButton
                            onClick={() => setOpen2(!open2)}
                            aria-label="expand"
                            size="small"
                        ///// backgroundColor= "rgba(211,211,211,0.4)"
                        >

                            {open2 ? <StyledRemoveIcon />
                                : <StyledAddIcon />}

                        </IconButton>
                    }
                >

                </CardHeader>
                <div style={{
                    padding: '0rem', margin: '0rem', // backgroundColor: '#8dbbf6',
                    ///// backgroundColor: "rgba(211,211,211,0.4)"   '#8dbbf6',
                }}>


                    <Collapse in={open2} timeout="auto" 
                        unmountOnExit
                        
                        style={{
                            padding: '0rem', margin: '0rem', border: '0rem' //, backgroundColor: '#aadff6',
                        }}
                        
                        >
                        <CardContent 
                                                style={{
                                                    padding: '0rem', margin: '0rem', border: '0rem', /// backgroundColor: '#aadff6',
                                                }}
                        >
                            <Container 
                            
                            

                            sx={{
                                height: 'auto',
                                lineHeight: 0,
                                width: '100%',
                                padding: '0rem',
                                margin: '0rem',


                            }}>










                                <div 
                                                                    style={{
                                                                        padding: '1rem',
                                                                        paddingTop: '0rem',
                                                                        //borderWidth: '10px',
                                                                        //backgroundColor: 'black'
                                                                    }}
                                >


                                    <Typography align='center' 
                                    sx={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: '1rem' }}
                                    >DESCRIPTION
                                    </Typography>


                                    <Typography align={'justify'}>

                                        {data_description}

                                    </Typography>
                                </div>





                            </Container>
                        </CardContent>
                    </Collapse>


                </div>
            </Card>



        </div >

    );
};

export default DrinkDescriptionComponent;




import React, { useState } from "react";
import Modal from "react-modal";
import '../../styles/modal-styles.css'
import { forwardRef } from 'react';
import { useEffect } from 'react';
import ModalContents from './ModalContents'

const ModalComponent = forwardRef((props, ref) => {

    const { data } = props
    const { isActive } = props
    useEffect(() => {
        if (isActive == true) {
            return
            // setTimeout(() => { setButtonOpacityVariable('50%') }, 100)
        }
        if (isActive == false) {
            return
            // setButtonOpacityVariable('0%')
        }
    }, [isActive]);

    const [isOpen, setIsOpen] = useState(false);
    function toggleModal() {
        //console.log('toggle')
        setIsOpen(!isOpen);
    }

    React.useImperativeHandle(ref, () => ({
        childFunction
    }));

    function childFunction() {
        // Function logic in the child component
        // console.log("Child function called from parent");
        toggleModal()
    }

    const wineDetails = props.data;

    if (props.render == true) {
        return (
            <div className="modalApp" style={{ width: '100%', height: '100%', opacity: '0%', }}>
                <button style={{
                    color: 'maroon',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    padding: '0rem',
                    // marginTop: '5rem',
                    //opacity: buttonOpacityVariable,
                    transition: 'opacity 1s',

                }} onClick={toggleModal}>VIEW DETAILS BUTTON</button>

                <div >
                
                    <Modal
                        isOpen={isOpen}
                        onRequestClose={toggleModal}
                        //contentLabel="modal_dialog"
                        className="modalClass"
                        overlayClassName="overlayClass"
                        closeTimeoutMS={500}
                    >
                        <ModalContents data={data} onRequestClose={toggleModal} ></ModalContents>
                    </Modal>

                </div>

            </div>
        )
    } else {
        return (
            <div></div>
        )
    }

})

export default ModalComponent;




// import CONFIG from '../../../config/config.js'
// const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
// const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL



// const [buttonOpacityVariable, setButtonOpacityVariable] = useState('100%');

// useEffect(() => {
//     if (isActive == true) {
//         setTimeout(() => { setButtonOpacityVariable('50%') }, 100)

//     }
//     if (isActive == false) {
//         setButtonOpacityVariable('0%')
//     }
// }, [isActive]);






import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';
//import SearchPanel from '../SearchPanel'
//import { connect } from 'react-redux';
import ResultItem from './ResultItem'
import Instructions from './Instructions'


import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_PANEL_COLOR = PAGE_CONSTANT_STYLES.page_panel_color


import { useState, useEffect, useRef } from 'react';

import { useMemo, useCallback } from 'react';
















const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',

            backgroundColor: PAGE_PANEL_COLOR, // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',

            // backgroundColor: '#011552',
            // opacity: '1.00',
            // paddingLeft: '0.5rem',
            // marginLeft: '1rem',
            marginRight: '1rem',

        }}
    >
        {props.children}
    </Box>
);


const InstructionTypography = (props) => (
    <Typography
        sx={{
            borderRadius: '5px',
            align: "center",
            fontSize: '1.2rem',
            fontWeight: 'bold',
            backgroundColor: '#edece8',
            color: 'black',
            margin: '0.5rem',
            opacity: '0.8',
            // marginTop:'0rem',
            // marginBottom:'1rem',
            padding: '5px',

        }}
    >
        {props.children}
    </Typography>
);

















const SearchResults = (props) => {


    const { sendActionToPage } = props
    const { favoritesData } = props



    function handleActionPanel(passObject) {

        //console.log(elementData)


        // let passObject = {
        //     statusToSet: !isFavorited,
        //     itemData: data,
        // }



        const itemDataAndStatus = {
            selectedType: 'updatefavorites',
            statusToSet: passObject.statusToSet,
            itemData: passObject.itemData,
        }

        // selectedObject: searchObjectInfo.activeSearchObject,
        // selectedSortBy: searchObjectInfo.sortBy,
        // selectedElement: elementTypeAndData.data,
        console.log('updatefavorites')

        sendActionToPage(itemDataAndStatus)

    }










    const triggerPointRef = useRef(null); // Ref to the element marking the trigger point

    // Extract data from props
    const { data } = props;
    let default_number_to_render = 5 //50
    const [renderIndex, setRenderIndex] = useState(0);
    let renderDisplayItemsLength = data.wineSearchResultsLength

    console.log('SEARCHRESULTS DATA ', data    )

    const handleReset = useCallback(() => {
        setRenderIndex((renderIndex) => default_number_to_render);
        /// console.log('default_number_to_render Updated renderIndex:', renderIndex); // Log for verification
    }, []);



    // Define the function to compute the memoized value (if applicable)
    const memoizedValue = useMemo(() => {
        // Expensive calculation or logic here (only runs when dependencies change)
        ///console.log('memo---------------')
        // setRenderIndex(default_number_to_render)
        handleReset()
        return data.wineSearchResults; // Example calculation


    }, [data.wineSearchResults]); // Dependency array




    // Define the function to compute the memoized value (if applicable)
    const memoizedValue2 = useMemo(() => {
        // Expensive calculation or logic here (only runs when dependencies change)


        // console.log('memo---------------')
        // console.log("triggerPointRef.current 4 ", triggerPointRef.current)

        console.log('RESULTS RENDERING memoizedValue.slice(0, renderIndex)  ', renderIndex,  memoizedValue.slice(0, renderIndex))

        console.log('memo---------------', memoizedValue)

        
        return memoizedValue.slice(0, renderIndex); // Example calculation
    }, [renderIndex, memoizedValue]); // Dependency array






    // console.log('memoizedValue ', memoizedValue)
    // console.log('memoizedValue2 ', memoizedValue2)

    // useEffect(() => {
    //     console.log('Updated renderIndex:--------------', renderIndex);
    //     console.log('memoizedValue ', memoizedValue)
    //     console.log('memoizedValue2 ', memoizedValue2)
    // }, [renderIndex]);








    const handleIntersection = useCallback((entries) => {
        setRenderIndex((renderIndex) => renderIndex + 5);
        ///console.log('Updated renderIndex:', renderIndex); // Log for verification
    }, []);

    useEffect(() => {


        const observer = new IntersectionObserver(handleIntersection, {
            root: null, // Observe within the viewport
            rootMargin: '0px 0px -100px 0px', // Observe bottom 100px of viewport
            threshold: 0 // Trigger when 100% of the element is visible
        });


        if (triggerPointRef.current) {
            observer.observe(triggerPointRef.current);
        }
        return () => observer.disconnect(); // Cleanup
    }, []); // Observe changes to triggerPointRef















    return (
        <div id="inspect_this_div" style={{ width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>
            <Grid container spacing={2}>
                <ButtonBox>
                    <Grid item xs={12} sm={8} md={6} >
                        <StyledBox>
                            <div id="inspect_this_div_2" style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                flexWrap: "wrap",
                                borderWidth: '2px',
                                borderColor: '#edece8',
                                borderStyle: 'solid',
                                paddingBottom: '1rem',
                                marginBottom: '0rem',
                            }}>
                                <div style={{
                                    width: '100%', height: 'auto'
                                }}
                                >

                                    <Instructions renderDisplayItems={memoizedValue2}></Instructions>
                                    {/* <button onClick={handleIncrement}>Increment</button> */}



                                    {/* {favoritesData.itemData.map((item, index) => (
                                        <ResultItem
                                            key={`displayFavoriteItem_${index}`}
                                            id={index + 1}
                                            data={item}
                                            renderDisplayItemsLength={renderDisplayItemsLength}
                                            handleAction={handleActionPanel}
                                            favoritesData={favoritesData}
                                        ></ResultItem>
                                    ))} */}






                                    {memoizedValue2.map((item, index) => (
                                        <ResultItem
                                            key={`displayItem_${index}`}
                                            id={index + 1}
                                            data={item}
                                            renderDisplayItemsLength={renderDisplayItemsLength}
                                            handleAction={handleActionPanel}
                                            favoritesData={favoritesData}
                                        ></ResultItem>
                                    ))}





                                </div>


                            </div>
                        </StyledBox>
                    </Grid >
                </ButtonBox>
            </Grid >

            <div ref={triggerPointRef} id='triggerPointRef' style={{ visibility: 'hidden', height: 1 }}  >

                {/* 
                
                style={{ visibility: 'hidden', height: 1 }} 
                style={{height: "200px" }}
                
                */}

                Load More
            </div>

        </div >
    );
    //}
};

export default SearchResults;



import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import App from './App';

import Modal from 'react-modal';
Modal.setAppElement('#root');



import './index.css';

import store from './redux/store'; // Import the store


// import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './redux/reducers/rootReducer'; // import the root reducer
// import thunkMiddleware from 'redux-thunk';

// Modal.setAppElement('#root');

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       thunk: {
//         extraArgument: thunkMiddleware
//       }
//     })
// })

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
      {/* <BrowserRouter> */}
        <App />
      {/* </BrowserRouter> */}
    </Provider>
);


// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>
//   </React.StrictMode>
// );








/*

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

*/
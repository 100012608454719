import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';
import { Checkbox } from '@mui/material';
import { useState } from "react";

import imgRed from '../../../../images/red.png'
import imgWhite from '../../../../images/white.png'
import imgBubbles from '../../../../images/bubbles.png'
import imgRose from '../../../../images/rose.png'
import imgOrange from '../../../../images/orange.png'
import imgBourbon from '../../../../images/bourbon.png'
import imgDessert from '../../../../images/dessert.png'

import imgOldWorldRed from '../../../../images/old_world_red.png'
import imgNewWorldRed from '../../../../images/new_world_red.png'





import italy from '../../../../images/region/italy.png'
import france from '../../../../images/region/france.png'
import california from '../../../../images/region/california.png'

import australia from '../../../../images/region/australia.png'
import austria from '../../../../images/region/austria.png'
import germany from '../../../../images/region/germany.png'
import hungary from '../../../../images/region/hungary.png'
import new_zealand from '../../../../images/region/new_zealand.png'
import spain from '../../../../images/region/spain.png'

import arizona from '../../../../images/region/arizona.png'
import south_africa from '../../../../images/region/south_africa.png'
import tasmania from '../../../../images/region/tasmania.png'
import oregon from '../../../../images/region/oregon.png'

import washington from '../../../../images/region/washington.png'
import colorado from '../../../../images/region/colorado.png'

import g_red from '../../../../images/grape/red.png'
import g_white from '../../../../images/grape/white.png'
import g_bubbles from '../../../../images/grape/bubbles.png'
import g_rose from '../../../../images/grape/rose.png'
import g_orange from '../../../../images/grape/orange.png'

import pinotnoir from '../../../../images/grape/pinotnoir.jpeg'
import albarino from '../../../../images/grape/albarino.jpeg'


import glera from '../../../../images/grape/glera.png'
import chardonnay from '../../../../images/grape/chardonnay.png'
import grenache from '../../../../images/grape/grenache.jpeg'
import zinfandel from '../../../../images/grape/zinfandel.jpeg'
import malbec from '../../../../images/grape/malbec.jpeg'
import nebbiolo from '../../../../images/grape/nebbiolo.jpeg'
import cabernetsauvignon from '../../../../images/grape/cabernetsauvignon.jpeg'
import tempranillo from '../../../../images/grape/tempranillo.png'


import portugal from '../../../../images/region/portugal.png'
import florida from '../../../../images/region/florida.png'
import greece from '../../../../images/region/greece.png'
import argentina from '../../../../images/region/argentina.png'

import bourbonMash from '../../../../images/grape/bourbonMash.png'

import sauvignon_blanc from '../../../../images/grape/sauvignon_blanc.png'
import new_mexico from '../../../../images/region/new_mexico.png'
import chile from '../../../../images/region/chile.png'
import earth from '../../../../images/region/earth.png'
import israel from '../../../../images/region/israel.png'


// australia.png   hungary.png
// austria.png     italy.png
// california.png  new_zealand.png
// france.png      spain.png
// germany.png



// FONT
import '../../../../fonts/font.css'



const StyledPaper = (props) => (
    <Paper
        sx={{
            // backgroundImage: `url(${white})`, 
            //backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            //objectFit: 'scale-down',
            backgroundImage: `url(${imgWhite})`,
            backgroundSize: 'contain', // cover
            backgroundPosition: 'center',
            //animation: 'fade-in 3s forwards',
            backgroundColor: 'white',
            color: 'black',
            minHeight: '7rem',
            width: '100%',
            padding: '2px'
        }}
    >
        {props.children}
    </Paper>
);

// const ImagePaper = (props) => (
//     <Paper
//         sx={{
//             // backgroundImage: `url(${white})`, 
//             //backgroundAttachment: 'fixed',
//             //repeat: 'no-repeat',
//             //objectFit: 'scale-down',
//             // backgroundImage: `url(${white})`,
//             // backgroundSize: 'cover',
//             //animation: 'fade-in 3s forwards',
//             // height: '100%', width: '100%', padding: '0px'
//         }}
//     >
//         {props.children}
//     </Paper>
// );

const StyledBoxCheck = (props) => (
    <Box
        sx={{
            // backgroundImage: `url(${white})`,
            display: 'flex',
            // flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'flex-end',
            // width: '100%', //'100%',
            // height: '100%', //'100%',
        }}
    >
        {props.children}
    </Box>
);

const StyledBoxCheck2 = (props) => (
    <Box
        sx={{
            // backgroundImage: `url(${white})`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            width: '100%', //'100%',
            height: '100%', //'100%',
        }}
    >
        {props.children}
    </Box>
);
const LargeCheckBox = (props) => (
    <Checkbox
        sx={{
            color: "#034488",
            '&.Mui-checked': {
                color: "#034488",
            },
            transform: "scale(2)",

        }}
    >
        {props.children}
    </Checkbox>
);








const CheckboxComponent = (props) => {

    // Extract data from props
    const { data } = props;
    const { handleAction } = props;
    const { sortBy } = props;
    // console.log('data', data)
    // Cabernet Sauvignon

    const [checkedStatus, setCheckedStatus] = useState(data.property[1]);


    const imageDataMap = {
        bubbles: imgBubbles,
        sparkling: imgBubbles,
        sparklingwine: imgBubbles,
        white: imgWhite,
        whitewine: imgWhite,
        rosé: imgRose,
        rose: imgRose,
        orange: imgOrange,
        red: imgRed,

        newworldreds: imgNewWorldRed,
        oldworldreds: imgOldWorldRed,
        nwred: imgNewWorldRed,
        owred: imgOldWorldRed,

        bourbon: imgBourbon,
        dessert: imgDessert,
        dessertwine: imgDessert,



        italy: italy,
        france: france,
        california: california,

        australia: australia,
        austria: austria,
        germany: germany,

        hungary: hungary,
        newzealand: new_zealand,
        spain: spain,

        arizona: arizona,
        southafrica: south_africa,
        tasmania: tasmania,
        oregon: oregon,
        washington: washington,
        colorado: colorado,

        portugal: portugal,
        florida: florida,
        greece: greece,
        argentina: argentina,

        newmexico: new_mexico,
        chile: chile,
        empty: earth,
        israel: israel,
    }

    const imageDataMap2 = {
        bubbles: g_bubbles,
        sparkling: g_bubbles,
        white: g_white,
        rosé: g_rose,
        orange: g_orange,
        red: g_red,
        newworldreds: g_red,
        oldworldreds: g_red,
        whitewine: g_white,
        sparklingwine: g_bubbles,
        rose: g_rose,

        dessertwine: g_red,

        pinotnoir: pinotnoir,
        albarino: albarino,
        glera: glera,
        chardonnay: chardonnay,
        grenache: grenache,
        zinfandel: zinfandel,
        malbec: malbec,
        nebbiolo: nebbiolo,
        cabernetsauvignon: cabernetsauvignon,
        tempranillo: tempranillo,
        bourbon: bourbonMash,
        sauvignonblanc: sauvignon_blanc

    }

    let checkBoxOpacity = '100%'
    let imageSelected = null
    let checkbox_label = data.property[0]
    if (checkbox_label.length > 30) {
        checkbox_label = checkbox_label.substring(0, 25) + "...";
    }


    if (sortBy == 'type') {

        let property_with_spaces = data.property[3]['color']
        let property_no_spaces = property_with_spaces.replace(/\s/g, '');

        // imageSelected = imageDataMap[data.property[3]['color']]
        imageSelected = imageDataMap[property_no_spaces]

        ///// console.log(imageSelected, data.property[3]['color'], data.property[3]['grape'] )
    }
    else if (sortBy == 'grape') {

        let property_with_spaces = data.property[3]['color']
        let property_no_spaces = property_with_spaces.replace(/\s/g, '');

        imageSelected = imageDataMap2[property_no_spaces]

        //imageSelected = imageDataMap2[data.property[3]['color']]
        // if (imageDataMap2.hasOwnProperty(data.property[3]['grape']) ){
        //     console.log('hasOwnProperty')
        //     imageSelected = imageDataMap2[data.property[3]['grape']]
        // }
        console.log(imageSelected, data.property[3]['color'], data.property[3]['grape'])

    }
    else if (sortBy == 'region') {
        if (imageDataMap[data.property[3]['region']]) {
            imageSelected = imageDataMap[data.property[3]['region']]
            //checkBoxOpacity = '95%'
        } else {
            imageSelected = ''
        }

    }


    //console.log('imageSelected ', data)



    function toggleCheckboxElement(elementData) {
        //if (devModeBool == true) {
        //console.log("taste toggle ", argument[0])
        console.log('data ', data)
        console.log(elementData)
        //}
        //setCheckedStatus(!checkedStatus)

        const elementTypeAndData = {
            type: 'checkbox',
            data: elementData,
        }
        // handleAction(elementData)
        handleAction(elementTypeAndData)
        //onData(argument); checked={data.property[1]} onClick={() => this.setState({isTrue: !this.state.isTrue})}
    }




    return (
        <div>

            <Paper

                onClick={() => {
                    toggleCheckboxElement(data.property)
                }}


                sx={{
                    // backgroundImage: `url(${white})`, 
                    //backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat',
                    //objectFit: 'scale-down',
                    backgroundImage: `url(${imageSelected})`,
                    backgroundSize: 'contain', // cover
                    backgroundPosition: 'center',
                    //animation: 'fade-in 3s forwards',
                    backgroundColor: '#16181e',//'white',
                    //opacity: '80%',
                    color: 'black',
                    minHeight: '9rem',
                    width: '100%',
                    padding: '2px'
                }}
            >
                <Box
                    sx={{
                        // backgroundImage: `url(${white})`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "space-around",
                        // alignItems: 'flex-end',
                        width: '100%', //'100%',
                        height: '8rem', //'100%',
                    }}
                >
                    <StyledBoxCheck2 >
                        <Typography
                            sx={{

                                fontFamily: 'imported-font-buttons',

                                color: 'white',//'#82878e',
                                textAlign: 'center',
                                fontSize: '1rem',
                                backgroundColor: '#42464c',//'#edece8',
                                opacity: '75%',
                                padding: '3px',
                                borderRadius: '3px',
                                margin: '0px',
                                marginTop: '3px',
                                height: 'auto',
                                fontWeight: 'bold',
                                // }}  >{data.property[0]}</Typography>
                            }}  >{checkbox_label}</Typography>





                    </StyledBoxCheck2>
                    <StyledBoxCheck >

                        <Checkbox
                            disableRipple
                            sx={{
                                color: "#034488",
                                opacity: checkBoxOpacity, // '70%', '50%', //
                                '&.Mui-checked': {
                                    color: "#034488",
                                },
                                transform: "scale(2)",

                            }}

                            // onClick={() => {
                            //     toggleCheckboxElement(data.property)
                            // }}

                            checked={data.property[1]}

                        >
                        </Checkbox>

                        {/* <div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={data.property[1]}
                                    onClick={() => {
                                        toggleCheckboxElement(data.property)
                                    }}
                                    style={{
                                        margin: '1rem',
                                        transform: 'scale(2.5)', // Scale the checkbox by 1.5
                                        transition: 'none', // Disable all transitions
                                        //color: "#034488",
                                        accentColor: "#034488",
                                    }}

                                    sx={{
                                        backgroundColor: 'blue',
                                        padding: '20px',
                                        color: 'white', // Default text color
                                        '&:hover': {
                                            backgroundColor: 'lightblue',
                                        },
                                        '&:active': {
                                            backgroundColor: 'darkblue',
                                            color: 'yellow', // Change text color on active
                                        }
                                    }}
                                />

                            </label>
                        </div> */}


                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '1.3rem',

                                color: '#82878e',
                                //backgroundColor: '#edece8',
                                backgroundColor: '#42464c',//'#edece8',

                                //opacity: '75%',
                                padding: '3px',
                                borderRadius: '3px',
                                margin: '3px',
                                height: 'auto',
                                width: '2rem',
                                fontWeight: 'bold',
                            }}  >{data.length}</Typography>


                    </StyledBoxCheck>
                </Box>
            </Paper>

        </div >
    );
};

export default CheckboxComponent;





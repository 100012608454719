import React, { useState } from 'react';
import { Search as MuiSearch } from '@mui/icons-material';
import { InputBase, IconButton, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';






import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_BUTTON_COLOR_SELECTED = PAGE_CONSTANT_STYLES.page_button_color_selected  // '#151269'
const PAGE_BUTTON_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.page_button_color_default
const PAGE_BUTTON_TEXT_COLOR = PAGE_CONSTANT_STYLES.page_button_text_color


const SEARCH_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.search_item_text_color
const SEARCH_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.search_item_background_color



// FONT
import '../../../fonts/font.css'







// Custom styled component for the SearchBar
const SearchBarContainer = styled('div')({
    //position: 'relative',
    borderRadius: '10px', // Or your desired MUI theme value
    // backgroundColor: 'rgba(255, 255, 255, 0.8)', // 80% opacity white background
    // '&:hover': {
    //     backgroundColor: 'rgba(255, 255, 255, 0.9)', // Slightly more opaque on hover
    // },
    margin: '10px',
    // marginRight: '10px',
    // marginLeft: '10px',
    // width: '100%',
    //color: {SEARCH_ITEM_TEXT_COLOR},
    backgroundColor: { SEARCH_ITEM_BACKGROUND_COLOR },
});











const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            // paddingBottom: '2rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            // backgroundColor: PAGE_PANEL_COLOR, // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',
            marginRight: '1rem',

        }}
    >
        {props.children}
    </Box>
);

















const SearchBar = (props) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchIn, setSearchIn] = useState(['name', 'description']); // Default to searching in 'name'




















    // function handleActionPanel(elementTypeAndData) {

    //     //console.log(elementData)

    //     const elementLocationAndData = {
    //         selectedObject: searchObjectInfo.activeSearchObject,
    //         selectedSortBy: null,
    //         selectedType: 'searchbar',
    //         selectedElement: null,
    //     }
    //     console.log(elementLocationAndData)
    //     sendActionToPage(elementLocationAndData)

    // }





    const { searchObjectInfo } = props
    const { sendActionToPage } = props







    const [searchTimeout, setSearchTimeout] = useState(null);


    const handleInputChange = (event) => {

        // Clear any existing timeout
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }



        const newQuery = event.target.value;
        setSearchQuery(newQuery);
        // Dispatch Redux action here (eventually)
        //console.log('Search triggered:', newQuery, searchIn);


        // const searchObject = {
        //     selectedObject: null,
        //     selectedSortBy: null,
        //     selectedType: 'searchbar',
        //     selectedElement: null,
        //     searchTerm: newQuery,
        //     searchFields: searchIn,
        // }


        // sendActionToPage(searchObject)




        // Set a new timeout to trigger the search after 500ms
        const newTimeout = setTimeout(() => {
            handleSearchClick(); // Replace with your actual search function
        }, 1000);
        setSearchTimeout(newTimeout);

    };





    const handleSearchClick = (event) => {



        const searchObject = {
            selectedObject: null,
            selectedSortBy: null,
            selectedType: 'searchbar',
            selectedElement: null,
            searchTerm: searchQuery,
            searchFields: searchIn,
        }


        console.log('SEARCHING')
        sendActionToPage(searchObject)


    };

    const clearSearchQuery = () => {


        setSearchQuery('')

        const searchObject = {
            selectedObject: null,
            selectedSortBy: null,
            selectedType: 'searchbar',
            selectedElement: null,
            searchTerm: '',
            searchFields: searchIn,
        }


        console.log('SEARCHING')
        sendActionToPage(searchObject)


    };

















    const handleSearchInChange = (event, newSearchIn) => {
        setSearchIn(newSearchIn);
    };

    return (



        <div style={{
            width: '100%',
            marginTop: '2rem', display: 'flex', flexDirection: 'row',
            justifyContent: 'center', flexWrap: "wrap",
        }}>

            <Grid container spacing={2}>
                <ButtonBox>
                    <Grid item xs={12} sm={8} md={6} >
                        <StyledBox>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                flexWrap: "wrap",
                                borderWidth: '2px',
                                borderColor: '#edece8',
                                borderStyle: 'solid',
                                paddingBottom: '0rem',
                            }}>





                                <div style={{ display: 'flex', flexDirection: 'column', }}>



                                    <div>



                                        {/* <input
                                            type="text"
                                            // value={yourStateVariable}
                                            // onChange={yourChangeHandler}
                                            placeholder="Enter your text here"
                                            style={{
                                                width: '100%',
                                                padding: '10px',
                                                border: '1px solid lightgray',
                                                borderRadius: '5px',
                                                resize: 'none', // Prevent resizing
                                            }}
                                        /> */}



                                        <div
                                            style={{
                                                backgroundColor: SEARCH_ITEM_BACKGROUND_COLOR,
                                                margin: '10px',
                                                borderRadius: '10px',
                                            }}
                                        >


                                            {/* <textarea
                                                // value={yourStateVariable}
                                                // onChange={yourChangeHandler}
                                                placeholder="Enter your text here"
                                                rows={4} // Adjust the number of rows as needed
                                            /> */}


                                            <InputBase
                                                placeholder="Enter Search Term..."
                                                value={searchQuery}
                                                onChange={handleInputChange}
                                                sx={{
                                                    width: '100%',
                                                    margin: '0px',
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                    fontSize: '2rem',
                                                    color: SEARCH_ITEM_TEXT_COLOR,

                                                }}

                                            // startAdornment={<MuiSearch onClick={() => handleSearchClick()} sx={{ width: '3rem', height: '3rem', color: { SEARCH_ITEM_TEXT_COLOR }, mr: 1, my: 0.5 }} />}


                                            />

                                        </div>


                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                borderRadius: '1rem',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                        >





                                            <div onClick={() => handleSearchClick()}

                                                style={{
                                                    fontFamily: 'imported-font-buttons',
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    color: PAGE_BUTTON_TEXT_COLOR,
                                                    backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // 'gray',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-around',
                                                    margin: '0.5rem',
                                                    padding: '0.5rem',
                                                    borderRadius: '0.5rem',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    height:'3rem',

                                                }}>
                                                <div>
                                                    <MuiSearch sx={{ width: '2rem', height: '2rem', color: { SEARCH_ITEM_TEXT_COLOR } }} />

                                                </div>
                                                <div >
                                                    <p style={{ fontSize: '1.2rem', margin: '0rem', textAlign:'center', }}>SEARCH NOW</p>
                                                </div>

                                            </div>




                                            <div
                                                onClick={() => clearSearchQuery()}
                                                style={{
                                                    fontFamily: 'imported-font-buttons',
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    color: PAGE_BUTTON_TEXT_COLOR,
                                                    backgroundColor: PAGE_BUTTON_COLOR_DEFAULT, // 'gray',

                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-around',
                                                    margin: '0.5rem',
                                                    padding: '0.5rem',
                                                    borderRadius: '0.5rem',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    height:'3rem',
                                                }}
                                            >
                                                <p style={{ fontSize: '1.2rem', margin: '0rem', textAlign:'center', }}>CLEAR SEARCH</p>
                                            </div>





                                        </div>




                                    </div>




                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', }}>


                                        {/* <ToggleButton value="name" aria-label="search in name">Name</ToggleButton>
                                            <ToggleButton value="description" aria-label="search in description">Description</ToggleButton> */}
                                    </div>


                                </div>





                            </div>
                        </StyledBox>
                    </Grid >
                </ButtonBox>
            </Grid >

        </div >




    );
};

export default SearchBar;

































// <div
//     style={{
//         width: '100%',
//         marginTop: '2rem',
//         display: 'flex',
//         flexDirection: 'row',
//         justifyContent: 'center',
//         flexWrap: "wrap",
//     }}>

//     <SearchBarContainer>
//         <div>

//             <InputBase
//                 placeholder="Search..."
//                 value={searchQuery}
//                 onChange={handleInputChange}
//                 sx={{
//                     width: '100%',
//                     paddingLeft: '20px',
//                     paddingRight: '20px',
//                 }}
//                 startAdornment={<MuiSearch sx={{ color: 'action.active', mr: 1, my: 0.5 }} />}
//             />

//         </div>


//         <div>

//             <ToggleButtonGroup
//                 value={searchIn}
//                 onChange={handleSearchInChange}
//                 exclusive
//                 aria-label="search in"
//                 sx={{ position: 'absolute', bottom: -25, right: 0 }}
//             >
//                 <ToggleButton value="name" aria-label="search in name">Name</ToggleButton>
//                 <ToggleButton value="description" aria-label="search in description">Description</ToggleButton>
//             </ToggleButtonGroup>

//         </div>

//     </SearchBarContainer>

// </div>
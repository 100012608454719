import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';

const GLOBAL_OPACITY = '90%'

import { useState, useEffect, useRef } from "react";





import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const PAGE_BUTTON_COLOR_SELECTED = PAGE_CONSTANT_STYLES.page_button_color_selected  // '#151269'
const PAGE_BUTTON_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.page_button_color_default
const PAGE_BUTTON_TEXT_COLOR = PAGE_CONSTANT_STYLES.page_button_text_color 





// FONT
import '../../../fonts/font.css'









const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            //backgroundColor: '#ccdde8',
            opacity: '1.00',
            paddingLeft: '0.5rem',
            marginLeft: '1rem',
            marginRight: '1rem',
        }}
    >
        {props.children}
    </Box>
);

const CategoryButtonBox = (props) => (
    <Box
        sx={{
            //backgroundColor: '#034488',
            borderRadius: '5px',
            //width: '7rem',
            //marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);










const SortByPanel = (props) => {

    // Extract data from props
    const searchCategory = props.searchCategory

    const { setSortByFunction } = props
    const { sortBy } = props

    const { buttonsActiveState } = props
    // selectedType: 'searchcategory',
    // searchCategory: 'glasses',
    // sortBy: sortBy,


    const { sendActionToPage } = props

    function handleActionPanel(data) {
        const panelPayload = {
            selectedType: 'sortby',
            sortBy: data,
        }
        console.log(panelPayload)
        sendActionToPage(panelPayload)

        // setSortByFunction(data)

    }



    useEffect(() => {
        console.log('Component re-rendered due to count change');
    }, [sortBy]);




    // function setSortByPanelFunction(argument) {
    //     setSortByFunction(argument);
    // }

    const colorMap = {
        type: {
            type: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1', // '#084d97',
            grape: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
            region: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
        },
        grape: {
            type: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
            grape: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1',
            region: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
        },
        region: {
            type: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
            grape: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
            region: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1',
        }
    }


    //const [sortByMap, setSortByMap] = useState(colorMap[sortBy]);



    const buttonRefType = useRef(null);
    const buttonRefVarietal = useRef(null);
    const buttonRefRegion = useRef(null);

    const [typeButtonPressed, setTypeButtonPressed] = useState(false);
    const handleTypeTouchStart = (event) => {
        setVarietalButtonPressed(false);
        setRegionButtonPressed(false);
        if (buttonsActiveState == true) {
            setTypeButtonPressed(true);
            // setVarietalButtonPressed(false);
            // setRegionButtonPressed(false);
        } else {
            setTypeButtonPressed(false);
        }
        //console.log('type')

        //setSortByFunction('type');


        //handleTypeTouchEnd()
    };
    const handleTypeTouchEnd = (event) => {
        setTimeout(() => {
            if (buttonsActiveState == true) {
                setVarietalButtonPressed(false);
                setRegionButtonPressed(false);
                handleActionPanel('type');
            }
        }, 100);

        setTimeout(() => {
            setTypeButtonPressed(false);
            buttonRefType.current.blur()
            buttonRefVarietal.current.blur()
            buttonRefRegion.current.blur()
        }, 250);

    };






    const [varietalButtonPressed, setVarietalButtonPressed] = useState(false);
    const handleVarietalTouchStart = (event) => {
        setTypeButtonPressed(false);
        setRegionButtonPressed(false);
        if (buttonsActiveState == true) {
            setVarietalButtonPressed(true);
        } else {
            setVarietalButtonPressed(false);
        }
        //console.log('grape')

        //setSortByFunction('grape');

    };
    const handleVarietalTouchEnd = (event) => {
        setTimeout(() => {
            if (buttonsActiveState == true) {
                handleActionPanel('grape');
                setTypeButtonPressed(false);
                setRegionButtonPressed(false);
            }
        }, 100);

        setTimeout(() => {
            setVarietalButtonPressed(false);
            buttonRefType.current.blur()
            buttonRefVarietal.current.blur()
            buttonRefRegion.current.blur()
        }, 250);
    };






    const [regionButtonPressed, setRegionButtonPressed] = useState(false);
    const handleRegionTouchStart = (event) => {

        setTypeButtonPressed(false);
        setVarietalButtonPressed(false);

        if (buttonsActiveState == true) {
            // setTypeButtonPressed(false);
            setVarietalButtonPressed(false);
            // setRegionButtonPressed(true);
        } else {
            setRegionButtonPressed(false);
        }

        //setSortByFunction('region');

    };
    const handleRegionTouchEnd = (event) => {
        setTimeout(() => {
            if (buttonsActiveState == true) {
                handleActionPanel('region');
                setTypeButtonPressed(false);
                setVarietalButtonPressed(false);
            }
        }, 100);

        setTimeout(() => {
            setRegionButtonPressed(false);
            buttonRefType.current.blur()
            buttonRefVarietal.current.blur()
            buttonRefRegion.current.blur()
        }, 250);
    };








    if (searchCategory.toLowerCase() == 'flights') {
        return (<div></div>)
    } else {
        return (


            <div>

                <Box sx={{

                    textAlign: 'center',
                    position: 'relative',
                }}>
                    <Typography align="center" fontSize='1rem' fontWeight='bold'
                        style={{
                            fontFamily: 'imported-font-labels',

                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-1%, 10%)',
                            paddingBottom: '1rem', paddingLeft: '0rem', margin: '0rem',
                            color: PAGE_BUTTON_TEXT_COLOR,
                        }}
                    >Sort By
                    </Typography>
                </Box>


                <Grid container spacing={2}>
                    <ButtonBox>
                        <Grid item xs={12} sm={8} md={6} >
                            <StyledBox>

                                <CategoryButtonBox>
                                    <Button
                                        ref={buttonRefType}
                                        sx={{
                                            fontFamily: 'imported-font-buttons',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            width: '6rem',
                                            borderRadius: '5px',
                                            //backgroundColor: '#034488',
                                            backgroundColor: colorMap[sortBy]['type'],
                                            boxShadow: typeButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                            color: '#ccdde8', // typeButtonPressed ? '#fffcf6' : '#ccdde8',
                                            '&:hover': {
                                                backgroundColor: colorMap[sortBy]['type'], //'#034488',
                                            },
                                            '&:active, &:focus': {
                                                boxShadow: 'none',
                                                backgroundColor: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1',
                                                //color: '#edece8',
                                            }
                                        }}
                                        onTouchStart={handleTypeTouchStart}
                                        onTouchEnd={handleTypeTouchEnd}
                                        onMouseDown={handleTypeTouchStart}
                                        onMouseUp={handleTypeTouchEnd}
                                    >Type</Button>
                                </CategoryButtonBox>


                                <CategoryButtonBox>
                                    <Button
                                        ref={buttonRefVarietal}
                                        sx={{
                                            fontFamily: 'imported-font-buttons',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            width: '6rem',
                                            borderRadius: '5px',
                                            backgroundColor: colorMap[sortBy]['grape'],
                                            boxShadow: varietalButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                            color: '#ccdde8', // varietalButtonPressed ? '#fffcf6' : '#ccdde8',
                                            '&:hover': {
                                                backgroundColor: colorMap[sortBy]['grape'], //'#034488',
                                            },
                                            '&:active, &:focus': {
                                                boxShadow: 'none',
                                                backgroundColor: PAGE_BUTTON_COLOR_SELECTED, //'#0d6cd1',
                                                //color: '#edece8',
                                            }
                                        }}
                                        onTouchStart={handleVarietalTouchStart}
                                        onTouchEnd={handleVarietalTouchEnd}
                                        onMouseDown={handleVarietalTouchStart}
                                        onMouseUp={handleVarietalTouchEnd}
                                    >VARIETAL</Button>
                                </CategoryButtonBox>

                                <CategoryButtonBox>
                                    <Button
                                        ref={buttonRefRegion}
                                        sx={{
                                            fontFamily: 'imported-font-buttons',
                                            fontSize: '1.1rem',
                                            fontWeight: 'bold',
                                            width: '6rem',
                                            borderRadius: '5px',
                                            backgroundColor: colorMap[sortBy]['region'],
                                            boxShadow: regionButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                            color: '#ccdde8', // regionButtonPressed ? '#fffcf6' : '#ccdde8',
                                            '&:hover': {
                                                backgroundColor: colorMap[sortBy]['region'], //'#034488',
                                            },
                                            '&:active, &:focus': {
                                                boxShadow: 'none',
                                                backgroundColor: PAGE_BUTTON_COLOR_SELECTED, //'#0d6cd1',
                                                //color: '#edece8',
                                            }
                                        }}
                                        onTouchStart={handleRegionTouchStart}
                                        onTouchEnd={handleRegionTouchEnd}
                                        onMouseDown={handleRegionTouchStart}
                                        onMouseUp={handleRegionTouchEnd}
                                    >Region</Button>
                                </CategoryButtonBox>


                            </StyledBox>
                        </Grid >
                    </ButtonBox>
                </Grid >




                {/* 
                
                
                setSortByPanelFunction('region')
                
                                                <Button style={{ opacity: GLOBAL_OPACITY, padding: '5px', margin: '0rem', width: '5rem', backgroundColor: 'gray' }}
                                    onClick={() => {
                                        setSortByPanelFunction('region')
                                    }}
                                >REGION</Button>




                <Typography align="center" fontSize='1rem' backgroundColor='black' color='rgb(171,18,57)'
                    style={{ opacity: GLOBAL_OPACITY, padding: '0px', margin: '0px', width: '100vw' }}
                >
                    SORT BY:
                </Typography> */}


                {/* <Grid item xs={12} sm={12} md={12} style={{
                    padding: '5px',
                    paddingTop: '0px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}>
                    <Button style={{ opacity: GLOBAL_OPACITY, padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('type')
                        }}
                    >TYPE</Button>

                    <Button style={{ opacity: GLOBAL_OPACITY, padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('grape')
                        }}
                    >GRAPE</Button>
                    <Button style={{ opacity: GLOBAL_OPACITY, padding: '0px', margin: '0px', width: '30vw', backgroundColor: 'gray' }}
                        onClick={() => {
                            setSortByPanelFunction('region')
                        }}
                    >REGION</Button>

                </Grid> */}

            </div >
        );


    }

};

export default SortByPanel;



/*

                                    <CategoryButtonBox>
                                        <Button
                                            sx={{
                                                //touchAction: 'none',
                                                width: '8rem',
                                                borderRadius: '5px',
                                                border: '10px',
                                                borderColor: 'gray',
                                                backgroundColor: '#034488', // Your button color
                                                boxShadow: bottlesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)', // Adjust the shadow styles
                                                color: bottlesButtonPressed ? '#fffcf6' : '#ccdde8',
                                                '&:active': {
                                                    boxShadow: 'none', // Remove shadow when pressed
                                                    backgroundColor: '#034488',
                                                    color: '#edece8',
                                                }
                                            }}
                                            onTouchStart={handleBottlesTouchStart}
                                            onTouchEnd={handleBottlesTouchEnd}
                                            onMouseDown={handleBottlesTouchStart}
                                            onMouseUp={handleBottlesTouchEnd}
                                        >Bottles</Button>
                                    </CategoryButtonBox>


*/
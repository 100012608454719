



import React, { useState, useEffect } from "react";
import { useTransition, animated } from "react-spring";

import image1 from "./yayas-banner-footer.png"
// import image2 from "./banner-2.png"
// import image3 from "./banner-3.png"
// import image4 from "./banner-4.png"
// import image5 from "./banner-5.png"


import image2 from "./banner-6.png"
import image3 from "./banner-7.png"
import image4 from "./banner-8.png"
import image5 from "./banner-9.png"


// import CONFIG from '../../config/config.js'
// const FOOD_IMAGE_SERVER_IP = CONFIG.FOOD_IMAGE_SERVER_IP
// const FOOD_IMAGE_SERVER_URL = CONFIG.FOOD_IMAGE_SERVER_URL


const FadeInImage = (props) => {


    const images = [
        { id: 1, image: image1 },
        { id: 2, image: image2 },
        { id: 3, image: image3 },
        { id: 4, image: image4 },
        { id: 5, image: image5 },
    ];

    // Extract data from props
    const imageData = props.images;

    let elementID = -1
    // const images = imageData.map(element => {
    //     elementID = elementID + 1
    //     return {
    //         id: elementID,
    //         image: FOOD_IMAGE_SERVER_IP + FOOD_IMAGE_SERVER_URL + element,

    //         // image: 'http://192.168.3.18:6996/images/' + element,
        
    //     };
    // });

    const [index, setIndex] = useState(0);

    const transition = useTransition(images[index], {
        key: images[index].id, // Add a unique key
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 2000 },
    });



    const [timerLength, setTimerLength] = useState(4000);

    useEffect(() => {
      const timer = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, timerLength);
    
      return () => clearInterval(timer);
    }, [images.length, timerLength]);
    








    return (
        <div style={{
            //height: '10rem', 
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            //overflow: "hidden",
        }}>



            {transition((style, item) => (
                <animated.div
                    key={item.id}
                    style={{
                        ...style,
                        position: "absolute",
                        top: '8rem',
                        left: '0rem',
                        right: 0,
                        bottom: 0,
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: "cover",
                        //position: "relative",
                        backgroundPosition: "center center",
                        width:'100%',
                        height: "15rem", /* Adjust height as needed */
                    }}
                />
            ))}


        </div>
    );
};

export default FadeInImage;

















































// import React, { useState, useEffect } from 'react'
// import { useTransition, animated } from '@react-spring/web'
// import styles from './styles.css'

// const slides = [
//   'photo-1544511916-0148ccdeb877',
//   'photo-1544572571-ab94fd872ce4',
//   'reserve/bnW1TuTV2YGcoh1HyWNQ_IMG_0207.JPG',
//   'photo-1540206395-68808572332f',
// ]



// const FadeInImage = (props) => {
//   const [index, set] = useState(0)
//   const transitions = useTransition(index, {
//     key: index,
//     from: { opacity: 0 },
//     enter: { opacity: 1 },
//     leave: { opacity: 0 },
//     config: { duration: 3000 },
//   })


//   // useEffect(() => {
//   //   const t = setInterval(() => set(state => (state + 1) % slides.length), 4000)
//   //   return () => clearTimeout(t)
//   // }, [])


//   return (
//     <div className="flex fill center">



//       {transitions((style, i) => (
//         <animated.div
//           className={styles.bg}
//           style={{
//             ...style,
//             backgroundImage: `url(https://images.unsplash.com/${slides[i]}?w=1920&q=80&auto=format&fit=crop)`,
//           }}
//         />
//       ))}
//     </div>
//   )
// }
// export default FadeInImage;

import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color

const PriceItem = (props) => {

    // Extract data from props
    const { data } = props

    const price  = data.price;
    const measure  = data.measure;

    let priceArray = []

    let displayPrice = measure + ': $' + price

    // priceArray.push(measure)
    // priceArray.push(': $')
    // priceArray.push(price)

    priceArray.push(displayPrice)
    


    

    return (
        <div>

            <Card sx={{
                backgroundColor: RESULT_ITEM_COLOR, // '#8dbbe6',
                color: RESULT_ITEM_TEXT_COLOR,
                marginTop: '0.5rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                fontWeight: 'bold',
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',

                }}>

                    {/* <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }} >Price</Typography> */}
                    {/* <Typography style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{display_glass_price}</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{display_bottle_price}</Typography> */}

                    {priceArray.map((item, index) => (

                        <Typography

                            sx={{
                                fontWeight: 'bold', fontSize: '1.2rem'
                            }}

                            key={`price-${index}`}

                        >
                            {item}
                        </Typography>

                    ))}


                </div>


            </Card>

        </div >
    );
};

export default PriceItem;


// selectors.js

export const FULL_WINE_DATA = (state) => state.full_wine_data;
export const SEARCH_OBJECTS = (state) => state.data_search_objects;
export const SEARCH_CATEGORY = (state) => state.selected_search_category;
export const SEARCH_RESULTS = (state) => state.wine_search_results;
export const FULL_DRINK_DATA = (state) => state.full_drink_data;
export const FAVORITE_ITEMS = (state) => state.favorite_items;

// selectors.js
// export const FULL_DATA = (state) => state.full_data;

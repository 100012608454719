import React from 'react';
import { BrowserRouter , Route, Routes } from 'react-router-dom';
import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; // Import the new components

import WineMenu from './pages/winemenu/WineMenu';
import Spirits from './pages/spirits/Spirits';
import Beer from './pages/beer/Beer';
import Cocktails from './pages/cocktails/Cocktails';



// ... other imports

const router = createBrowserRouter([ // Create a router configuration
    { path: "/", element: <WineMenu /> },
    { path: "/spirits", element: <Spirits /> },
    { path: "/beer", element: <Beer /> },
    { path: "/cocktails", element: <Cocktails /> },
]);

const App = (props) => {
    return (
        <RouterProvider router={router} basename="/" /> // Use RouterProvider with the router and basename

    );
};

export default App;















// const App = (props) => {
//   return (
//     <div style={{width: '100vw'}}>
//       <Routes>
//         <Route index path="" element={<WineMenu />} /> 
//         <Route index element={<WineMenu />} />   
//         <Route path="spirits" element={<Spirits />} />
//         <Route path="beer" element={<Beer />} />  
//         <Route path="cocktails" element={<Cocktails />} />  
//       </Routes>
//     </div>
//   );
//   };
//   export default App;






// const App = (props) => {


//     return (
//       <div style={{width: '100vw'}}>
//         <Routes>
//           <Route index path="test" element={<WineMenu />} /> 
//           <Route index element={<WineMenu />} />   
//           <Route path="spirits" element={<Spirits />} />
//           <Route path="beer" element={<Beer />} />  
//           <Route path="cocktails" element={<Cocktails />} />  
//         </Routes>
//       </div>
//     );
    
//     };
    
    
    
    
//     export default App;
  
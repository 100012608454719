
// rootReducer.js

import { combineReducers } from 'redux';
import WineData from './r01_WineData';
import DataSearchObjects from './r02_DataSearchObjects.js';
import SelectSearchCategory from './r03_SelectSearchCategory.js';
import SearchResults from './r04_GenerateWineSearchResults.js';

import FavoriteItems from './r05_UpdateFavoriteItems.js';

import DrinkData from './r20_DrinkData.js';

// import reducer2 from './QueryStatus.js';
// import reducer3 from './QueryMenu.js';
// import reducer4 from './QueryWine.js';
// import wineData from "./WineDataPopulate.js"
// import foodMenu from "./FoodMenu.js"
//////import wineSearchResults from "./WineSearchResults.js"
///////import searchPanel from "./SearchPanelFields.js"
////import reducer2 from './reducer2';
// ... import other reducers as needed

const rootReducer = combineReducers({
  
  full_wine_data: WineData,
  data_search_objects: DataSearchObjects,
  selected_search_category: SelectSearchCategory,
  wine_search_results: SearchResults,
  full_drink_data: DrinkData,
  favorite_items: FavoriteItems,
  
//   reducer2, // shorthand for reducer1: reducer1
//   reducer3,
//   reducer4,
//   wineData,
//   foodMenu,
  
  //wineSearchResults,

  //////searchPanel,
  
  ////reducer2, // shorthand for reducer2: reducer2
  // ... add other reducers as needed

});

export default rootReducer;


// ORIGINAL COLOR SCHEME
export const PAGE_CONSTANT_STYLES1 = {

    page_background_color: '#ccdde8',
    page_button_color_selected: '#0d6cd1', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    
    page_panel_color: '#ccdde8', // dark blue: 011552,    lighter: #034488
    result_item_color: '#ccdde8',
    result_item_background_color: '#fffcf6', // original light color: '#fffcf6' ,   
    result_item_text_color: 'black',

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};



// NEW DARKER COLOR SCHEME   
export const PAGE_CONSTANT_STYLES2 = {

    page_background_color: '#113065', // '#034488', '#333399' 262673 113065   
    // from-couch-1: 343d4e     272f42      1a2332      0c1930     10264b
    //
    page_button_color_selected: '#151269', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#113065', // dark blue: 011552,    lighter: #034488
    result_item_color: '#113065', //'#262673',  //'#011552'
    result_item_background_color: '#034488', // original light color: '#fffcf6' ,   
    result_item_text_color: 'white',

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',

};



// NEW DARKER COLOR SCHEME 2
export const PAGE_CONSTANT_STYLES3 = {

    page_background_color: '#0c254e', // '#034488', '#333399' 262673 113065   
    // from-couch-1: 343d4e     272f42      1a2332      0c1930     10264b
    //   10264b   14284b   16366b   0c254e
    page_button_color_selected: '#151269', // 0d6cd1 , 151269
    page_button_color_default: '#034488', // '#2c3d5b' , // '#0d6cd1', 034488 , 2c3d5b
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#0c254e', // dark blue: 011552,    lighter: #034488
    result_item_color: '#0c254e', //'#262673',  //'#011552'
    result_item_background_color: '#74acdf', // '#0d5eaf', // original light color: '#fffcf6' ,     113065
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};


export const PAGE_CONSTANT_STYLES4 = {

    page_background_color: '#05449c', //    #405782
    page_button_color_selected: '#74acdf', //
    page_button_color_default: '#011552', // 
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#05449c', // 
    result_item_color: '#05449c', //
    result_item_background_color: '#74acdf', //
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};

export const PAGE_CONSTANT_STYLES5 = {
    page_background_color: '#05449c', //    #405782
    page_button_color_selected: '#58a6d6', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', // 
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#05449c', // 
    result_item_color: '#05449c', //
    result_item_background_color: '#74acdf', // '#74acdf', //   006bbd
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#edece8',
    modal_button_color: '#011552',
};

export const PAGE_CONSTANT_STYLES = {
    page_background_color: '#05449c', //    #405782
    page_button_color_selected: '#011552', //   74acdf
    page_button_color_default: '#006bbd', // '#5e73a4', //   325372
    page_button_text_color: '#ccdde8', // fffcf6 0d6cd1

    page_panel_color: '#05449c', // 
    result_item_color: '#05449c', //
    result_item_background_color: '#74acdf', // '#74acdf', //   006bbd
    result_item_text_color: '#edece8', 

    modal_background_color: '#74acdf',
    modal_indicator_full_color: '#011552',
    modal_indicator_empty_color: '#ccdde8', // '#edece8',
    modal_button_color: '#011552',
};



/*
color palette

dark blue #034488
mid blue #178fd6
light blue #ccdde8
gray brown #edece8
off white #fffcf6

*/
import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import ModalComponent from './ModalComponent'

import DescriptionComponent from './DescriptionComponent'

import PriceItem from './PriceItem'

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import IconButton from '@mui/material/IconButton';

// import testImg from "../../../images/test/test.jpg"
//import testImg from "../../../images/test/logo.png"




import CONFIG from '../../../config/config.js'
const WINE_IMAGE_SERVER_IP = CONFIG.WINE_IMAGE_SERVER_IP
const WINE_IMAGE_SERVER_URL = CONFIG.WINE_IMAGE_SERVER_URL


import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from "react";
////import ResultItemPrice from './ResultItemPrice'








import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color


//THIS IS THE BACKGROUND CURRENTLY USED
//const RESULT_ITEM_BACKGROUND_COLOR_FAVORITE = PAGE_CONSTANT_STYLES.result_item_background_color_favorite_2
const RESULT_ITEM_BACKGROUND_COLOR_FAVORITE = 'black' // PAGE_CONSTANT_STYLES.result_item_background_color_favorite_2

// FONT
import '../../../fonts/font.css'




const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            padding: '0.5rem',
            gap: '1rem',
            //width: 'auto',
            backgroundColor: '#178fd6',//'#034488',
            width: '95%', // '85%',
            padding: '5px',
            //opacity: "85%",



        }}
    >
        {props.children}
    </Box>
);




const StyledBox3 = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "center",
            width: '100%',
            backgroundColor: 'gray',
        }}
    >
        {props.children}
    </Box>
);






const ResultItem = (props) => {
    // Extract data from props
    const { data } = props;
    const { id } = props;


    const { handleAction } = props;
    
    const { favoritesData } = props;


    
    // console.log("data: ", data)

    //const { onItemVisible } = props
    const { activeItem } = props
    ///const { activeItems } = props
    ///const { isScrolling } = props

    const { renderDisplayItemsLength } = props
    const itemRef = useRef(null);


    //let GLOBAL_OPACITY = '100%'

    const [GLOBAL_OPACITY, setGLOBAL_OPACITY] = useState('100%');
    //console.log('id', id, 'activeItem', activeItem)



    const boxElement1Ref = useRef(null);
    const boxElement2Ref = useRef(null);
    const boxElement3Ref = useRef(null);
    const boxElement4Ref = useRef(null);



    // const [isActive, setIsActive] = useState(false);

    // useEffect(() => {
    //     // Compare the active item id and the element id
    //     //const isActiveElement = id === activeItem;
    //     setIsActive(true);
    // }, []);

    let visStatus
    let visStatus2 = 'hidden'







    useEffect(() => {

        if (id == activeItem) {
            setGLOBAL_OPACITY('60%')

            function setOpacityZero() {
                if (id == activeItem) {
                    setGLOBAL_OPACITY('0%')
                } else {
                    setGLOBAL_OPACITY('100%')
                }
            }
            setTimeout(setOpacityZero, 1800);
            //setTimeout(setOpacityZero('40%'), 3800);

            function setOpacityReturn() {
                if (id == activeItem) {
                    setGLOBAL_OPACITY('60%')
                } else {
                    setGLOBAL_OPACITY('100%')
                }
            }
            setTimeout(setOpacityReturn, 4000);




            visStatus = 'visible'
            // if (isScrolling == true) {
            //     visStatus = 'hidden'

            //     console.log()
            // }

        } else {
            visStatus = 'visible'
            setGLOBAL_OPACITY('100%')
        }

    }, [id, activeItem]);















    const debounce = (fn, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        };
    };







    const imgURL = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + 'label_' + data.image





    // const customPreviewSrc = null
    const imageSrc = WINE_IMAGE_SERVER_IP + WINE_IMAGE_SERVER_URL + data.image













    // //const imageSrc = definedURL
    // const [previewSrc, setPreviewSrc] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     const img = new Image();
    //     img.src = imageSrc;
    //     img.onload = () => {

    //         console.log(imageSrc)
    //         console.log(img)
    //         setPreviewSrc(img.src);
    //         setIsLoading(false);

    //     }


    // }, [imageSrc])











    // const [previewSrc, setPreviewSrc] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);
    // const canvas = document.createElement('canvas');


    // useEffect(() => {
    //     const img = new Image();
    //     img.src = imageSrc;

    //     img.onload = () => {
    //         // Apply cropping styles to the image element
    //         img.style.objectFit = 'cover';
    //         img.style.width = '100%';
    //         img.style.height = '100%';
    //         img.style.clip = 'rect(25% 25% 75% 50%)'; // Adjust cropping area as needed

    //         // Create a canvas to capture the cropped image
    //         //const canvas = document.createElement('canvas');
    //         canvas.width = img.width;
    //         canvas.height = img.height;
    //         const ctx = canvas.getContext('2d');

    //         // Draw the cropped image onto the canvas
    //         ctx.drawImage(img, 0, 0);

    //         // Generate a data URL from the canvas
    //         const croppedImageSrc = canvas.toDataURL();

    //         // Set the preview state with the cropped image URL
    //         setPreviewSrc(croppedImageSrc);
    //         setIsLoading(false);
    //     };
    // }, [imageSrc]);














    // useEffect(() => {  
    // //   if (customPreviewSrc) {
    // //     setPreviewSrc(customPreviewSrc);
    // //   } else {
    //     const img = new Image();
    //     img.onload = () => {
    //       // Generate preview using object-fit and cropping

    //       img.style.objectFit = 'cover';
    //       img.style.width = '100%';
    //       img.style.height = '100%';
    //       // img.style.clipPath = 'rect(25% 25% 75% 50%)'; // Define cropping area
    //       img.style.clipPath = 'inset(75% 25% 25% 50%)'; // Corrected syntax and values


    //       setPreviewSrc(img);

    //       const canvas = document.createElement('canvas');
    //       canvas.width = img.width;
    //       canvas.height = img.height;
    //       const ctx = canvas.getContext('2d');
    //       ctx.drawImage(img, 0, 0);

    //     //   setPreviewSrc(canvas.toDataURL());
    //     };
    //     img.src = imageSrc;
    // //   }
    // }, [imageSrc]);





















    // const childRef = useRef(null);
    // function handleParentClick() {
    //     // Call a function in the child component
    //     childRef.current.childFunction();
    // }

    let buttonOpacity = '100%'










    const childRef = useRef(null);
    function handleParentClick() {
        // Call a function in the child component
        childRef.current.childFunction();
    }










    const [isFavorited, setIsFavorited] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(RESULT_ITEM_BACKGROUND_COLOR);

    const handleFavoriteClick = () => {
        console.log('!isFavorited: ', !isFavorited)

        let passObject = {
            statusToSet: !isFavorited,
            itemData: data,
        }


        setIsFavorited(!isFavorited);
        console.log('isFavorited: ', isFavorited)

        console.log('passObject: ', passObject)
        handleAction(passObject)
        // Dispatch Redux action to update the favorited status in your app's state
    };

    useEffect(() => {
        setBackgroundColor(isFavorited ? RESULT_ITEM_BACKGROUND_COLOR_FAVORITE : RESULT_ITEM_BACKGROUND_COLOR); // Update based on favorited state
      }, [isFavorited]); // Run effect only when isFavorited changes



      useEffect(() => {
        const found = favoritesData.itemData.some(item => item.name === data.name)
        console.log('found', found)
        if (found == true){
            setIsFavorited(true)
        } else {
            setIsFavorited(false)
        }
      }, [data, favoritesData]); // Run effect  when data changes, NECESSARY OR RESULT PANEL REMAINS SELECTED EVEN IF DATA CHANGES!!!!!!!!!! 








    return (


        <div
            // ref={itemRef} onClick={handleParentClick}
            style={{
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '0rem',
                paddingBottom: '0.5rem',
                //backgroundColor:'gray',
                display: 'flex',
                justifyContent: 'center',



            }} >


            <Box 
            
           // backgroundImage= `url(${imgURL})`,
            backgroundColor={backgroundColor} //'#fffcf6'
                sx={{
                    // backgroundImage: `url(${imgURL})`,
                    // opacity: '0.85',
                    // backgroundSize: 'cover',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: '10rem 10rem', // '100% 100%',
                    
                    //opacity: '100%',
                    width: "100%", //'23rem',
                    //minWidth: '40%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0.5rem', //'5px',
                    margin: '0.5rem',
                    border: '0px',
                    display: 'flex',
                    //marginLeft: '2rem',
                    flexDirection: 'column',
                    borderRadius: '8px'

                }} >







                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>


                    <Typography
                        align="left"
                        fontSize='0.9rem'
                        fontWeight='bold'
                        // backgroundColor='gray'
                        color= 'yellow' // 'white' {RESULT_ITEM_TEXT_COLOR}
                    //margin='0.2rem'
                    //marginBottom= '0.5rem' // '5px',
                    >
                        - Favorite Item {id} of {renderDisplayItemsLength} -
                    </Typography>

                    {/* ... your other component content ... */}



                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <div>

                            {isFavorited ? (
                                <div>

                                    {/* <Typography
                                        align="left"
                                        fontSize='0.9rem'
                                        fontWeight= 'bold'
                                        // fontWeight='italic'
                                        // backgroundColor='gray'
                                        color= 'yellow' // {RESULT_ITEM_TEXT_COLOR}// 'white'
                                    //margin='0.2rem'
                                    //marginBottom= '0.5rem' // '5px',
                                    >
                                        - ITEM BOOKMARKED -
                                    </Typography> */}

                                </div>
                            ) : (
                                <div></div>
                            )}

                            {/* <Typography
                                align="left"
                                fontSize='1rem'
                                // fontWeight='italic'
                                // backgroundColor='gray'
                                color={RESULT_ITEM_TEXT_COLOR}// 'white'
                            //margin='0.2rem'
                            //marginBottom= '0.5rem' // '5px',
                            >
                                - SAVE -
                            </Typography> */}
                        </div>


                        <div>
                            {/* <IconButton onClick={handleFavoriteClick}>
                                {isFavorited ? (
                                    <StarIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                ) : (
                                    <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                )}
                            </IconButton> */}
                        </div>

                    </div>



                    {/* <StarIcon 
                    sx={{ width: '3rem', 
                    height: '3rem', 
                    color:  RESULT_ITEM_TEXT_COLOR , }} 
                    ></StarIcon>
                    <StarBorderIcon 
                    sx={{ width: '3rem', 
                    height: '3rem', 
                    color:  RESULT_ITEM_TEXT_COLOR , }} 
                    ></StarBorderIcon> */}



                </div>



                {/* title banner */}
                <Box
                    sx={{
                        //display: 'flex',
                        //flexDirection: 'column',
                        //flexWrap: "wrap",
                        //justifyContent: "space-between",
                        padding: '0.5rem',
                        //gap: '1rem',
                        //width: 'auto',
                        //backgroundColor: '#011552',//'#034488',
                        backgroundColor: RESULT_ITEM_COLOR, // '#011552' //'#edece8'
                        width: 'auto', // '85%',
                        //paddingTop: '0rem', // '5px',

                        //opacity: "85%",
                        height: '20%',



                    }}
                >


                    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ width: '75%' }}> */}
                    {/* <Grid item xs={6} sm={6} md={6} > */}



                    <Typography
                        align="center"
                        fontSize='1.3rem'
                        fontWeight='bold'
                        // backgroundColor='gray'
                        color={RESULT_ITEM_TEXT_COLOR}// 'white'
                        sx={{
                            // fontFamily: 'imported-font-result-text',
                            fontFamily: 'imported-font-result-text-name',

                        }}
                    //margin='0.2rem'
                    //marginBottom= '0.5rem' // '5px',
                    >
                        {data.name}
                    </Typography>
                    {/* </Grid> */}




                    {/* </div>



                        <div>
                            <IconButton onClick={handleFavoriteClick}>
                                {isFavorited ? (
                                    <StarIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                ) : (
                                    <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                )}
                            </IconButton>
                        </div>
                    </div> */}

                </Box>











                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: "no-wrap",
                        //justifyContent: "space-between",
                        padding: '0rem',
                        paddingTop: '0.5rem',
                        //gap: '1rem',
                        //width: 'auto',
                        backgroundColor: backgroundColor, // 'gray', // '#fffcf6',//'#034488',
                        width: 'auto',
                        //padding: '5px',
                        //opacity: "85%",
                        height: '80%',



                    }}
                >




                    <Box sx={{
                        padding: '0rem',
                        //gap: '1rem',
                        //width: 'auto',
                        backgroundColor: '#fffcf6',//'#034488',
                        width: 'auto', //'50%', // '85%',
                        //padding: '5px',
                        //opacity: "85%",
                        height: '10rem',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: "no-wrap",
                        justifyContent: 'flex-start',
                        borderRadius: '0px',
                    }}>
                        <Box sx={{
                            padding: '0rem',
                            backgroundColor: RESULT_ITEM_COLOR, //'#034488',
                            height: '10rem',
                            padding: '0.2rem',
                        }}>



                            {/* {isLoading ? (
                                <div>{previewSrc}</div>
                            ) : (

                                <div >
                                    <img
                                        src={previewSrc}
                                        backgroundSize='10rem 10rem' // '100% 100%',
                                        alt="Loaded Image" /> // Use the URL from state
                                </div>
                            )} */}


                            <Paper
                                sx={{
                                    backgroundImage: `url(${imgURL})`,
                                    //backgroundImage: { previewSrc },
                                    opacity: '0.85',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '10rem 10rem', // '100% 100%',
                                    backgroundColor: 'black',// '#ccdde8', //'#696969',
                                    width: '10rem', //'100%',
                                    height: '10rem',
                                    //minHeight: '23rem',
                                    //margin: '0.2rem',
                                    borderRadius: '0px',
                                }}>
                                <ModalComponent
                                    style={{
                                        backgroundColor: 'black',
                                        paddingLeft: '0rem',
                                        // color: 'blue',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                    render={true}
                                    data={data}
                                    buttonOpacity={'60%'}
                                    //ref={childRef}
                                    isActive={false}
                                //isActive={id === activeItem}
                                ></ModalComponent>
                            </Paper>












                        </Box>
                    </Box>






                    <Box visibility={visStatus}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "no-wrap",
                            //backgroundColor: 'gray',
                            justifyContent: "space-between",
                            paddingBottom: '0.5rem',
                            // paddingLeft: '15px',
                            // paddingRight: '15px',
                            opacity: '100%',
                            transition: 'opacity 1.8s',

                        }}>

                    </Box>
















                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: "no-wrap",
                        justifyContent: 'space-evenly',
                        padding: '0rem',
                        padding: '0.2rem',
                        margin: '0rem',
                        marginLeft: '0.5rem',
                        //gap: '1rem',
                        //width: 'auto',
                        //backgroundColor: '#ccdde8',//'#034488',
                        width: '50%',//'50%', // '85%',
                        //padding: '5px',
                        //opacity: "85%",
                        height: '10rem',
                    }}>
                        {/* <Paper sx={{
                            // backgroundImage: `url(${imgURL})`,
                            // backgroundSize: 'contain',
                            // backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%', // '100% 100%',
                            backgroundColor: '#696969',
                            width: '100%', //'100%',
                            height: '100%',
                            //minHeight: '23rem',
                        }}> */}


                        {/* <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        //justifyContent: 'center',
                        width: '100%',
                    }}> */}
                        <Typography
                            borderRadius='5px'
                            align="left"
                            fontSize='1rem'
                            fontWeight='bold'
                            //backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                            color={RESULT_ITEM_TEXT_COLOR} //'white'
                            margin='0rem'
                            marginTop='0rem'
                            padding='2px'
                            paddingLeft='5px'
                            //width= '90%'
                            sx={{
                                fontFamily: 'imported-font-result-text',
                            }}

                        >
                            Type: {data.type}
                        </Typography>
                        {/* </Box> */}


                        <Typography
                            borderRadius='5px'
                            align="left"
                            fontSize='1rem'
                            fontWeight='bold'
                            //backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                            color={RESULT_ITEM_TEXT_COLOR} //'white'
                            margin='0rem'
                            marginTop='0rem'
                            padding='2px'
                            paddingLeft='5px'
                            //width= '90%'
                            sx={{
                                fontFamily: 'imported-font-result-text',
                            }}
                        >


                            Varietal: {data.grape}
                        </Typography>

                        <Typography
                            borderRadius='5px'
                            align="left"
                            fontSize='1rem'
                            fontWeight='bold'
                            //backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                            color={RESULT_ITEM_TEXT_COLOR} //'white'
                            margin='0rem'
                            marginTop='0rem'
                            padding='2px'
                            paddingLeft='5px'
                            //width= '90%'
                            sx={{
                                fontFamily: 'imported-font-result-text',
                            }}
                        >
                            Region: {data.region}
                        </Typography>



                        {/* </Paper> */}



                        {/* <IconButton onClick={handleFavoriteClick}>
                            {isFavorited ? (
                                <StarIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                            ) : (
                                <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                            )}
                        </IconButton> */}










                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            {/* <div>
                                <Typography
                                    align="left"
                                    fontSize='2rem'
                                    // fontWeight='italic'
                                    // backgroundColor='gray'
                                    color={RESULT_ITEM_TEXT_COLOR}// 'white'
                                //margin='0.2rem'
                                //marginBottom= '0.5rem' // '5px',
                                >
                                    - SAVE -
                                </Typography>
                            </div> */}


                            {/* <div>
                                <IconButton onClick={handleFavoriteClick}>
                                    {isFavorited ? (
                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <div>
                                                <StarIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                            </div>

                                            <div>
                                                <Typography
                                                    color={RESULT_ITEM_TEXT_COLOR}>Favorite</Typography>
                                            </div>



                                        </div>

                                    ) : (

                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                            <div>
                                                <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                                            </div>

                                            <div>
                                                <Typography
                                                    color={RESULT_ITEM_TEXT_COLOR}></Typography>
                                            </div>



                                        </div>



                                    )}
                                </IconButton>
                            </div> */}

                        </div>























                        {/* <Typography
                            borderRadius='5px'
                            align="left"
                            fontSize='1rem'
                            fontWeight='bold'
                            backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                            color={RESULT_ITEM_TEXT_COLOR} //'white'
                            margin='0rem'
                            marginTop='0rem'
                            padding='2px'
                            paddingLeft='5px'
                            //width= '90%'
                            sx={{
                                fontFamily: 'imported-font-result-text',
                            }}
                        >
                            FAVORITE


                            <IconButton onClick={handleFavoriteClick}>
                                {isFavorited ? (
                                    <StarIcon sx={{ width: '2rem', height: '2rem', color: RESULT_ITEM_TEXT_COLOR }} />
                                ) : (
                                    <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: RESULT_ITEM_TEXT_COLOR }} />
                                )}
                            </IconButton>


                        </Typography> */}









                    </Box>







                </Box>



                {/* <Typography
                            borderRadius='5px'
                            align="left"
                            fontSize='1rem'
                            fontWeight='bold'
                            backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                            color={RESULT_ITEM_TEXT_COLOR} //'white'
                            margin='0rem'
                            marginTop='0.5rem'
                            padding='2px'
                            paddingLeft='5px'
                        //width= '90%'

                        >

                            

                        </Typography> */}

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ width: '85%', }}>
                        <PriceItem price_glass={data.price_glass_8_oz} price_bottle={data.price_bottle}></PriceItem>
                    </div>

                    <div>


                        <div>
                            <IconButton onClick={handleFavoriteClick}>
                                {isFavorited ? (
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <div>
                                            <StarIcon sx={{ width: '2.5rem', height: '2.5rem', color: 'yellow' }} />
                                        </div>

                                        <div>
                                            <Typography
                                                color={RESULT_ITEM_TEXT_COLOR}></Typography>
                                        </div>



                                    </div>

                                ) : (

                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                        <div>
                                            <StarBorderIcon sx={{ width: '2.5rem', height: '2.5rem', color: 'yellow' }} />
                                        </div>

                                        <div>
                                            <Typography
                                                color={RESULT_ITEM_TEXT_COLOR}></Typography>
                                        </div>



                                    </div>



                                )}
                            </IconButton>
                        </div>

                    </div>
                </div>




                {/* <Typography
                    borderRadius='5px'
                    align="left"
                    fontSize='1rem'
                    fontWeight='bold'
                    backgroundColor={RESULT_ITEM_COLOR} // '#011552' //'#edece8'
                    color={RESULT_ITEM_TEXT_COLOR} //'white'
                    margin='0rem'
                    marginTop='1rem'
                    padding='2px'
                    paddingLeft='5px'
                    //width= '90%'
                    sx={{
                        fontFamily: 'imported-font-result-text',
                    }}
                >
                    ADD TO FAVORITES:


                    <IconButton onClick={handleFavoriteClick}>
                        {isFavorited ? (
                            <StarIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                        ) : (
                            <StarBorderIcon sx={{ width: '2rem', height: '2rem', color: 'yellow' }} />
                        )}
                    </IconButton>


                </Typography> */}



                <DescriptionComponent data={data}></DescriptionComponent>


            </Box>






            {/* <Box visibility={visStatus}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "no-wrap",
                    //backgroundColor: 'gray',
                    justifyContent: "space-between",
                    paddingBottom: '0.5rem',
                    // paddingLeft: '15px',
                    // paddingRight: '15px',
                    opacity: '100%',
                    transition: 'opacity 1.8s',

                }}>
                <ModalComponent
                    style={{ paddingLeft: '0rem', color: 'blue' }}
                    render={true}
                    data={data}
                    buttonOpacity={'60%'}
                    //ref={childRef}
                    isActive={false}
                    //isActive={id === activeItem}
                />
            </Box> */}








            {/* <div style={{ height: '10rem' }}></div> */}













        </div >



    );
};

export default ResultItem;

















{/* // this is the bounding box for the item */ }
//     <Box backgroundColor='#edece8'

//     sx={{

//         opacity: '100%',
//         width: "100%", //'23rem',
//         minWidth: '40%',
//         display: 'flex',
//         justifyContent: 'center',
//         padding: '0.5rem', //'5px',
//         margin: '0.5rem',
//         border: '0px',

//     }} >

//     <StyledBox >



//     <Grid item xs={6} sm={6} md={6} >
//                     <Typography
//                         align="center"
//                         fontSize='1.3rem'
//                         fontWeight='bold'
//                         // backgroundColor='gray'
//                         color='black'
//                         margin='0.2rem'

//                     >
//                         {data.name}
//                     </Typography>
//                 </Grid>


//         <Paper sx={{
//             backgroundImage: `url(${imgURL})`,
//             backgroundSize: 'auto',
//             backgroundRepeat: 'no-repeat',
//             backgroundSize: '100% 50%', // '100% 100%',
//             backgroundColor: '#696969',
//             width: '50%', //'100%',
//             //minHeight: '23rem',
//         }}>

// </Paper>

// </StyledBox>

// </Box>










{/* this is the box which has the title banner */ }

{/* <Box visibility={visStatus}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: "no-wrap",
                    justifyContent: "center",
                    width: '100%',
                    backgroundColor: '#ccdde8',
                    opacity: '100%',
                    // transition: 'opacity 0.3s',

                }}
            >

                <Grid item xs={6} sm={6} md={6} >
                    <Typography
                        align="center"
                        fontSize='1.3rem'
                        fontWeight='bold'
                        // backgroundColor='gray'
                        color='black'
                        margin='0.2rem'

                    >
                        {data.name}
                    </Typography>
                </Grid>
            </Box> */}




































// useEffect(() => {
//     const debounceOnItemVisible = debounce(onItemVisible, 500); // Adjust the debounce delay as needed

//     // const observer = new IntersectionObserver(
//     //     (entries) => {
//     //         entries.forEach((entry) => {
//     //             if (entry.isIntersecting) {
//     //                 if (isScrolling == false) {
//     //                     // Use the debounced version of onItemVisible
//     //                     debounceOnItemVisible(id);
//     //                 }

//     //             }
//     //         });
//     //     },
//     //     { threshold: 0.99 } // Adjust the threshold as per your needs.
//     // );

//     if (itemRef.current) {
//         observer.observe(itemRef.current);
//     }

//     return () => {
//         if (itemRef.current) {
//             observer.unobserve(itemRef.current);
//         }
//     };
// }, [id, onItemVisible]);


















{/* 
                        <Box visibility={visStatus}>
                            <ResultItemPrice data={data} elementOpacity={GLOBAL_OPACITY} ></ResultItemPrice>
                        </Box> */}



























{/* 
                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: GLOBAL_OPACITY,
                                transition: 'opacity 1.2s',

                            }}>
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='#ccdde8'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'


                                >
                                    Type: {data.type}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%'>
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='#ccdde8'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0.6rem'
                                    padding='5px'
                                >
                                    Match # {id}
                                </Typography>

                            </Grid>
                        </Box>
 */}





{/* 
                        <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: GLOBAL_OPACITY,
                                transition: 'opacity 1.2s',

                            }}>
                            <Grid item xs={6} sm={6} md={6} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='#ccdde8'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >

                                    Grape: {data.grape}
                                </Typography>

                            </Grid>

                            <Grid item xs={5} sm={5} md={5} width='50%' >
                                <Typography
                                    borderRadius='5px'
                                    align="left"
                                    fontSize='1.1rem'
                                    fontWeight='bold'
                                    backgroundColor='#ccdde8'
                                    color='black'
                                    margin='0.3rem'
                                    marginTop='0rem'
                                    padding='5px'
                                >
                                    Region: {data.region}
                                </Typography>

                            </Grid>
                        </Box>
 */}















{/* <Box visibility={visStatus}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: "no-wrap",
                                //backgroundColor: 'gray',
                                justifyContent: "space-between",
                                paddingBottom: '0.5rem',
                                paddingLeft: '15px',
                                paddingRight: '15px',
                                opacity: '100%',
                                transition: 'opacity 1.8s',

                            }}>
                            <ModalComponent
                                style={{ paddingLeft: '0rem', color: 'blue' }}
                                render={true}
                                data={data}
                                buttonOpacity={'60%'}
                                ref={childRef}
                                isActive={id === activeItem}
                            />
                        </Box> */}









import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

const devModeBool = false

import BeerResultItem from './BeerResultItem'


// export const ContentBox = (props) => (
//     <Box
//         sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             paddingTop: '0rem',
//             width: '100%',
//             justifyContent: 'center',
//             //backgroundColor: 'black',

//         }}
//     >
//         {props.children}
//     </Box>
// );


const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',

            // backgroundColor: 'gray', // '#011552',
            opacity: '1.00',
            paddingLeft: '0rem', // '0.5rem',
            marginLeft: '1.5rem', //'1rem',

            // backgroundColor: '#011552',
            // opacity: '1.00',
            // paddingLeft: '0.5rem',
            // marginLeft: '1rem',
            marginRight: '1rem',

        }}
    >
        {props.children}
    </Box>
);








const BeerResultsContainer = (props) => {

    const { stillLoading } = props;
    const { data } = props;


    console.log('stillLoading', stillLoading)
    console.log('data', data)




    if (stillLoading == true) {
        return (
            <div></div>
        )
    } else {
        return (



            <div id="inspect_this_div" style={{ width: '100%', marginTop: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: "wrap", }}>
                <Grid container spacing={2}>
                    <ButtonBox>
                        <Grid item xs={12} sm={8} md={6} >
                            <StyledBox>
                                <div id="inspect_this_div_2" style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-evenly',
                                    flexWrap: "wrap",
                                    borderWidth: '2px',
                                    borderColor: '#edece8',
                                    borderStyle: 'solid',
                                    paddingBottom: '1rem',
                                    marginBottom: '1rem',
                                    color: 'white',
                                }}>
                                    <div style={{
                                        width: '100%', height: 'auto'
                                    }}
                                    >









                                        {data.map((item, index) => (

                                            <div key={index}>


                                                {/* <Typography >{item.name}</Typography> */}


                                                <BeerResultItem
                                                    item_data={item}
                                                    index={index}
                                                    item_count={data.length}
                                                ></BeerResultItem>

                                            </div>

                                        ))}













                                    </div>


                                </div>
                            </StyledBox>
                        </Grid >
                    </ButtonBox>
                </Grid >
            </div >

        );
    }
};

export default BeerResultsContainer;
























// <div>
// <ContentBox>
//     <Paper
//         sx={{
//             borderRadius: '0px',
//             padding: '0rem',
//             border: '0rem',
//             backgroundColor: 'black',
//             repeat: 'no-repeat',
//             backgroundSize: 'cover',
//             width: "100%",
//             boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
//             textAlign: 'center',

//         }}
//     >
//         <Grid item xs={12} sm={8} md={6} >
//             {data.map((item, index) => (

//                 <div key={index}>

//                     <Card sx={{
//                         // width: '100%',
//                         border: "1px solid black", //"4px solid rgba(211,211,211,0.6)",
//                         width: '14rem',
//                         backgroundColor: 'gray',
//                         borderRadius: '0px',
//                         opacity: '100%',
//                         margin: '0rem',
//                         borderRadius: '0rem',
//                         padding: '1rem',
//                         margin: '1rem',
//                     }}>


//                         <Typography>{item.category}</Typography>


//                     </Card>
//                 </div>

//             ))}
//         </Grid>
//     </Paper>
// </ContentBox>
// </div >
























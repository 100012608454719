
// ==========================================
// ==========================================
//               NOTES
// ==========================================
// ========================================== 



// activeSearchObject = null causing an error somewhere



// ==========================================
// ==========================================
//               IMPORTS
// ==========================================
// ========================================== 



// REACT
import React from 'react';
import { useState, useEffect, useRef } from "react";


// MUI
import { Grid, Box, Paper, Card, CardMedia, Typography, Button } from '@mui/material';


// REACT COMPONENTS
import MenuBar from "../../components/MenuBar"
import Footer from "../../components/Footer"

import SortByPanel from './searchcontrols/SortByPanel'
import SelectPanel from './searchcontrols/SelectPanel'
import CheckboxPanel from './searchcontrols/CheckboxPanel'

import SearchResults from './searchresults/SearchResults'
import SearchBar from './searchcontrols/SearchBar'

import FadeInImage from "./FadeInImage"
import FavoriteResults from './searchresults/FavoriteResults'


// REDUX
import { connect } from 'react-redux';
/////import { useDispatch } from 'react-redux';

// REDUX ACTIONS - MOVED TO MODULES
/////import FetchWineDataSuccess from "../../redux/actions/FetchWineDataSuccess"
import { FULL_WINE_DATA } from '../../redux/selectors';
import { SEARCH_OBJECTS } from '../../redux/selectors';
import { SEARCH_CATEGORY } from '../../redux/selectors';
import { SEARCH_RESULTS } from '../../redux/selectors';
import { FAVORITE_ITEMS } from '../../redux/selectors';


// REDUX OPERATION MODULES
import * as initializeOperations from "../../redux/pagemodules/winemenu/initializeOperations.js"
import * as searchObjectOperations from "../../redux/pagemodules/winemenu/searchObjectOperations.js"
import * as sortOperations from "../../redux/pagemodules/winemenu/sortOperations.js"




// ==========================================
// ==========================================
//               CONFIGS
// ==========================================
// ========================================== 

import CONFIG from '../../config/config.js'
const WINE_DATA_SERVER_IP = CONFIG.WINE_DATA_SERVER_IP
const WINE_DATA_SERVER_URL = CONFIG.WINE_DATA_SERVER_URL

const devModeBool = true




import { PAGE_CONSTANT_STYLES } from './page-constants.jsx'
// console.log('PAGE_CONSTANT_STYLES', PAGE_CONSTANT_STYLES.page_button_color)
const PAGE_BUTTON_COLOR_SELECTED = PAGE_CONSTANT_STYLES.page_button_color_selected  // '#151269'
const PAGE_BUTTON_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.page_button_color_default

const WELCOME_MESSAGE_COLOR_DEFAULT = PAGE_CONSTANT_STYLES.welcome_message_color_default



// ==========================================
// ==========================================
//               STYLES
// ==========================================
// ========================================== 

import '../../fonts/font.css'


import '../../styles/page-styles.css'

import { ContentDivWrapper } from './wine_menu_styles.jsx'
import { BannerBox } from './wine_menu_styles.jsx'
import { ButtonBox } from './wine_menu_styles.jsx'
import { BottomBannerBox } from './wine_menu_styles.jsx'
import { BannerCardBlue } from './wine_menu_styles.jsx'
import { BannerCardWhite } from './wine_menu_styles.jsx'
//import { BannerImageTop } from './wine_menu_styles.jsx'
import { BannerImageBottom } from './wine_menu_styles.jsx'
import { WhiteBox } from './wine_menu_styles.jsx'
import { BlueTypographyBar } from './wine_menu_styles.jsx'
import { StyledBox } from './wine_menu_styles.jsx'
import { CategoryButtonBox } from './wine_menu_styles.jsx'

import wineBottleBanner from '../../images/wine-bottle-banner.jpg'
import wineGlassBanner from '../../images/wine-glass-banner.jpg'
// export const BannerImageTop = (props) => (
//     <Paper
//         sx={{
//             margin: '1rem',
//             marginLeft: '1.5rem',
//             padding: '0rem',
//             border: '0rem',
//             backgroundImage: `url(${wineBannerImg})`,
//             repeat: 'no-repeat',
//             backgroundSize: 'cover',
//             width: "100%",
//             height: "100px",
//             borderRadius: '5px',
//             boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
//             textAlign: 'center',
//             position: 'relative',

//         }}
//     >
//         {props.children}
//     </Paper>
// );




import { useSpring, animated } from 'react-spring';


// ==========================================
// ==========================================
//            FUNCTIONAL COMPONENT
// ==========================================
// ========================================== 

const WineMenu = (props) => {




    // this works but easier to import through a separate css as a name e.g. 'imported-font-1 imported-font-2 etc' to change all at once
    // useEffect(() => {
    //     const link = document.createElement('link');
    //     link.rel = 'stylesheet';
    //     link.href = 'https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Parisienne&display=swap'; // Replace with your font URL
    //     document.head.appendChild(link);
    //   }, []);


    // const [isLoaded, setIsLoaded] = useState(false);
    // const props2 = useSpring({
    //     opacity: isLoaded ? 1 : 0,
    //     config: { duration: 500 }, // Adjust duration as needed
    // });

    // useEffect(() => {
    //     const img = new Image();
    //     img.src = BannerImageBottom;
    //     img.onload = () => setIsLoaded(true);
    // }, [BannerImageBottom]);















    // Extract data from props
    // const { data } = props;
    // const dispatch = useDispatch();




    // FULL_WINE_DATA_FROM_SELECTOR: FULL_WINE_DATA(state),
    // SEARCH_OBJECTS_FROM_SELECTOR: SEARCH_OBJECTS(state),
    // SEARCH_CATEGORY_FROM_SELECTOR: SEARCH_CATEGORY(state),

    /// console.log(searchCategoryInfo.activeStateDataObjects.activeDataArray)
    /// console.log(searchCategoryInfo.activeStateDataObjects.activeSearchObject)



















    const [activeSearchObject, setActiveSearchObject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reduxStateInitialized, setReduxStateInitialized] = useState(false);
    let fetchEndpointURL = WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL

    useEffect(() => {
        const initializeFetch = async () => {
            if (loading === true) {
                await initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)
                await initializeOperations.selectSearchCategory();
                // await setTimeout(() => {
                //     setLoading(false);
                // }, 300);

                // Use a promise-based delay
                // await new Promise(resolve => setTimeout(resolve, 100));
                //console.log('complete')
                setLoading(false);
            }
        };
        initializeFetch();
    }, []);

    useEffect(() => {
        const initializeSearchObjectAndCategory = async () => {
            if (loading === false) {
                await initializeOperations.createDataSearchObjects(props.FULL_WINE_DATA_FROM_SELECTOR);
                setReduxStateInitialized(true)
            }
        };
        initializeSearchObjectAndCategory();
    }, [loading]);












    const getSearchResultsInputData = () => {

        //console.log('getSearchResultsInputData---------------------', props.SEARCH_CATEGORY_FROM_SELECTOR)

        let searchCategoryInfo = props.SEARCH_CATEGORY_FROM_SELECTOR
        let selectedActiveDataArray = searchCategoryInfo.activeStateDataObjects.activeDataArray
        let selectedActiveSearchObject = searchCategoryInfo.activeStateDataObjects.activeSearchObject
        let sortByProperty = searchCategoryInfo.sortBy
        //console.log(searchCategoryInfo.activeStateDataObjects.activeDataArray)
        //console.log(searchCategoryInfo.activeStateDataObjects.activeSearchObject)
        let payloadDataArray = props.FULL_WINE_DATA_FROM_SELECTOR[selectedActiveDataArray]
        //console.log('SEARCH_OBJECTS_FROM_SELECTOR', props.SEARCH_OBJECTS_FROM_SELECTOR)
        let payloadSearchObject = props.SEARCH_OBJECTS_FROM_SELECTOR[selectedActiveSearchObject]
        // [sortByProperty]
        //console.log('payloadDataArray', payloadDataArray)
        //console.log('payloadSearchObject', payloadSearchObject)

        // if (reduxStateInitialized == true) {
        setActiveSearchObject({
            activeSearchObject: selectedActiveSearchObject,
            searchObject: payloadSearchObject,
            sortBy: sortByProperty,
        })
        //}


        let payloadObject = {
            activeDataArray: selectedActiveDataArray,
            dataArray: payloadDataArray,
            activeSearchObject: selectedActiveSearchObject,
            searchObject: payloadSearchObject,
            sortBy: sortByProperty,
        }

        return payloadObject
    }














    useEffect(() => {
        const updateSearchObjectSelection = async () => {
            if (loading === false) {
                //console.log('updateSearchObjectSelection')
                const searchResultsInputData = await getSearchResultsInputData()
                await initializeOperations.generateWineSearchResults(searchResultsInputData);
            }
        };
        updateSearchObjectSelection();
    }, [props.SEARCH_OBJECTS_FROM_SELECTOR]);











    //DEFINE BUTTON COLORS
    const colorMap = {
        glasses: {
            glasses: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1', // '#084d97',
            bottles: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
        },
        bottles: {
            glasses: PAGE_BUTTON_COLOR_DEFAULT, // '#034488',
            bottles: PAGE_BUTTON_COLOR_SELECTED, // '#0d6cd1',
        }
    }

















    const [categoryButtonColor, setCategoryButtonColor] = useState(colorMap['glasses']);



    useEffect(() => {
        const updateSearchCategorySelection = async () => {
            if (loading === false) {


                //console.log('------updateSearchCategorySelection------')
                const searchResultsInputData = await getSearchResultsInputData()
                //console.log('------updateSearchCategorySelection------', searchResultsInputData)
                //const searchResultsInputData = await getSearchResultsInputData()
                ///await initializeOperations.generateWineSearchResults(searchResultsInputData);
                console.log(props.SEARCH_CATEGORY_FROM_SELECTOR['searchCategory'])
                let buttonColor = props.SEARCH_CATEGORY_FROM_SELECTOR['searchCategory']
                console.log(buttonColor, colorMap[buttonColor])
                // let newColors = colorMap[buttonColor]
                // setCategoryButtonColor(newColors)
                // setTimeout(() => {
                //     console.log('categoryButtonColor', categoryButtonColor)
                // }, 250);
                await initializeOperations.generateWineSearchResults(searchResultsInputData);

            }
        };
        updateSearchCategorySelection();
    }, [props.SEARCH_CATEGORY_FROM_SELECTOR]);




    function handleActionPage(componentPayload) {

        console.log('componentPayload ', componentPayload)
        //searchObjectOperations.updateCheckbox(componentPayload)

        const actionSwitchValue = componentPayload.selectedType

        console.log('componentPayload', componentPayload)

        switch (actionSwitchValue) {
            case 'checkbox':
                console.log('checkbox')
                searchObjectOperations.updateCheckbox(componentPayload)
                break;
            case 'flavornote':
                console.log('flavornote')
                break;
            case 'slider':
                console.log('slider')
                break;
            case 'searchcategory':
                // console.log('searchcategory')
                searchObjectOperations.selectSearchCategory(componentPayload)
                break;
            case 'sortby':
                console.log('sortby')
                setSortBy(componentPayload.sortBy);
                searchObjectOperations.selectSearchCategory(componentPayload)
                break;
            case 'togglecheckbox':
                console.log('togglecheckbox')
                searchObjectOperations.toggleCheckbox(componentPayload)
                break;
            case 'searchbar':
                console.log('searchbar')


                const panelPayload = {
                    selectedType: 'togglecheckbox',
                    value: true,
                    selectedObject: activeSearchObject.activeSearchObject,
                    selectedSortBy: activeSearchObject.sortBy,
                }

                console.log('SEARCH_OBJECTS_FROM_SELECTOR ', props.SEARCH_OBJECTS_FROM_SELECTOR)
                console.log('panelPayload ', panelPayload)
                let selectedObject = props.SEARCH_OBJECTS_FROM_SELECTOR[panelPayload.selectedObject][panelPayload.selectedSortBy]

                let toggleAllOnBool = true
                selectedObject.forEach(element => {
                    console.log(element.property[1]); // Outputs each number
                    if (element.property[1] == true) {
                        toggleAllOnBool = false
                    }
                });

                if (toggleAllOnBool == true) {
                    searchObjectOperations.toggleCheckbox(panelPayload)
                }

                console.log('selectedObject ', selectedObject)




                searchObjectOperations.searchByTerm(componentPayload)





                break;
            case 'updatefavorites':
                console.log('updatefavorites')
                console.log('componentPayload ', componentPayload)


                let payloadWithCurrentData = {
                    statusToSet: componentPayload.statusToSet,
                    itemToAdd: componentPayload.itemData,
                    currentData: props.FAVORITE_ITEMS_FROM_SELECTOR,
                }


                searchObjectOperations.updateFavoriteItems(payloadWithCurrentData)
                break;
            // Handle other action types as needed
            default:
            // Do nothing or throw an error for unsupported action types
        }


    }









    // ==========================================
    //            PAGE VIEW POSITION TRACKER
    // ==========================================


    const [offsetFromTop, setOffsetFromTop] = useState(window.scrollY);
    const [scrollingActive, setScrollingActive] = useState(true);


    const thresholdElementRef = useRef(null);
    let thresholdPosition = 0;
    let thresholdPassed = true
    const [triggerUpdate, setTriggerUpdate] = useState(false);


    const [buttonsActiveState, setButtonsActiveState] = useState(true);

    let buttonsActive = true
    useEffect(() => {
        const handleScroll = () => {

            if (buttonsActive == false) {
                ///console.log('FALSE NOW', buttonsActive)
                return; // Do nothing if buttons are disabled
            } else {



                // Disable buttons while scrolling
                //setButtonsActive(false);

                // setButtonsActive(prevButtonsActive => {
                //     if (prevButtonsActive) {
                //         console.log('set false', prevButtonsActive);
                //         return false;
                //     }
                //     return prevButtonsActive;
                // });

                const currentOffset = window.scrollY;
                // setOffsetFromTop(currentOffset);
                buttonsActive = false
                ///console.log('set false', buttonsActive)
                setButtonsActiveState(false);
                setTimeout(() => {
                    buttonsActive = true
                    /// console.log('set true', buttonsActive, currentOffset)
                    setButtonsActiveState(true);
                }, 500);

                // Re-enable buttons after a delay (e.g., 500 milliseconds)
                // setTimeout(() => {
                //     setButtonsActive(true);
                //     console.log('set true', buttonsActive ,currentOffset)
                // }, 5000);

            }



        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [thresholdPosition]);

















    // useEffect(() => {
    //     const handleScroll = () => {
    //         // let scrollPosition = window.scrollY;
    //         // setScrollValue(scrollPosition)
    //         // console.log("scrollValue ", scrollValue)

    //         // const { top } = thresholdElementRef.current.getBoundingClientRect();
    //         // console.log("top ", top)

    //         // Later in your component or useEffect:
    //         // const offsetTopValue = thresholdElementRef.current.offsetTop;
    //         // console.log('Offset Top:', offsetTopValue);

    //         const offsetFromTop = window.scrollY;
    //         console.log('Offset from Top:', offsetFromTop);

    //         if (top < 0) {
    //             //console.log("passed threshold")
    //             setTriggerUpdate(true);
    //             // The threshold has been passed
    //             // Do something, like closing the menu
    //         }
    //         if (top > 0) {
    //             //console.log("Not passed threshold")
    //             setTriggerUpdate(false);
    //             // The threshold has been passed
    //             // Do something, like closing the menu
    //         }

    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [thresholdPosition]);




















    // const thresholdElementRef = useRef(null);
    // let thresholdPosition = 0;
    // let thresholdPassed = true
    // const [triggerUpdate, setTriggerUpdate] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const { top } = thresholdElementRef.current.getBoundingClientRect();
    //         console.log("top ", top)
    //         if (top < 0) {
    //             setTriggerUpdate(true);
    //         }
    //         if (top > 0) {
    //             setTriggerUpdate(false);
    //         }
    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [thresholdPosition]);


















    // useEffect(() => {
    //     if (loading === true) {
    //         initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)

    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 300);

    //     }
    // }, []);



    // useEffect(() => {
    //     if (loading === false) {
    //         initializeOperations.createDataSearchObjects(props.FULL_WINE_DATA_FROM_SELECTOR);
    //         initializeOperations.selectSearchCategory();
    //         initializeOperations.generateWineSearchResults();
    //     }
    // }, [loading]);

    //     }, [loading , props.FULL_WINE_DATA_FROM_SELECTOR]);












    // useEffect(() => {
    //     if (loading === false) {
    //         initializeOperations.createDataSearchObjects(props.FULL_DATA_FROM_SELECTOR);
    //         initializeOperations.selectSearchCategory();

    //     }
    // }, [props.FULL_DATA_FROM_SELECTOR]);


    // useEffect(() => {
    //     if (loading === true) {
    //         initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)
    //             .then(() => setLoading(false))
    //             .catch((error) => {
    //                 console.error('Error fetching data:', error);
    //                 setLoading(false);
    //             });
    //     }
    // }, [loading, fetchEndpointURL]);

    // const [loading, setLoading] = useState(true);
    // const fetchEndpointURL = WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL;

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             if (loading) {
    //                 await initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL);
    //                 setLoading(false);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [loading, fetchEndpointURL]);

    // useEffect(() => {
    //     const fetchDataSearchObjects = async () => {
    //         try {
    //             if (!loading) {
    //                 await initializeOperations.createDataSearchObjects(props.FULL_DATA_FROM_SELECTOR);
    //             }
    //         } catch (error) {
    //             console.error('Error creating data search objects:', error);
    //         }
    //     };

    //     fetchDataSearchObjects();
    // }, [loading, props.FULL_DATA_FROM_SELECTOR]);

















    // useEffect(() => {

    //     const fetchData = async () => {
    //         try {
    //             // Assuming fetchDataAfterPageLoad returns a Promise
    //             await initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL);
    //             //////console.log(props.FULL_WINE_DATA_FROM_SELECTOR)
    //             // After the first async operation completes, trigger the second async operation
    //             //////await initializeOperations.createDataSearchObjects(props.FULL_WINE_DATA_FROM_SELECTOR);

    //             // After the second async operation, trigger the next event or action
    //             // dispatch(nextAction());

    //         } catch (error) {
    //             // Handle errors if needed
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     // Invoke the async function immediately
    //     fetchData();

    // }, []);




    // useEffect(() => {

    //     const fetchData2 = async () => {
    //         try {
    //             await initializeOperations.createDataSearchObjects(props.FULL_WINE_DATA_FROM_SELECTOR);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     // Invoke the async function immediately
    //     fetchData2();

    // }, [props.FULL_WINE_DATA_FROM_SELECTOR]);










    // ==========================================
    // ==========================================
    //            EVENT HANDLERS
    // ==========================================
    // ========================================== 

    const initialSearchCategory = 'glasses'
    const initialSortBy = 'type'

    const [searchCategory, setSearchCategory] = useState(initialSearchCategory);
    const [sortBy, setSortBy] = useState(initialSortBy);


    //     const image1 = new Image();
    //     const image2 = new Image();
    //     image1.src = '../../images/wine-bottle-banner.jpg'
    //     image2.src =  '../../images/wine-glass-banner.jpg'

    // //     import wineBottleBanner from '../../images/wine-bottle-banner.jpg'
    // // import wineGlassBanner from '../../images/wine-glass-banner.jpg'

    //     useEffect(() => {
    //         // Preload images when the component mounts

    //         image1.src = '../../images/wine-bottle-banner.jpg'
    //     image2.src =  '../../images/wine-glass-banner.jpg'


    //         // Use the onLoad event to set the flag once both images are loaded
    //         //image2.onload = () => setImagesLoaded(true);
    //       }, []);






    // const [imagesLoaded, setImagesLoaded] = useState(false);

    // useEffect(() => {
    //   const imagePaths = ['../../images/wine-bottle-banner.jpg', '../../images/wine-glass-banner.jpg'];

    //   // Preload images using the fetch API
    //   Promise.all(imagePaths.map((path) => fetch(path)))
    //     .then((responses) => Promise.all(responses.map((res) => res.blob())))
    //     .then(() => setImagesLoaded(true));
    // }, []);





    const searchCategoryMapObject = {
        glasses: {
            label: 'Wine Glasses',
            image: wineGlassBanner,
            z_indexG: 9999,
            z_indexB: 9990,
            opacityG: 1,
            opacityB: 0,
        },
        bottles: {
            label: 'Wine Bottles',
            image: wineBottleBanner,
            z_indexG: 9990,
            z_indexB: 9999,
            opacityG: 0,
            opacityB: 1,
        },
    }

    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const imagePaths = [
            searchCategoryMapObject['glasses']['image'],
            searchCategoryMapObject['bottles']['image'], // Assuming searchCategory is defined
        ];

        // Preload images using the fetch API
        Promise.all(imagePaths.map((path) => fetch(path)))
            .then((responses) => Promise.all(responses.map((res) => res.blob())))
            .then(() => setImagesLoaded(true));
    }, [searchCategory]); // Make sure to include the dependencies, such as searchCategory
















    const [glassesButtonPressed, setGlassesButtonPressed] = useState(false);
    const handleGlassesTouchStart = (event) => {
        //console.log('props.FULL_WINE_DATA_FROM_SELECTOR', props.FULL_WINE_DATA_FROM_SELECTOR)
        let newColors = colorMap['glasses']
        setCategoryButtonColor(newColors)

        setGlassesButtonPressed(true);
        //setBottlesButtonPressed(false);
        setSearchCategory('glasses')


        setTimeout(() => {



            handleActionPage({
                selectedType: 'searchcategory',
                searchCategory: 'glasses',
                sortBy: sortBy,
            })

        }, 100);


    };
    const handleGlassesTouchEnd = (event) => {
        //console.log('end')


        setGlassesButtonPressed(false);

        // setTimeout(() => {


        //     handleActionPage({
        //         selectedType: 'searchcategory',
        //         searchCategory: 'glasses',
        //         sortBy: sortBy,
        //     })

        // }, 250);
    };


    const [bottlesButtonPressed, setBottlesButtonPressed] = useState(false);
    const handleBottlesTouchStart = (event) => {

        let newColors = colorMap['bottles']
        setCategoryButtonColor(newColors)

        setBottlesButtonPressed(true);
        //setGlassesButtonPressed(false);
        setSearchCategory('bottles')
        setTimeout(() => {



            handleActionPage({
                selectedType: 'searchcategory',
                searchCategory: 'bottles',
                sortBy: sortBy,
            })

        }, 100);
    };
    const handleBottlesTouchEnd = (event) => {
        setBottlesButtonPressed(false);
        // setTimeout(() => {


        //     handleActionPage({
        //         selectedType: 'searchcategory',
        //         searchCategory: 'bottles',
        //         sortBy: sortBy,
        //     })

        // }, 250);
    };












    const setSortByFunction = (newValue) => {
        console.log("sortBy: ", newValue)
        setSortBy(newValue);
        // if (devModeBool == true) {
        //     console.log("sortBy: ", sortBy)
        // }


        //toggleAll(false)
    };



    const toggleAll = (boolValue) => {

        // let actionObject = {
        //     activeMenu: activeMenu.toLowerCase(),
        //     sortBy: sortBy,
        // }
        // let toggleBool = boolValue
        // if (devModeBool == true) {
        //     console.log("TOGGLE", actionObject, toggleBool)
        // }
        // let dispatchDataObject = {
        //     actionObject: actionObject,
        //     toggleBool: toggleBool,
        // }
        // dispatch(ToggleAll(dispatchDataObject))
        console.log('toggle')
    };



















    // ==========================================
    // ==========================================
    //                  RETURN
    // ==========================================
    // ========================================== 

    return (
        <div className='parentDiv' >



            <div id='fadingBanner'>
            </div>


            <ContentDivWrapper>


                <FadeInImage src="./yayas-banner-footer.png" alt="Image description" />


                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MenuBar></MenuBar>
                        </Grid>



                        <Grid
                            sx={{
                                height: '12rem',
                                margin: '2rem',
                            }}>




                        </Grid>


                        <BannerBox>
                            <Grid item xs={12} sm={8} md={6} >

                                <Box
                                    sx={{
                                        margin: '2rem',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'imported-font-welcome',
                                            fontWeight: 'bold',
                                            fontSize: '2.5rem',
                                            textAlign: 'center',
                                            color: WELCOME_MESSAGE_COLOR_DEFAULT,
                                        }}
                                    >
                                        Welcome YaYa's Guests!
                                    </Typography>
                                </Box>

                                <div style={{ display: 'flex', justifyContent: 'center', }}>


                                    <Box
                                        sx={{
                                            margin: '2rem',
                                            width: '75%',
                                            // display:'flex',
                                            // justifyContent: 'center',

                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'imported-font-5',
                                                fontWeight: 'bold',
                                                fontSize: '1.8rem',
                                                textAlign: 'center',
                                                color: WELCOME_MESSAGE_COLOR_DEFAULT,
                                            }}
                                        >
                                            Thank you for visiting the interactive wine and drink menu for YaYa's Euro Bistro in Greenwood Village, CO
                                        </Typography>
                                    </Box>
                                </div>







                                {/* <Box
                                    sx={{
                                        margin: '2rem',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'imported-font-4',
                                            fontSize:'1.5rem',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Thank you for visiting the interactive drink menu for Yayas European Bistro in Greenwood Village
                                    </Typography>
                                </Box> */}

                                {/* <Paper>
                                    <Typography
                                        sx={{
                                            fontFamily: 'imported-font-2',
                                        }}
                                    >
                                        Welcome!
                                    </Typography>
                                </Paper> */}


                                {/* <Typography
                                    sx={{
                                        fontFamily: 'imported-font',
                                    }}
                                >
                                    Welcome!
                                </Typography> */}


                            </Grid>
                        </BannerBox>

















































                        <BannerBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <BannerCardBlue >
                                    <Paper
                                        sx={{
                                            margin: '1rem',
                                            marginLeft: '1.5rem',
                                            padding: '0rem',
                                            border: '0rem',
                                            backgroundColor: 'black',
                                            repeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: '5px',
                                            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                            textAlign: 'center',
                                            position: 'relative',


                                        }}
                                    >
                                        <Paper
                                            sx={{
                                                margin: '0rem',
                                                padding: '0rem',
                                                border: '0rem',
                                                backgroundImage: `url(${searchCategoryMapObject['glasses']['image']})`,
                                                repeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                width: "100%",
                                                height: "100px",
                                                borderRadius: '5px',
                                                boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                                textAlign: 'center',
                                                position: 'absolute',

                                                opacity: searchCategoryMapObject[searchCategory]['opacityG'],
                                                transition: 'opacity 0.5s ease-in-out',
                                                zIndex: searchCategoryMapObject[searchCategory]['z_indexG'],

                                            }}>
                                            <Typography sx={{


                                                fontFamily: 'imported-font-buttons',


                                                backgroundColor: '#ccdde8',
                                                opacity: "0.85",
                                                margin: '0px',
                                                paddingTop: '0rem',
                                                paddingBottom: '0rem',
                                                paddingLeft: '1rem',
                                                paddingRight: '1rem',
                                                borderRadius: '5px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '2rem',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                width: '14rem',
                                            }}
                                            >
                                                {searchCategoryMapObject[searchCategory]['label']}
                                            </Typography>
                                        </Paper>

                                        <Paper
                                            sx={{
                                                margin: '0rem',
                                                padding: '0rem',
                                                border: '0rem',
                                                backgroundImage: `url(${searchCategoryMapObject['bottles']['image']})`,
                                                repeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                width: "100%",
                                                height: "100px",
                                                borderRadius: '5px',
                                                boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
                                                textAlign: 'center',
                                                position: 'absolute',
                                                opacity: searchCategoryMapObject[searchCategory]['opacityB'],
                                                transition: 'opacity 0.5s ease-in-out',
                                                zIndex: searchCategoryMapObject[searchCategory]['z_indexB'],

                                            }}>
                                            <Typography sx={{

                                                fontFamily: 'imported-font-buttons',


                                                backgroundColor: '#ccdde8',
                                                opacity: "0.85",
                                                margin: '0px',
                                                paddingTop: '0rem',
                                                paddingBottom: '0rem',
                                                paddingLeft: '1rem',
                                                paddingRight: '1rem',
                                                borderRadius: '5px',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                fontSize: '2rem',
                                                fontWeight: 'bold',
                                                whiteSpace: 'nowrap',
                                                width: '14rem',
                                            }}
                                            >
                                                {searchCategoryMapObject[searchCategory]['label']}
                                            </Typography>
                                        </Paper>

                                    </Paper>
                                </BannerCardBlue>
                            </Grid>
                        </BannerBox>
                    </Grid>
                </Box>











                <Box>
                    <Grid container spacing={2}>
                        <ButtonBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <StyledBox>

                                    <CategoryButtonBox>
                                        <Button
                                            sx={{
                                                fontSize: '1.3rem',
                                                fontWeight: 'bold',
                                                fontFamily: 'imported-font-buttons',

                                                width: '8rem',
                                                borderRadius: '5px',
                                                border: '10px',
                                                borderColor: 'gray',
                                                backgroundColor: categoryButtonColor['glasses'], //backgroundColor: '#034488', // Your button color
                                                // boxShadow: glassesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
                                                color: glassesButtonPressed ? '#fffcf6' : '#ccdde8',
                                                '&:hover, &:focus, &:active': {
                                                    backgroundColor: categoryButtonColor['glasses'], //'#034488',
                                                },
                                                // '&:focus, &:hover': {
                                                //     backgroundColor: categoryButtonColor['glasses'],
                                                // },
                                                // '&:active': {
                                                //     boxShadow: 'none', // Remove shadow when pressed
                                                //     backgroundColor: categoryButtonColor['glasses'], //backgroundColor: '#034488', // '#0d6cd1',
                                                //     color: '#edece8',
                                                // }
                                                // style={{ fontFamily: 'imported-font' }}
                                            }}
                                            onTouchStart={handleGlassesTouchStart}
                                            onTouchEnd={handleGlassesTouchEnd}
                                            onMouseDown={handleGlassesTouchStart}
                                            onMouseUp={handleGlassesTouchEnd}
                                        >Glasses</Button>
                                    </CategoryButtonBox>

                                    <CategoryButtonBox>
                                        <Button
                                            sx={{
                                                //touchAction: 'none',

                                                fontFamily: 'imported-font-buttons',


                                                fontSize: '1.3rem',
                                                fontWeight: 'bold',
                                                width: '8rem',
                                                borderRadius: '5px',
                                                border: '10px',
                                                borderColor: 'gray',
                                                backgroundColor: categoryButtonColor['bottles'], // backgroundColor: '#034488', // Your button color
                                                // boxShadow: bottlesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)', // Adjust the shadow styles
                                                color: bottlesButtonPressed ? '#fffcf6' : '#ccdde8',
                                                '&:hover, &:focus, &:active': {
                                                    backgroundColor: categoryButtonColor['bottles'], //'#034488',
                                                },

                                                // // '&:hover': {
                                                // //     backgroundColor: '#034488',
                                                // // },
                                                // '&:focus, &:hover': {
                                                //     backgroundColor: categoryButtonColor['bottles'],
                                                // },
                                                // '&:active': {
                                                //     boxShadow: 'none', // Remove shadow when pressed
                                                //     backgroundColor: categoryButtonColor['bottles'], //backgroundColor: '#034488',
                                                //     color: '#edece8',
                                                // }
                                            }}
                                            onTouchStart={handleBottlesTouchStart}
                                            onTouchEnd={handleBottlesTouchEnd}
                                            onMouseDown={handleBottlesTouchStart}
                                            onMouseUp={handleBottlesTouchEnd}
                                        >Bottles</Button>
                                    </CategoryButtonBox>
                                </StyledBox>
                            </Grid>
                        </ButtonBox>
                    </Grid>
                </Box>















                <div style={{ width: '100%' }}>

                    <SortByPanel buttonsActiveState={buttonsActiveState} searchCategory={searchCategory} sortBy={sortBy} setSortByFunction={setSortByFunction} sendActionToPage={handleActionPage}></SortByPanel>
                    <SelectPanel buttonsActiveState={buttonsActiveState} data={props.SEARCH_RESULTS_FROM_SELECTOR} searchObjectInfo={activeSearchObject} sendActionToPage={handleActionPage}></SelectPanel>
                    <SearchBar searchObjectInfo={activeSearchObject} sendActionToPage={handleActionPage}></SearchBar>
                    <CheckboxPanel searchObjectInfo={activeSearchObject} sendActionToPage={handleActionPage} ></CheckboxPanel>

                </div>

                <div ref={thresholdElementRef} >
                </div>


                <div>
                    <FavoriteResults data={props.SEARCH_RESULTS_FROM_SELECTOR} favoritesData={props.FAVORITE_ITEMS_FROM_SELECTOR} sendActionToPage={handleActionPage}></FavoriteResults>
                </div>

                <div>
                    <SearchResults data={props.SEARCH_RESULTS_FROM_SELECTOR} favoritesData={props.FAVORITE_ITEMS_FROM_SELECTOR} sendActionToPage={handleActionPage}></SearchResults>
                    {/* <p>RenderSelector</p> */}
                </div>





                <BlueTypographyBar></BlueTypographyBar>
                <WhiteBox >
                    <Grid container spacing={2}>
                        <BottomBannerBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <BannerCardWhite>
                                    <BannerImageBottom />
                                </BannerCardWhite>
                            </Grid>
                        </BottomBannerBox>
                    </Grid>
                </WhiteBox>

                <Footer></Footer>

            </ContentDivWrapper>
        </div >
    );


};

const mapStateToProps = (state) => ({
    FULL_WINE_DATA_FROM_SELECTOR: FULL_WINE_DATA(state),
    SEARCH_OBJECTS_FROM_SELECTOR: SEARCH_OBJECTS(state),
    SEARCH_CATEGORY_FROM_SELECTOR: SEARCH_CATEGORY(state),
    SEARCH_RESULTS_FROM_SELECTOR: SEARCH_RESULTS(state),
    FAVORITE_ITEMS_FROM_SELECTOR: FAVORITE_ITEMS(state),
});

export default connect(mapStateToProps)(WineMenu);









































// ==========================================
// ==========================================
//                 COMMENTS
// ==========================================
// ========================================== 




// const mapStateToProps = (state) => {
//     const reducerData = state.reducer4
//     const wineData = state.wineData
//     return {
//         reducerData,
//         wineData,
//     };
// };



//export default WineMenu;


















/*






                <Box
                    sx={{
                        backgroundColor: '#034488',
                        borderRadius: '5px',
                        width: '8rem',
                    }}
                >
                    <Button
                        sx={{
                            //touchAction: 'none',
                            width: '8rem',
                            borderRadius: '5px',
                            border: '10px',
                            borderColor: 'gray',
                            backgroundColor: '#034488', // Your button color
                            boxShadow: isPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)', // Adjust the shadow styles
                            color: '#edece8',

                            '&:active': {
                                boxShadow: 'none', // Remove shadow when pressed
                                backgroundColor: '#034488',
                                color: '#ccdde8',
                            }
                        }}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                    >Bottles</Button>
                </Box>









                <Box
                // sx={{
                //     border: 'none',
                //     padding: '0px',
                //     margin: '0px',
                //     width: '8rem',
                //     cursor: 'pointer',
                //     boxShadow: isPressed ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.4)', // Adjust the shadow styles
                //     backgroundColor: '#edece8', // Your button color
                //     color: 'black', // Your text color
                //     // '&:hover': {
                //     //     backgroundColor: '#1565c0', // Adjust the hover color
                //     // },
                //     '&:active': {
                //         boxShadow: 'none', // Remove shadow when pressed
                //         backgroundColor: '#edece8',
                //     },
                // }}
                sx={{
                    backgroundColor: '#034488', 
                    borderRadius: '5px',
                    width: '8rem',
                }}

                >
                    <Button
                        sx={{
                            //touchAction: 'none',
                            width: '8rem',
                            borderRadius: '5px',
                            border: '10px',
                            borderColor: 'gray',
                            backgroundColor: '#034488', // Your button color
                            boxShadow: isPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)', // Adjust the shadow styles
                            color: '#edece8',

                            '&:active': {
                                boxShadow: 'none', // Remove shadow when pressed
                                backgroundColor: '#034488',
                                color: '#ccdde8',
                            }
                        }}
                        onTouchStart={handleMouseDown}
                        onTouchEnd={handleMouseUp}
                    >Bottles</Button>
                </Box>





*/


{/* <Typography>test</Typography> */ }


{/* <Box>
                    <Grid container spacing={2}>
                        <ButtonBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <StyledBox>

                                    <GlassesCategoryButton2
                                                onTouchStart={handleGlassesTouchStart}
                                                onTouchEnd={handleGlassesTouchEnd}
                                                onMouseDown={handleGlassesTouchStart}
                                                onMouseUp={handleGlassesTouchEnd}
                                    >Glasses</GlassesCategoryButton2>

                                </StyledBox>
                            </Grid>
                        </ButtonBox>
                    </Grid>
                </Box> */}



{/* <Box>
                    <Grid container spacing={2}>
                        <ButtonBox>
                            <Grid item xs={12} sm={8} md={6} >
                                <StyledBox>

                                    <CategoryButton
                                        // categoryButtonPressed={glassesButtonPressed}
                                        externalStyles={glassesExternalStyles}
                                    // externalOnTouchStart={handleGlassesTouchStart}
                                    // externalOnTouchEnd={handleGlassesTouchEnd}
                                    // externalOnMouseDown={handleGlassesTouchStart}
                                    // externalOnMouseUp={handleGlassesTouchEnd}
                                    //childeren={'glasses'}
                                    ></CategoryButton>

                                    <CategoryButton searchCategory={searchCategory} ></CategoryButton>
                                </StyledBox>
                            </Grid>
                        </ButtonBox>
                    </Grid>
                </Box> */}


































































/*

// import wineBannerImg from '../../images/wine-banner.jpg'
// import footerBannerImg from '../../images/yayas-banner-footer.png'

const ContentDivWrapper = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: "wrap",
            justifyContent: "space-between",
            //gap: '1rem',
            width: '100%',
            height: 'auto',
            minHeight: '120vh',
            backgroundColor: '#ccdde8',
            // backgroundImage: `url(${background_image})`,
            backgroundAttachment: 'fixed',
            repeat: 'no-repeat',
            backdropFilter: 'blur(0)', // or backdropFilter: 'none'
            padding: '0px',
            margin: '0px',
            border: '0px',
        }}
    >
        {props.children}
    </Box>
);

const BannerBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const ButtonBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '0.5rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const BottomBannerBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '1rem',
            width: '100%',
            justifyContent: 'center',
            //backgroundColor: 'black',

        }}
    >
        {props.children}
    </Box>
);

const BannerCardBlue = (props) => (
    <Card
        sx={{
            margin: '0rem',
            padding: '0rem',
            border: '0rem',
            display: 'flex',
            justifyContent: "center",
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0)',
            borderRadius: '0px',
            backgroundColor: '#ccdde8',
        }}
    >
        {props.children}
    </Card>
);

const BannerCardWhite = (props) => (
    <Card
        sx={{
            margin: '0rem',
            padding: '0rem',
            border: '0rem',
            display: 'flex',
            justifyContent: "center",
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.0)',
            borderRadius: '0px',
            backgroundColor: '#fffcf6',
        }}
    >
        {props.children}
    </Card>
);

const BannerImageTop = (props) => (
    <Paper
        sx={{
            margin: '1rem',
            marginLeft: '1.5rem',
            padding: '0rem',
            border: '0rem',
            backgroundImage: `url(${wineBannerImg})`,
            repeat: 'no-repeat',
            backgroundSize: 'cover',
            width: "100%",
            height: "100px",
            borderRadius: '5px',
            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
            textAlign: 'center',
            position: 'relative',

        }}
    >
        {props.children}
    </Paper>
);

const BannerImageBottom = (props) => (
    <Paper
        sx={{
            margin: '1rem',
            marginLeft: '1.5rem',
            padding: '0rem',
            border: '0rem',
            backgroundImage: `url(${footerBannerImg})`,
            repeat: 'no-repeat',
            backgroundSize: 'cover',
            width: "100%",
            height: "100px",
            borderRadius: '5px',
            boxShadow: '2px 2px 4px rgba(0, 0, 50, 0.4)',
        }}
    >
        {props.children}
    </Paper>
);

const WhiteBox = (props) => (
    <Box
        sx={{
            backgroundColor: '#fffcf6',
        }}
    >
        {props.children}
    </Box>
);

const BlueTypographyBar = (props) => (
    <Typography
        sx={{
            padding: '0.4rem',
            backgroundColor: '#034488',
            align: "center",
        }}
    >
        {props.children}
    </Typography>
);



const StyledBox = (props) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: "no-wrap",
            justifyContent: "space-between",
            width: 'auto',
            backgroundColor: '#ccdde8',
            opacity: '1.00',
            //paddingLeft: '1rem',
            marginLeft: '1rem',
            marginRight: '1rem',
        }}
    >
        {props.children}
    </Box>
);

// const StyledButton = (props) => (
//     <Button
//         sx={{
//             fontSize: '1.0rem',
//             fullWidth: "true",
//             size: "large",
//             padding: '5px',
//             color: '#420420',
//         }}
//     >
//         {props.children}
//     </Button>
// );

// const setMenuFunction = (newValue) => {
//     // setActiveMenu(newValue);
//     // toggleAll(false)
//     console.log(newValue)

// };

const CategoryButtonBox = (props) => (
    <Box
        sx={{
            backgroundColor: '#034488',
            borderRadius: '5px',
            width: '8rem',
            marginLeft: '0.5rem',
        }}
    >
        {props.children}
    </Box>
);









*/















/*

const [searchCategory, setSearchCategory] = useState(false);

const glassesExternalStyles = {
    boxShadow: glassesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
    color: glassesButtonPressed ? '#fffcf6' : '#ccdde8',
};

//const { sx: externalStyles, ...rest } = props; // Extract sx from props
const GlassesCategoryButton = (props) => (
    <Button
        sx={{
            width: '8rem',
            borderRadius: '5px',
            border: '10px',
            borderColor: 'gray',
            backgroundColor: '#034488',
            // boxShadow: glassesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)',
            // color: glassesButtonPressed ? '#fffcf6' : '#ccdde8',
            '&:active': {
                boxShadow: 'none',
                backgroundColor: '#034488',
                color: '#edece8',
            },
            //...externalStyles, // Merge external styles
        }}
    // {...rest} // Pass other props, including event handlers
    >
        {props.children}
    </Button>
);



// the onTouchStart and onTouchEnd event handlers are passed down through {...rest}, and the boxShadow and color styles are merged through ...externalStyles. This allows for a clean separation of style-related props and non-style-related props, making the component more flexible and reusable.




const GlassesCategoryButton2 = (props) => (
    <Button
        sx={{
            //touchAction: 'none',
            width: '8rem',
            borderRadius: '5px',
            border: '10px',
            borderColor: 'gray',
            backgroundColor: '#034488', // Your button color
            boxShadow: glassesButtonPressed ? 'none' : '1px 2px 2px rgba(0, 0, 0, 0.5)', // Adjust the shadow styles
            color: glassesButtonPressed ? '#fffcf6' : '#ccdde8',

            '&:active': {
                boxShadow: 'none', // Remove shadow when pressed
                backgroundColor: '#034488',
                color: '#edece8',
            }
        }}

    >
        {props.children}
    </Button>
);





*/
























/*

    const [loading, setLoading] = useState(true);
    let fetchEndpointURL = WINE_DATA_SERVER_IP + WINE_DATA_SERVER_URL
    useEffect(() => {

        // const fetchData = async () => {

        //     if (devModeBool == true) {
        //         console.log('Component mounted, if this happens twice check strict mode');
        //     }

        //     try {
        //         const response = await fetch(fetchEndpointURL);
        //         const data = await response.json();
        //         if (devModeBool == true) {
        //             console.log('FETCH_DATA SUCCESS ----- ', data)
        //         }

        //         await dispatch(FetchWineDataSuccess(data));
        //         setLoading(false);

        //     } catch (error) {
        //         if (devModeBool == true) {
        //             console.error('Error:', error);
        //             console.log('FETCH_DATA ----- ERROR')
        //         }
        //     }
        // };
        // fetchData();

        initializeOperations.fetchDataAfterPageLoad(fetchEndpointURL)


    }, []);


*/




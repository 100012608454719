

export default function exportedFunction(state = {}, action) {


    if (action.type === 'UPDATE_FAVORITE_ITEMS') {

        console.log('UPDATE_FAVORITE_ITEMS', action.payload)

        let currentItemArray = action.payload.currentData.itemData
        if (action.payload.statusToSet == true){
            
            currentItemArray.push(action.payload.itemToAdd)
        }
        else {
            let referenceObject = action.payload.itemToAdd
            currentItemArray = currentItemArray.filter(item => item.name !== referenceObject.name);
        }

        return {
            itemData: currentItemArray,
        };
    }

    return state;

}


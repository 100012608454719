
export default function exportedFunction(state = {}, action) {

    if (action.type === 'FETCH_DRINK_DATA_SUCCESS') {

        // console.log('action', action.payload)
        const drinkDataArray = action.payload
        let spiritsArray = []
        let cocktailsArray = []
        let beerArray = []

        // drinkDataArray.forEach((element, index) => {
        //     let type = element.type
        // })

        const sortedArrays = drinkDataArray.reduce((acc, obj) => {
            // console.log('reducing')
            acc[obj.type].push(obj);
            return acc;
        }, { spirits: [], cocktails: [], beer: [] });

        // console.log(sortedArrays);
        spiritsArray = sortedArrays.spirits
        cocktailsArray = sortedArrays.cocktails
        beerArray = sortedArrays.beer

        // console.log('spiritsArray', spiritsArray);
        // console.log('cocktailsArray', cocktailsArray);
        // console.log('beerArray', beerArray);

        // spiritsArray.forEach((element, index) => {
        //     // console.log('element', element.subcategory)
        // })

        const key = 'subcategory'; // The key for which you want to count instances
        const valueCounts = spiritsArray.reduce((acc, obj) => {
            acc[obj[key]] = (acc[obj[key]] || 0) + 1;
            return acc;
        }, {}); // Initialize accumulator as an empty object            
        console.log(valueCounts); // Output: { 1: 2, 2: 1, 3: 1 }

        const sortedCounts = Object.entries(valueCounts)
            .sort((a, b) => b[1] - a[1]); // Sort descending by count

        console.log(sortedCounts);



        // const categorizedData = sortedCounts.map(([category, count]) => ({
        //     category,
        //     data: spiritsArray.filter(item => item[key] === parseInt(category)) || [], // Ensure empty array if filter returns nothing
        // }));

        // console.log(categorizedData);

        // Initialize categories based on sorted counts
        // let categoriesArray = sortedCounts.reduce((acc, [category]) => {
        //     acc[category] = [];
        //     return acc;
        // }, {});
        // console.log('categoriesArray', categoriesArray);

        // Create categorized data with empty arrays
        const categorizedData = sortedCounts.map(([category, count]) => ({
            category,
            count,
            data: [], // Initialize empty data array
        }));
        console.log(categorizedData);

        // Populate data in categorizedData
        categorizedData.forEach(categoryObject => {
            const category = categoryObject.category;

            spiritsArray.forEach(item => {
                if (item[key] === category) {
                    categoryObject.data.push(item);
                }
            });
        });
        console.log(categorizedData);



        return {
            spiritsData: categorizedData,
            cocktailsData: cocktailsArray,
            beerData: beerArray,
        };

    }

    return state;

}




// abv: "none"
// brewery: "none"
// description: "Famous for being truly singular bourbon, Wild Turkey is a Kentucky straight bourbon whiskey. Wild Turkey is distilled a low proof with very little water to seal its flavor. As a result Wild Turkey is full flavored and very similar to the authentic bourbon taste one would get straight from the barrel. By adding a little water, the caramel gives way to a richer chocolate flavor. This 101 proof has a fragrance of violet and honeysuckle, with a sweet taste of peaches, chocolate and caramel."
// id: 2
// image_main: "159_M_wild_turkey_101"
// image_thumb: "159_T_wild_turkey_101"
// location: "none"
// measure: "Glass"
// misc_data: "{Brewery: None, IBU: None, ABV: None, Style: None, Location: None}"
// name: "Wild Turkey 101"
// price: 12
// proof: "none"
// style: "none"
// subcategory: "Bourbon,Whiskey"
// type: "spirits"


import React from 'react';
import { Grid, Box, Paper, Card, CardMedia, Typography } from '@mui/material';

import { PAGE_CONSTANT_STYLES } from '../page-constants.jsx'
const RESULT_ITEM_COLOR = PAGE_CONSTANT_STYLES.result_item_color
const RESULT_ITEM_BACKGROUND_COLOR = PAGE_CONSTANT_STYLES.result_item_background_color
const RESULT_ITEM_TEXT_COLOR = PAGE_CONSTANT_STYLES.result_item_text_color

// FONT
import '../../../fonts/font.css'



const PriceItem = (props) => {

    // Extract data from props
    const { price_glass } = props;
    const { price_bottle } = props;
    // console.log('price_glass ', price_glass, ' price_bottle ', price_bottle)

    let priceArray = []

    let display_glass_price = 'Glass: $'
    if (price_glass == null) {
        display_glass_price = ''
    } else {
        display_glass_price = display_glass_price + price_glass.toString()
        priceArray.push(display_glass_price)
    }

    let display_bottle_price = 'Bottle: $'
    if (price_bottle == null) {
        display_bottle_price = ''
    } else {
        display_bottle_price = display_bottle_price + price_bottle.toString()
        priceArray.push(display_bottle_price)
    }
    //let display_bottle_price =  

    return (
        <div>

            <div style={{
                //backgroundColor: 'black', //RESULT_ITEM_BACKGROUND_COLOR, //RESULT_ITEM_COLOR, // '#8dbbe6',
                color: RESULT_ITEM_TEXT_COLOR,
                marginBottom:'1rem',
                marginTop: '1rem',
                // paddingLeft: '1rem',
                // paddingRight: '1rem',
                // paddingTop: '1rem',
                // paddingBottom: '1rem',
                fontWeight: 'bold',
            }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',

                }}>

                    {/* <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }} >Price</Typography> */}
                    {/* <Typography style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{display_glass_price}</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{display_bottle_price}</Typography> */}

                    {priceArray.map((item, index) => (

                        <Typography

                            sx={{
                                fontWeight: 'bold', fontSize: '1.2rem',
                                fontFamily: 'imported-font-result-text',


                                

                            }}

                            key={`price-${index}`}

                        >
                            {item}
                        </Typography>

                    ))}


                </div>


            </div>

        </div >
    );
};

export default PriceItem;

